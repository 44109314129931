export enum QueryStringParam {
  Action = 'action',
  AlertId = 'alertId',
  CloudIds = 'cloudIds',
  Diagram = 'diagram',
  Division = 'div',
  EudbRelevantNodes = 'eudbRelevantNodes',
  ECDeployments = 'ecDeployments',
  FeatureId = 'featureId',
  FeatureFlag = 'ff',
  Id = 'id',
  M365Cloud = 'm365Cloud',
  Mode = 'mode',
  MyFeatures = 'myFeatures',
  NodeId = 'nodeId',
  Organization = 'org',
  Parity = 'parity',
  PartialParityCloud = 'partialParityCloud',
  PendingTransfer = 'pendingTransfer',
  PlatformIds = 'platformIds',
  ProductId = 'productId',
  ProductIds = 'productIds',
  Rejected = 'Rejected',
  ResourceEnv = 'resourceEnv',
  ResourceGroup = 'resourceGroup',
  ResourceProvider = 'resourceProvider',
  ResourceType = 'resourceType',
  RTIEnvironment = 'environment',
  RTITeam = 'team',
  RTIVulnerabilityEnvironment = 'vulnerabilityEnvironment',
  RTIVulnerabilityTeam = 'vulnerabilityTeam',
  ServiceId = 'serviceId',
  ServiceCloudIds = 'serviceCloudIds',
  ServiceComponentIds = 'serviceComponentIds',
  ServicePlanIds = 'servicePlanIds',
  RunId = 'runId',
  ApplicationName = 'applicationName',
  SkuIds = 'skuIds',
  SkuType = 'SkuType',
  Statuses = 'statuses',
  Tab = 'tab',
  TargetServiceId = 'targetServiceId',
  Type = 'type',
  Workstream1Only = 'workstream1Only',
  JobEnvironment = 'jobEnvironment',
  StartDate = 'startDate',
  EndDate = 'endDate',
  State = 'state',
  ServiceTier = 'serviceTier',
}
