import React from 'react'
import { ISelectableOption, IStackTokens, Stack } from '@fluentui/react'
import { useDivisions, useOrganizations } from 'src/queries'
import { DivisionVM, OrganizationVM, ServiceVM } from 'src/services/graphapi'
import { ALL } from 'src/constants'
import { useDefaultService, useSearchParamValue } from 'src/hooks'
import { FilterSize, getDropdownStyles } from '../../Filters'
import { ServiceUrlAutoCompleteWithFilters } from '../../AutoComplete'
import { Filters, QueryStringParam } from 'src/enums'
import { UrlDropdown } from '../UrlDropdown'
import { UrlFilterProps, getEveryDependent } from '../UrlFilterProps'

export interface UrlServiceSelectionWithFiltersProps {
  horizontal?: boolean
  allOptionEnabled?: boolean
  customizationByUserType?: boolean
  displayOnlyMyServices?: boolean
}

const dropdownsTokens = (horizontal?: boolean): IStackTokens => {
  return { childrenGap: horizontal ? 20 : 0 }
}

const getErrorMessage = (isError: boolean) => (isError ? `Couldn't fetch the data` : undefined)

const mapToOptions = (
  data: DivisionVM[] | OrganizationVM[] | ServiceVM[] | undefined,
  allOptionEnabled: boolean,
): ISelectableOption[] => {
  const result =
    data?.map((item) => {
      const id = item.id || item.name || ''
      return { key: id, id, text: item.name || '' }
    }) || []
  if (allOptionEnabled && result.length) {
    result.unshift({ key: ALL, id: undefined, text: ALL })
  }
  return result
}

const dropdownStyles = getDropdownStyles(FilterSize.M)

const serviceFilter: UrlFilterProps = {
  urlKey: QueryStringParam.ServiceId,
}
const organizationFilter: UrlFilterProps = {
  urlKey: QueryStringParam.Organization,
  dependents: [serviceFilter],
}
const divisionFilter: UrlFilterProps = {
  urlKey: QueryStringParam.Division,
  dependents: [organizationFilter],
}

export const UrlServiceSelectionWithFilters: React.FunctionComponent<UrlServiceSelectionWithFiltersProps> = ({
  horizontal = false,
  allOptionEnabled = false,
  customizationByUserType = false,
  displayOnlyMyServices = false,
}) => {
  const [divisionUrlValue] = useSearchParamValue(divisionFilter.urlKey, getEveryDependent(divisionFilter.dependents))

  const { data: divisionsData, isLoading: isLoadingDivisions, isError: isErrorDivisions } = useDivisions()
  const {
    data: organizationsData,
    isLoading: isLoadingOrganizations,
    isError: isErrorOrganizations,
  } = useOrganizations(divisionUrlValue)

  const divisions = mapToOptions(divisionsData, allOptionEnabled)
  const organizations = mapToOptions(organizationsData, allOptionEnabled)

  useDefaultService()

  return (
    <Stack
      horizontal={horizontal}
      styles={{ root: { flexWrap: 'wrap', textAlign: 'left' } }}
      disableShrink
      horizontalAlign="start"
      tokens={dropdownsTokens(horizontal)}
    >
      <UrlDropdown
        label={Filters.Division}
        placeholder="Select a division"
        options={divisions}
        styles={dropdownStyles}
        disabled={isLoadingDivisions}
        errorMessage={getErrorMessage(isErrorDivisions)}
        urlKey={divisionFilter.urlKey}
        dependents={divisionFilter.dependents}
      />
      <UrlDropdown
        label={Filters.Organization}
        placeholder="Select an organization"
        options={organizations}
        styles={dropdownStyles}
        disabled={isLoadingOrganizations}
        errorMessage={getErrorMessage(isErrorOrganizations)}
        urlKey={organizationFilter.urlKey}
        dependents={organizationFilter.dependents}
      />
      <ServiceUrlAutoCompleteWithFilters
        customizationByUserType={customizationByUserType}
        displayOnlyMyServices={displayOnlyMyServices}
      />
    </Stack>
  )
}
