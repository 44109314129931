import { ITag } from '@fluentui/react'
import { useMemo } from 'react'
import { useProducts } from 'src/queries'

export interface UseProductListOptionsProps {
  selectedFeatureProducts: ITag[]
}

export interface UseProductListOptions {
  options: ITag[]
  isLoadingOptions: boolean
}

export const useProductListOptions = ({
  selectedFeatureProducts,
}: UseProductListOptionsProps): UseProductListOptions => {
  const { data: allProducts, isLoading: isLoadingProducts } = useProducts()

  const options = useMemo(() => {
    if (allProducts?.length) {
      const totalAvailableProductsList = allProducts.filter(
        (product) => !selectedFeatureProducts?.some((productTag) => productTag.key === product.id),
      )
      const totalAvailableProductsTags = totalAvailableProductsList.map(
        ({ id, name }) => ({ key: id, name: name } as ITag),
      )
      return totalAvailableProductsTags
    }
    return []
  }, [allProducts, selectedFeatureProducts])

  return { options, isLoadingOptions: isLoadingProducts }
}
