import { Stack } from '@fluentui/react'
import { AlertPayloadComponent } from '../../../../features/SGAlerts/base/types'
import { EUDBAimlTrainingEnvironmentAlertRawPayload } from './types'
import { content, header, headerStackItemStyle } from './Styles'
import { DatasetsList } from './DatasetsList'

export const AimlTrainingEnvironmentAlertPayload: AlertPayloadComponent<EUDBAimlTrainingEnvironmentAlertRawPayload> = ({
  rawPayload,
}) => {
  return (
    <Stack>
      <Stack.Item styles={headerStackItemStyle}>Description:</Stack.Item>
      <Stack.Item>
        The model <b>{rawPayload.ViolatedModel?.Name}</b> (version:
        <b>{rawPayload.ViolatedModel?.Version}</b>) has been detected to be trained on the environment outside of the
        EU, which is in violation of Microsoft EUDB commitments. The model is classified as{' '}
        {rawPayload.DataClassification?.join(', ')}, which means that it contains in-scope EU customer data that must be
        stored and processed in the EU. Please refer to the next section for incident details.
      </Stack.Item>
      <br></br>
      <Stack.Item styles={headerStackItemStyle}>To resolve violation:</Stack.Item>
      <ul>
        <li>
          Improve your data boundary measures to avoid training models with in-scope data outside of the data boundary
        </li>
        <li>Set alert as &quot;Resolved&quot;</li>
      </ul>
      <Stack.Item>
        If you believe this is a false positive, please update alert state as &quot;Dispute&quot; and provide
        appropriate justification in Comment section.
      </Stack.Item>
      <br></br>
      <Stack.Item styles={headerStackItemStyle}>Information:</Stack.Item>
      <Stack.Item styles={header}>Model:</Stack.Item>
      <Stack styles={content}>
        <Stack.Item>Name: {rawPayload.ViolatedModel?.Name} </Stack.Item>
        <Stack.Item>Version: {rawPayload.ViolatedModel?.Version} </Stack.Item>
        <Stack.Item>Owners: {rawPayload.ViolatedModel?.OwnerAlias} </Stack.Item>
        <Stack.Item>DataClassificaiton: {(rawPayload.ViolatedModel?.DataClassification ?? []).join(', ')}</Stack.Item>
        <Stack.Item>
          DataBoundary: {(rawPayload.ViolatedModel?.DataBoundary ?? '').length == 0 ? 'No Boundary' : 'EUDB'}{' '}
        </Stack.Item>
      </Stack>

      <Stack.Item styles={header}>Model training datasets:</Stack.Item>
      <DatasetsList datasets={rawPayload.Datasets} />

      <Stack.Item styles={header}>Affected Training environment:</Stack.Item>
      <Stack styles={content}>
        <Stack.Item>Id: {rawPayload.ViolatedEnvironment?.Id} </Stack.Item>
        <Stack.Item>Resource group name: {rawPayload.ViolatedEnvironment?.ResourceGroup} </Stack.Item>
        <Stack.Item>DataBoundary: Non EU </Stack.Item>
      </Stack>
    </Stack>
  )
}
