import { useMutation, useQueryClient } from 'react-query'
import { apiService, UpdateFeatureIntentsCommand, FeatureIntentVM, FeatureVM } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const saveFeatureIntent = async (command: UpdateFeatureIntentsCommand) => {
  if (command.featureId) {
    await apiService.features_UpdateFeatureIntents(command.featureId, command)
  }
}

export function useSaveFeatureIntents() {
  const queryClient = useQueryClient()

  return useMutation((command: UpdateFeatureIntentsCommand) => saveFeatureIntent(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<FeatureIntentVM[]>(
        queryClient,
        SgQueryKey.featureIntent,
        (queryKey, data) => {
          if (queryKey[1] === variables.featureId) {
            return data
          }
          return undefined
        },
      )
      optimisticUpdateAndInvalidateQueries<FeatureVM[]>(
        queryClient,
        SgQueryKey.cloudParityReport,
        (_queryKey, data) => {
          return data
        },
      )
    },
  })
}
