import { FC, useState, useEffect } from 'react'
import { IComboBox, IComboBoxOption, IComboBoxProps, SelectableOptionMenuItemType } from '@fluentui/react'
import * as React from 'react'
import { AutoCompleteProps } from './AutoComplete'
import { CLEAR_SELECTION_TEXT, SELECT_ALL_TEXT } from 'src/constants'
import { ControlledVirtualizedComboBox, HookFormOptionalProps } from '../ControlledInput'
import { FetchingProgressIndicator } from '../ProgressIndicator'
import { SGVirtualizedComboBox } from '../SGVirtualizedComboBox'
import { getComboBoxProps } from './props'
import { useFocusOnClick, useOnRenderLabel } from './hooks'

export interface AutoCompletePropsMultiselect extends AutoCompleteProps {
  selectedKey?: string[]
  onChangeMultipleValues?: (event: React.FormEvent<IComboBox>, selectedOptions?: string[]) => void
  isSelectAll?: boolean
  selectedOptionLimit?: number
}

export const AutoCompleteMultiselect: FC<HookFormOptionalProps & AutoCompletePropsMultiselect> = ({
  selectedKey,
  isSelectAll,
  selectedOptionLimit,
  filterSize,
  options,
  isLoading,
  isFetching,
  error,
  onChangeMultipleValues,
  label,
  placeholder,
  disabled,
  hint,
  name,
  control,
}) => {
  const focusOnClick = useFocusOnClick()
  const onRenderLabel = useOnRenderLabel(hint, label)
  const [selectedOptions, setSelectedOptions] = useState<string[]>()
  const isError = !!error
  const optionLimitText = selectedOptionLimit ? ` - Limit: ${selectedOptionLimit} items` : ''
  const userOptions: IComboBoxOption[] = [
    {
      key: 'selectAll',
      text: isSelectAll ? SELECT_ALL_TEXT : CLEAR_SELECTION_TEXT,
      itemType: SelectableOptionMenuItemType.SelectAll,
      disabled: !isSelectAll && !selectedOptions?.length,
    },
    {
      key: 'Selected',
      text: `${selectedOptions ? selectedOptions?.length : 0} items${optionLimitText}`,
      itemType: SelectableOptionMenuItemType.Header,
    },
  ]

  useEffect(() => {
    setSelectedOptions(selectedKey)
  }, [setSelectedOptions, selectedKey])

  const selectableOptions = options.filter(
    (option) =>
      (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
  )

  const onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (event.type === 'blur') {
      return
    }
    const selected = option?.selected
    const currentSelectedOptionKeys = selectedOptions ? selectedOptions?.filter((key) => key !== 'selectAll') : []
    const isSelectAllOption = option?.itemType === SelectableOptionMenuItemType.SelectAll
    const isNeedToClearAllSelected =
      option?.text === CLEAR_SELECTION_TEXT || currentSelectedOptionKeys?.length === selectableOptions.length

    let updatedSelectedValues = currentSelectedOptionKeys

    if (option) {
      if (isSelectAllOption) {
        if (isNeedToClearAllSelected) {
          updatedSelectedValues = []
        } else {
          updatedSelectedValues = [...selectableOptions.map((o) => o.key as string)]
        }
      } else {
        if (selected) {
          if (!selectedOptionLimit || currentSelectedOptionKeys?.length < selectedOptionLimit) {
            updatedSelectedValues = [...currentSelectedOptionKeys, option?.key as string]
          }
        } else {
          updatedSelectedValues = currentSelectedOptionKeys.filter((k) => k !== option.key)
        }
      }
    }
    if (onChangeMultipleValues) {
      onChangeMultipleValues(event, isSelectAllOption ? [] : updatedSelectedValues)
    }
    setSelectedOptions(updatedSelectedValues)
  }

  const comboboxProps: IComboBoxProps = {
    ...getComboBoxProps(focusOnClick, filterSize, isError),
    disabled: disabled || isLoading,
    multiSelect: true,
    onChange,
    onRenderLabel,
    options: [...userOptions, ...options],
    placeholder,
    selectedKey: selectedOptions,
  }

  const testProps = {
    'data-automation-key': `filter-${label}`,
  }

  return (
    <>
      {control && name ? (
        <ControlledVirtualizedComboBox {...comboboxProps} {...testProps} name={name} control={control} />
      ) : (
        <SGVirtualizedComboBox {...comboboxProps} {...testProps} />
      )}
      {isFetching && <FetchingProgressIndicator />}
    </>
  )
}
