import { IComboBoxOption } from '@fluentui/react'
import { useServicePlans } from 'src/queries'
import { SkuServicePlansVM } from 'src/services/graphapi'
import { useOptions } from '../../hooks'
import { Filters } from 'src/enums'

export const useServicePlanOptions = (skuIds?: string, isSkuMappingReport?: boolean) => {
  const queryResult = useServicePlans(skuIds, isSkuMappingReport)
  const map = (item: string[]) => ({ key: item[0] || '', text: item[1] || '' } as IComboBoxOption)
  const transform = (items: SkuServicePlansVM[]) =>
    [...new Set(items.map((item) => item.id + ';' + item.name))].map((item) => item.split(';')).map((item) => map(item))
  const options = useOptions<SkuServicePlansVM>({
    queryResult,
    transform,
    name: Filters.CommerceServicePlan,
    showTitle: true,
  })

  return options
}
