import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getServices = async (divisionName?: string, organizationName?: string) => {
  const response = await apiService.services_Get(organizationName, divisionName)
  return response
}

export function useServices(divisionName?: string, organizationName?: string, enabled = true) {
  return useQuery(
    [SgQueryKey.services, divisionName, organizationName],
    () => getServices(divisionName, organizationName),
    {
      staleTime: 5 * MINUTE,
      enabled,
    },
  )
}
