import { getCurrentUrlSearchValue } from 'src/utils/WindowUtils'
import { acquireAccessTokenSilent } from 'src/services/msalInstance'

export class ServiceBase {
  // NOTE: Keep the keys lower case + remove trailing slash.
  static readonly ApiUrlMap = new Map([
    // Laptop
    ['https://laptop.microsoftservicegraph.io', 'https://api-laptop.microsoftservicegraph.io'],
    ['http://localhost:3000', 'https://localhost:44300'],

    // Private
    ['https://private.microsoftservicegraph.io', 'https://api-private.microsoftservicegraph.io'],
    [
      'https://app-servicegraph-ui-private-weeu.azurewebsites.net',
      'https://app-servicegraph-api-private-weeu.azurewebsites.net',
    ],

    // Dev
    ['https://dev.microsoftservicegraph.io', 'https://api-dev.microsoftservicegraph.io'],
    [
      'https://app-servicegraph-ui-dev-weeu.azurewebsites.net',
      'https://app-servicegraph-api-dev-weeu.azurewebsites.net',
    ],

    // Staging
    ['https://staging.microsoftservicegraph.io', 'https://api-staging.microsoftservicegraph.io'],
    [
      'https://servicegraph-ui-appservice-westeurope-staging.azurewebsites.net',
      'https://servicegraph-api-appservice-westeurope-staging.azurewebsites.net',
    ],

    // Production
    ['https://www.microsoftservicegraph.io', 'https://api.microsoftservicegraph.io'],
    ['https://microsoftservicegraph.io', 'https://api.microsoftservicegraph.io'],
    [
      'https://servicegraph-ui-appservice-westeurope-production.azurewebsites.net',
      'https://servicegraph-api-appservice-westeurope-production.azurewebsites.net',
    ],
  ])

  protected getBaseUrl = (_: string, __?: string) => {
    const url = window.location.origin.replace(/\/$/, '').toLowerCase()
    return (
      ServiceBase.ApiUrlMap.get(url) ||
      url.replace(
        /https:\/\/(app-)?servicegraph-ui-([^.]*).azurewebsites.net/,
        'https://$1servicegraph-api-$2.azurewebsites.net',
      )
    )
  }

  protected transformOptions = async (options: RequestInit): Promise<RequestInit> => {
    const accessToken = await acquireAccessTokenSilent()
    const currentUrlSearchParams = new URLSearchParams(getCurrentUrlSearchValue())

    if (accessToken) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
        'sg-cds-version': currentUrlSearchParams.get('v') ?? '',
      }
    }

    return Promise.resolve(options)
  }
}
