import {
  IStackItemStyles,
  IStackStyles,
  Label,
  memoizeFunction,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from '@fluentui/react'
import { FunctionComponent } from 'react'
import _ from 'lodash'
import { FetchingProgressIndicator } from '../ProgressIndicator'

export interface FormItemProps {
  label: string
  field?: string | JSX.Element
  required?: boolean
  isLoading?: boolean
  isRefetching?: boolean
}

const panelStackStyles: IStackStyles = {
  root: {
    margin: '1em 0 0 0',
  },
}
const col1StackItemStyles: IStackItemStyles = { root: { flex: 1 } }
//  minWidth 0, for flex items helps to avoid overflow.
const col2StackItemStyles: IStackItemStyles = {
  root: { minWidth: 0, flex: 3, alignSelf: 'center', wordBreak: 'break-word' },
}

const spinnerStyles = {
  root: {
    justifyContent: 'start',
    flexDirection: 'row',
  },
}

export const getNewFormItemId = memoizeFunction((label: string): string => {
  return _.camelCase(label)
})

export const FormItem: FunctionComponent<FormItemProps> = ({ label, required, children, isLoading, isRefetching }) => {
  const id = getNewFormItemId(label)
  return (
    <Stack horizontal styles={panelStackStyles} key={label}>
      <Label required={required} styles={col1StackItemStyles} htmlFor={id}>
        {label && `${label}:`}
      </Label>
      <Stack.Item styles={col2StackItemStyles}>
        {isLoading ? (
          <Spinner size={SpinnerSize.large} styles={spinnerStyles} />
        ) : _.isString(children) ? (
          <Text id={id}>{children}</Text>
        ) : (
          <Stack.Item>{children}</Stack.Item>
        )}
        {isRefetching && <FetchingProgressIndicator />}
      </Stack.Item>
    </Stack>
  )
}
