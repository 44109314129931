import { useMutation, useQueryClient } from 'react-query'
import { apiService } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

export interface UploadThreatModelCommand {
  serviceId: string
  content: string
}

const uploadThreatModel = async (command: UploadThreatModelCommand) => {
  await apiService.threatModels_Upload(command.serviceId, command.content)
}

export function useUploadThreatModel() {
  const queryClient = useQueryClient()

  return useMutation((command: UploadThreatModelCommand) => uploadThreatModel(command), {
    useErrorBoundary: true,
    onError: undefined,
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<any>(queryClient, SgQueryKey.tmErrors, (queryKey, data) => {
        return queryKey.includes(variables.serviceId) ? data : null
      })
      optimisticUpdateAndInvalidateQueries<any>(queryClient, SgQueryKey.tmNodes, (queryKey, data) => {
        return queryKey.includes(variables.serviceId) ? data : null
      })
      optimisticUpdateAndInvalidateQueries<any>(queryClient, SgQueryKey.dataFlows, (queryKey, data) => {
        return queryKey.includes(variables.serviceId) ? data : null
      })
      optimisticUpdateAndInvalidateQueries<any>(queryClient, SgQueryKey.tmFileExistence, (queryKey, data) => {
        return queryKey.includes(variables.serviceId) ? data : null
      })
    },
  })
}
