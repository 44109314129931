import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { FeatureType, FeatureParity, M365Cloud, SortOrder, SortColumn } from 'src/services/graphapi/lists'
import { useInfiniteQuery } from 'react-query'

const PAGE_SIZE = 200

export const getCloudParityReport = async (
  featureSearchName?: string,
  skuIds?: string,
  productsIds?: string,
  serviceIds?: string,
  cloudIds?: string,
  platformIds?: string,
  featureType?: FeatureType,
  ownerIds?: string,
  deploymentStatuses?: string,
  featureParity?: FeatureParity,
  partialParityCloud?: M365Cloud,
  includeOwnerMails?: boolean,
  sortOrder?: SortOrder,
  sortColumn?: SortColumn,
  startDate?: Date,
  endDate?: Date,
  skip?: number,
  limit?: number,
) => {
  const response = await apiService.reports_GetCloudParityReport(
    featureSearchName,
    skuIds,
    productsIds,
    serviceIds,
    cloudIds,
    platformIds,
    featureType,
    ownerIds,
    deploymentStatuses,
    sortOrder,
    sortColumn,
    featureParity,
    partialParityCloud,
    true,
    includeOwnerMails,
    skip,
    limit,
    startDate,
    endDate,
  )
  return response
}

export function useCloudParityReport(
  featureSearchName?: string,
  skuIds?: string,
  productsIds?: string,
  serviceIds?: string,
  cloudIds?: string,
  platformIds?: string,
  featureType?: FeatureType,
  ownerIds?: string,
  deploymentStatuses?: string,
  featureParity?: FeatureParity,
  partialParityCloud?: M365Cloud,
  includeOwnerMails?: boolean,
  sortOrder?: SortOrder,
  sortColumn?: SortColumn,
  startDate?: Date,
  endDate?: Date,
) {
  return useInfiniteQuery(
    [
      SgQueryKey.cloudParityReport,
      featureSearchName,
      skuIds,
      productsIds,
      serviceIds,
      cloudIds,
      platformIds,
      featureType,
      ownerIds,
      deploymentStatuses,
      featureParity,
      partialParityCloud,
      includeOwnerMails,
      sortOrder,
      sortColumn,
      startDate,
      endDate,
    ],
    ({ pageParam }) =>
      getCloudParityReport(
        featureSearchName,
        skuIds,
        productsIds,
        serviceIds,
        cloudIds,
        platformIds,
        featureType,
        ownerIds,
        deploymentStatuses,
        featureParity,
        partialParityCloud,
        includeOwnerMails,
        sortOrder,
        sortColumn,
        startDate,
        endDate,
        pageParam || 0,
        PAGE_SIZE,
      ),
    {
      getNextPageParam: (lastPage, _pages) => {
        return lastPage.pagination?.nextPage
      },
      refetchOnWindowFocus: false,
    },
  )
}
