import { useQuery } from 'react-query'
import { ResourceFlowState } from 'src/enums'
import { apiService } from 'src/services/graphapi'
import { M365Cloud, Environment } from 'src/services/graphapi/lists'
import { SgQueryKey } from './Shared'

const getResources = async (
  serviceId?: string,
  resourceGroup?: string,
  resourceEnv?: Environment,
  nodeId?: string,
  provider?: string,
  resourceType?: string,
  m365Cloud?: M365Cloud,
) => {
  const response = await apiService.resources_Get(
    serviceId,
    resourceGroup,
    resourceEnv,
    nodeId,
    provider,
    resourceType,
    m365Cloud,
  )
  return response.map((item) => ({ ...item, updateState: item.updateState || ResourceFlowState.ATTESTED }))
}

export function useResources(
  serviceId?: string,
  resourceGroup?: string,
  resourceEnv?: Environment,
  nodeId?: string,
  provider?: string,
  resourceType?: string,
  m365Cloud?: M365Cloud,
) {
  return useQuery(
    [SgQueryKey.resources, serviceId, resourceGroup, resourceEnv, nodeId, provider, resourceType, m365Cloud],
    () => getResources(serviceId, resourceGroup, resourceEnv, nodeId, provider, resourceType, m365Cloud),
    {
      enabled: !!serviceId,
    },
  )
}

const getResourceById = async (serviceId: string, resourceId: string) => {
  const result = await apiService.resources_GetById(serviceId, resourceId)
  if (result) {
    return { ...result, updateState: result.updateState || ResourceFlowState.ATTESTED }
  }
  return null
}

export function useResourceById(serviceId: string, resourceId: string) {
  return useQuery([SgQueryKey.resource, serviceId, resourceId], () => getResourceById(serviceId, resourceId), {
    enabled: !!serviceId && !!resourceId,
  })
}
