export enum ResourceFlowAction {
  REQUEST_TRANSFER = 'Request Transfer',
  ACCEPT = 'Accept',
  REVERT = 'Revert',
  REJECT = 'Reject',
}

export enum ResourceActionButton {
  MODIFY_RESOURCE = 'Modify Resource',
  REVERT_TRANSFER = 'Revert Transfer',
  ACCEPT_TRANSFER = 'Accept Transfer',
  REJECT_TRANSFER = 'Reject Transfer',
}

export enum ResourceFlowState {
  PENDING_TRANSFER = 'PendingTransfer',
  TRANSFER_APPROVED = 'TransferApproved',
  TRANSFER_REVERTED = 'TransferReverted',
  TRANSFER_REJECTED = 'TransferRejected',
  NEW = 'New',
  ATTESTED = 'Attested',
  RESOURCE_GROUP_TRANSFERRED = 'Resource Group Transferred',
}
