import {
  ActionButton,
  DefaultButton,
  getTheme,
  IStackTokens,
  mergeStyles,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
} from '@fluentui/react'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useWizard } from 'src/contexts'
import { StepBasicProps, Stepper } from '../Stepper'
import { Summary } from './Summary'
import { SAVE_BUTTON_TEXT, BACK_BUTTON_TEXT, NEXT_BUTTON_TEXT } from 'src/constants'

const theme = getTheme()

export interface WizardStepDetailProps {
  index: number
}

export interface WizardStepProps extends StepBasicProps {
  detail?: JSX.Element
}

export interface WizardProps {
  title: string
  stepDetails: WizardStepProps[]
  onDismiss: () => void
  onDone: () => void
}

const BORDER_STYLE = `1px solid ${theme.semanticColors.variantBorder}`

const panelStyles = {
  content: {
    borderTop: BORDER_STYLE,
    borderBottom: BORDER_STYLE,
    paddingBottom: 0,
  },
}

const wrapperTokens: IStackTokens = {
  childrenGap: 5,
}
const footerTokens: IStackTokens = {
  childrenGap: 20,
}

const wrapperStyles = mergeStyles({
  height: '100%',
})
const footerCol1Styles = mergeStyles({
  flex: 1,
})
const detailCol1Styles = mergeStyles([footerCol1Styles, { paddingTop: theme.spacing.l2, borderRight: BORDER_STYLE }])
const footerCol2Styles = mergeStyles({
  flex: 4,
  paddingLeft: theme.spacing.l2,
})
const detailCol2Styles = mergeStyles([footerCol2Styles, { paddingTop: theme.spacing.l1 }])

const getStepVisibilityStyle = (index: number, currentStep: number) => {
  return { display: index === currentStep ? 'block' : 'none' } // stylelint-disable-line selector-class-pattern
}

export const Wizard: FunctionComponent<WizardProps> = ({ title, stepDetails, onDismiss, onDone }) => {
  const [steps, setSteps] = useState<StepBasicProps[]>([])
  const {
    currentStep = 0,
    goBack,
    goNext,
    isDone,
    isViewOnly,
    wizardSteps,
    isSavingArr,
    backToFirstStep,
    skipToSummary,
    goToStep,
  } = useWizard()

  const stepDetailArr = useMemo(() => {
    return [...stepDetails, { label: 'Summary', detail: <Summary /> } as WizardStepProps]
  }, [stepDetails])

  const isSaving = useMemo(() => {
    return isSavingArr?.some((s) => s)
  }, [isSavingArr])

  useEffect(() => {
    setSteps(
      stepDetailArr.map((step, index) => {
        const wizardStep = wizardSteps && wizardSteps[index]
        const isSavingStep = isSavingArr && isSavingArr[index]
        const backgroundColor = wizardStep?.hasError ? theme.palette.red : undefined
        const iconName = wizardStep?.hasError ? 'StatusCircleErrorX' : isSavingStep ? 'ProgressLoopInner' : undefined

        return {
          label: step.label,
          checked: isDone || index < currentStep,
          checkedCurrentStep: index === currentStep,
          backgroundColor,
          iconName,
        } as StepBasicProps
      }),
    )
  }, [isDone, currentStep, wizardSteps, stepDetailArr, isSavingArr])

  const onOuterClick = useCallback(() => {}, [])

  const onStepChange = useCallback(
    (_label, _checked, index) => {
      if (goToStep) goToStep(index)
    },
    [goToStep],
  )

  const onRenderFooterContent = useCallback(() => {
    const isLastStep = currentStep === steps.length - 1
    return (
      <Stack horizontal grow tokens={wrapperTokens}>
        <Stack.Item className={footerCol1Styles}>
          {!isLastStep ? (
            <ActionButton
              text="Skip to Summary"
              iconProps={{ iconName: 'DoubleChevronLeftMedMirrored' }}
              allowDisabledFocus
              onClick={skipToSummary}
            />
          ) : (
            <ActionButton
              text="Back to the first step"
              iconProps={{ iconName: 'DoubleChevronLeftMed' }}
              allowDisabledFocus
              onClick={backToFirstStep}
            />
          )}
        </Stack.Item>
        <Stack horizontal verticalAlign="center" className={footerCol2Styles} tokens={footerTokens}>
          <DefaultButton text={BACK_BUTTON_TEXT} onClick={goBack} disabled={currentStep === 0 || isDone || isSaving} />
          {!isLastStep ? (
            <PrimaryButton text={NEXT_BUTTON_TEXT} onClick={goNext} disabled={isLastStep} />
          ) : (
            <PrimaryButton
              text={SAVE_BUTTON_TEXT}
              onClick={onDone}
              disabled={isViewOnly || isDone || isSaving}
              onRenderIcon={() => (isSaving ? <Spinner size={SpinnerSize.xSmall} /> : null)}
            />
          )}
        </Stack>
      </Stack>
    )
  }, [currentStep, goBack, goNext, backToFirstStep, skipToSummary, onDone, steps.length, isDone, isSaving, isViewOnly])

  return (
    <Panel
      isOpen={true}
      type={PanelType.large}
      headerText={title}
      onDismiss={onDismiss}
      onOuterClick={onOuterClick}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
      styles={panelStyles}
    >
      <Stack horizontal grow tokens={wrapperTokens} className={wrapperStyles}>
        <Stack.Item className={detailCol1Styles}>
          <Stepper steps={steps} selectable onStepChange={onStepChange} />
        </Stack.Item>
        <Stack.Item className={detailCol2Styles}>
          {stepDetailArr.map((stepDetail, index) => (
            <div key={stepDetail.label} style={getStepVisibilityStyle(index, currentStep)}>
              {stepDetail.detail}
            </div>
          ))}
        </Stack.Item>
      </Stack>
    </Panel>
  )
}
