import { ActionButton, getTheme, IButtonStyles, IColumn, IIconProps, PrimaryButton } from '@fluentui/react'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { DownloadButtonType, ReportFileType } from 'src/enums'
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js'
import { utils, writeFile, WritingOptions } from 'xlsx'
import { getFormattedDate } from 'src/utils'

export interface DownloadReportProps {
  columns?: IColumn[]
  data?: any[]
  fileTitle?: string
  worksheetName?: string
  text?: string
  reportFileType?: ReportFileType
  buttonType?: DownloadButtonType
  disabled?: boolean
  iconProps?: IIconProps
  styles?: IButtonStyles
  onReportDownload?: (report: string, fileName: string) => void
  performOnlyOnReportDownload?: boolean
  includeDateInFilename?: boolean
  onClickLoadData?: () => Promise<any[]>
}

const theme = getTheme()

const downloadIcon: IIconProps = { iconName: 'Download' }
const buttonStyles: IButtonStyles = {
  flexContainer: { flexDirection: 'row-reverse', color: theme.palette.blue },
}

export const DownloadReport: FunctionComponent<DownloadReportProps> = ({
  data = [],
  fileTitle = 'report',
  worksheetName = 'Data',
  text = 'Download Report',
  reportFileType = ReportFileType.CSV,
  buttonType = DownloadButtonType.ACTION_BUTTON,
  disabled = false,
  iconProps = downloadIcon,
  styles = buttonStyles,
  onClickLoadData,
  includeDateInFilename = true,
}) => {
  const [trackData, setTrackData] = useState<any>({ downloadCount: 0 })
  const appInsights = useAppInsightsContext()
  const trackDownload = useTrackEvent(appInsights, 'DownloadReport', trackData)

  useEffect(() => {
    if (trackData?.downloadCount > 0) {
      trackDownload({ fileTitle })
    }
  }, [trackData, fileTitle, trackDownload])

  const onClick = useCallback(async () => {
    const reportData = (onClickLoadData ? await onClickLoadData() : data).filter((d) => d)
    const worksheet = utils.json_to_sheet(reportData)
    const workbook = utils.book_new()
    const writingOptions = (
      reportFileType === ReportFileType.CSV ? { bookType: ReportFileType.CSV } : {}
    ) as WritingOptions
    utils.book_append_sheet(workbook, worksheet, worksheetName)
    writeFile(
      workbook,
      `${fileTitle}${includeDateInFilename ? `- ${getFormattedDate(new Date())}` : ''}.${reportFileType}`,
      writingOptions,
    )
    setTrackData((currentValue) => {
      return { ...currentValue, downloadCount: currentValue.downloadCount + 1 }
    })
  }, [data, fileTitle, includeDateInFilename, onClickLoadData, reportFileType, worksheetName])

  if (!data?.length && !onClickLoadData) {
    return null
  }

  const btnProps = {
    onClick,
    text,
    disabled,
  }
  return buttonType === DownloadButtonType.PRIMARY_BUTTON ? (
    <PrimaryButton {...btnProps} />
  ) : (
    <ActionButton {...btnProps} iconProps={iconProps} styles={styles} />
  )
}
