import { useMutation } from 'react-query'
import { CreateFeedbackCommand, apiService } from 'src/services/graphapi'

const sendFeedback = async (command: CreateFeedbackCommand) => {
  await apiService.feedbacks_CreateFeedback(command)
}

export function useSendFeedback() {
  return useMutation((command: CreateFeedbackCommand) => sendFeedback(command), {
    onError: undefined,
  })
}
