import { content, header, headerStackItemStyle } from './Styles'

import { AlertPayloadComponent } from '../../../../features/SGAlerts/base/types'
import { EDUBAimlInferencingAlertRawPayload } from './types'
import { Stack } from '@fluentui/react'
import { DatasetsList } from './DatasetsList'

export const AimlInferencingAlertPayload: AlertPayloadComponent<EDUBAimlInferencingAlertRawPayload> = ({
  rawPayload,
}) => {
  return (
    <Stack>
      <Stack.Item styles={headerStackItemStyle}>Description:</Stack.Item>
      <Stack.Item>
        The model <b>{rawPayload.ViolatedModel?.Name}</b> (version: <b>{rawPayload.ViolatedModel?.Version}</b>) has been
        detected to be inferenced via <b>{rawPayload.ModeInstance?.PathOnResource}</b> from the outside of the EU.
        Caller subscription: <b>{rawPayload.ViolatedResource?.SubscriptionName}</b> (Id:{' '}
        <b>{rawPayload.ViolatedResource?.SubscriptionId}</b>), caller service:{' '}
        <b>{rawPayload.ViolatedResource?.ServiceName}</b> (Id: <b>{rawPayload.ViolatedResource?.ServiceId}</b>), caller
        location: <b>{rawPayload.ViolatedResource?.Location}</b>. This is in violation of Microsoft EUDB commitments.
        This model is classified as {rawPayload.DataClassification?.join(', ')}, which means that it contains in-scope
        EU customer data that must not be copied outside of the EU. Please refer to the next section for incident
        details.
      </Stack.Item>
      <br></br>
      <Stack.Item styles={headerStackItemStyle}>To resolve violation:</Stack.Item>
      <ul>
        <li>
          Restrict access to {rawPayload.ModeInstance?.PathOnResource || '"Unknown"'} for Subscription Id{' '}
          {rawPayload.ViolatedResource?.SubscriptionId}, service Id {rawPayload.ViolatedResource?.ServiceId}
        </li>
        <li>Set alert as &quot;Resolved&quot;</li>
      </ul>
      <Stack.Item>
        If you believe this is a false positive, please update alert state as &quot;Dispute&quot; and provide
        appropriate justification in Comment section.
      </Stack.Item>

      <Stack.Item styles={headerStackItemStyle}>Information:</Stack.Item>
      <Stack.Item styles={header}>Model:</Stack.Item>
      <Stack styles={content}>
        <Stack.Item>Model Id: {rawPayload.ViolatedModel?.ModelId} </Stack.Item>
        <Stack.Item>DataClassificaiton: {(rawPayload.ViolatedModel?.DataClassification ?? []).join(', ')}</Stack.Item>
        <Stack.Item>
          DataBoundary: {(rawPayload.ViolatedModel?.DataBoundary ?? '').length == 0 ? 'No Boundary' : 'EUDB'}{' '}
        </Stack.Item>
      </Stack>

      <Stack.Item styles={header}>Model training datasets:</Stack.Item>
      <DatasetsList datasets={rawPayload.Datasets} />

      <Stack.Item styles={header}>Violated resource:</Stack.Item>
      <Stack styles={content}>
        <Stack.Item>Subscription Id: {rawPayload.ViolatedResource?.SubscriptionId} </Stack.Item>
        <Stack.Item>Subscription Name: {rawPayload.ViolatedResource?.SubscriptionName} </Stack.Item>
        <Stack.Item>Service Id: {rawPayload.ViolatedResource?.ServiceId} </Stack.Item>
        <Stack.Item>Service Name: {rawPayload.ViolatedResource?.ServiceName} </Stack.Item>
        <Stack.Item>Location: {rawPayload.ViolatedResource?.Location} </Stack.Item>
      </Stack>
    </Stack>
  )
}
