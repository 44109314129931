import { FunctionComponent } from 'react'
import { SGTableLongCellTooltip } from './SGTableLongCellTooltip'
import { SGTableLongCellText } from './SGTableLongCellText'

export interface SGTableLongTextCellProps {
  text: string
}

export const SGTableLongTextCell: FunctionComponent<SGTableLongTextCellProps> = ({ text }) => {
  return (
    <SGTableLongCellTooltip content={text}>
      <SGTableLongCellText text={text} />
    </SGTableLongCellTooltip>
  )
}
