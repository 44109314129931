import { FontSizes, memoizeFunction, mergeStyleSets } from '@fluentui/react'
import { memo } from 'react'

export interface ChipProps {
  label: string | undefined
  backgroundColor?: string | undefined
}

const getClassNames = memoizeFunction((backgroundColor: string | undefined) => {
  return mergeStyleSets({
    chip: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 25,
      width: '100%',
      maxWidth: 'auto',
      backgroundColor: backgroundColor ?? 'transparent',
      borderRadius: 2,
      cursor: 'default',
    },
    labelText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: 12,
      paddingRight: 12,
      whiteSpace: 'nowrap',
      fontSize: FontSizes.size12,
    },
  })
})

export const Chip = memo(({ label, backgroundColor }: ChipProps) => {
  const { chip, labelText } = getClassNames(backgroundColor)
  return (
    <div className={chip}>
      <span className={labelText}>{label}</span>
    </div>
  )
})
export default Chip
