import { IStackTokens, Stack } from '@fluentui/react'
import { FunctionComponent } from 'react'

export interface PageActionsProps {
  children?: any
}

const pageFiltersTokens: IStackTokens = { childrenGap: 15, padding: '0 5px 10px 5px' }

export const PageActions: FunctionComponent<PageActionsProps> = ({ children }) => {
  return (
    <Stack horizontal tokens={pageFiltersTokens} verticalAlign="end">
      {children}
    </Stack>
  )
}
