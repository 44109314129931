import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/graphapi'
import { useQuery } from 'react-query'

const getSGIDatasetDependencies = async (runId: string) => {
  const response = await apiService.sGI_GetSGIDatasetDependencies(runId)
  return response
}

export function useSGIDatasetDependencies(runId: string) {
  return useQuery([SgQueryKey.sgiDatasetDependencies, runId], () => getSGIDatasetDependencies(runId), {})
}
