import { Stack } from '@fluentui/react'
import { AlertPayloadComponent } from '../../../../features/SGAlerts/base/types'
import { EDUBAimlTrainingDatasetsAlertRawPayload } from './types'
import { content, header, headerStackItemStyle } from './Styles'
import { ResourceActionCell } from '../utils/ResourceActionCell'

export const AimlTrainingDatasetsAlertPayload: AlertPayloadComponent<EDUBAimlTrainingDatasetsAlertRawPayload> = ({
  rawPayload,
}) => {
  return (
    <Stack>
      <Stack.Item styles={headerStackItemStyle}>Description:</Stack.Item>
      <Stack.Item>
        The dataset <b>{rawPayload.ViolatedDataset?.DatasetId}</b> has been detected to be stored outside of the EU,
        which is in violation of Microsoft EUDB commitments. The dataset is classified as
        {rawPayload.DataClassification?.join(', ')}, which means that it contains in-scope EU customer data that must be
        stored and processed in the EU. Please refer to the next section for incident details.
      </Stack.Item>
      <br></br>
      <Stack.Item styles={headerStackItemStyle}>To resolve violation:</Stack.Item>
      <ul>
        <li>Delete dataset copy from the {rawPayload.ViolatedDataset?.PathOnResource || '"Unknown"'}</li>
        <li>Set alert as &quot;Resolved&quot;</li>
      </ul>
      <Stack.Item>
        If you believe this is a false positive, please update alert state as &quot;Dispute&quot; and provide
        appropriate justification in Comment section.
      </Stack.Item>

      <Stack.Item styles={headerStackItemStyle}>Information:</Stack.Item>
      <Stack.Item styles={header}>Dataset:</Stack.Item>
      <Stack styles={content}>
        <Stack.Item>Dataset Id: {rawPayload.ViolatedDataset?.DatasetId} </Stack.Item>
        <Stack.Item>DataClassificaiton: {rawPayload.DataClassification?.join(', ')} </Stack.Item>
        <Stack.Item>DataBoundary: EUDB </Stack.Item>
      </Stack>

      <Stack.Item styles={header}>Affected resource:</Stack.Item>
      <Stack styles={content}>
        <Stack.Item>
          Name:&nbsp;
          <ResourceActionCell
            serviceId={rawPayload.ViolatedResource?.ServiceId || ''}
            resourceId={rawPayload.ViolatedResource?.Id || ''}
            resourceName={rawPayload.ViolatedResource?.Name || ''}
          />
        </Stack.Item>
        <Stack.Item>Id: {rawPayload.ViolatedResource?.ArmId} </Stack.Item>
      </Stack>
    </Stack>
  )
}
