import { useQuery } from 'react-query'
import { apiService, DataFlowClouds, SwaggerException } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getDataFlowClouds = async (serviceId?: string, nodeId?: string) => {
  const response = await apiService.dataFlows_GetDataFlowClouds(serviceId, nodeId)
  return response
}
export function useDataFlowClouds(serviceId?: string, nodeId?: string) {
  return useQuery<DataFlowClouds, SwaggerException>(
    [SgQueryKey.dataFlowClouds, serviceId, nodeId],
    () => getDataFlowClouds(serviceId, nodeId),
    {
      enabled: !!serviceId,
    },
  )
}
