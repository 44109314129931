import { useQuery } from 'react-query'
import { apiService, SwaggerException } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getDoesTMFileExist = async (serviceId?: string) => {
  const response = await apiService.threatModels_GetFileExist(serviceId)
  return response
}

export function useTMFileExist(serviceId?: string) {
  return useQuery<boolean, SwaggerException>(
    [SgQueryKey.tmFileExistence, serviceId],
    () => getDoesTMFileExist(serviceId),
    {
      enabled: !!serviceId,
      staleTime: 0,
    },
  )
}
