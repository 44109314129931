import { useMutation } from 'react-query'
import { apiService } from 'src/services/graphapi'

const downloadThreatModel = async (serviceId?: string) => {
  const file = await apiService.threatModels_Download(serviceId)
  return file
}

export function useDownloadThreatModel() {
  return useMutation((serviceId?: string) => downloadThreatModel(serviceId), {
    useErrorBoundary: true,
    onError: undefined,
  })
}
