import { DirectionalHint, IButtonProps, TeachingBubble } from '@fluentui/react'
import { FunctionComponent, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useUrlAutoComplete } from '../AutoComplete/hooks'
import { SubTabs } from './enum'
import { useOnboardingStore } from 'src/hooks/useOnboardingStore'
import { getTourSteps } from './stepsService'
import { OK_BUTTON_TEXT, SKIP_BUTTON_TEXT } from 'src/constants'
import { LandingPages, QueryStringParam } from 'src/enums'

export const Tour: FunctionComponent = () => {
  const location = useLocation()
  const { urlValue } = useUrlAutoComplete({ urlKey: QueryStringParam.Tab })
  const [currentStep, setCurrentStep] = useState(0)
  const locationPath = location.pathname.substring(1) as LandingPages
  const steps = getTourSteps(locationPath, urlValue as SubTabs)
  const [isCurrentOnboardingCompleted, setCurrentOnboardingCompletion] = useOnboardingStore()

  useEffect(() => {
    setCurrentStep(0)
  }, [isCurrentOnboardingCompleted])

  if (!steps?.length || isCurrentOnboardingCompleted) {
    return <></>
  }

  const primaryButtonProps: IButtonProps = {
    children: OK_BUTTON_TEXT,
    onClick: () => {
      if (currentStep + 1 === steps.length) {
        setCurrentOnboardingCompletion()
      }
      setCurrentStep(currentStep + 1)
    },
  }

  const secondaryButtonProps: IButtonProps = {
    children: SKIP_BUTTON_TEXT,
    onClick: () => {
      setCurrentOnboardingCompletion()
      setCurrentStep(steps.length)
    },
  }

  return (
    <>
      {steps.length && steps[currentStep] && (
        <TeachingBubble
          calloutProps={{ directionalHint: steps[currentStep].directionalHint ?? DirectionalHint.bottomCenter }}
          target={steps[currentStep].target}
          isWide={true}
          footerContent={`${currentStep + 1} of ${steps.length}`}
          primaryButtonProps={primaryButtonProps}
          secondaryButtonProps={secondaryButtonProps}
          headline={steps[currentStep]?.title}
        >
          {steps[currentStep]?.content}
        </TeachingBubble>
      )}
    </>
  )
}
