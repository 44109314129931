import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/graphapi'
import { useQuery } from 'react-query'

const getSGIJobMetadataForRun = async (runId: string) => {
  const response = await apiService.sGI_GetSGIJobMetadataForRun(runId)
  return response
}

export function useSGIJobMetadataForRun(runId: string) {
  return useQuery([SgQueryKey.sgiJobMetadataForRun, runId], () => getSGIJobMetadataForRun(runId), { enabled: !!runId })
}
