import { IStackStyles, IStackTokens, Stack } from '@fluentui/react'

export interface DashboardProps {
  children?: any
  className?: string
  tokens?: IStackTokens
}

const dashboardTokens: IStackTokens = { childrenGap: 25 }
const dashboardStyles: IStackStyles = {
  root: {
    width: `100%`,
    overflow: 'visible',
  },
}

export const Dashboard = ({ children, className, tokens }: DashboardProps) => {
  return (
    <Stack
      horizontal
      verticalFill
      wrap
      horizontalAlign="start"
      verticalAlign="start"
      styles={dashboardStyles}
      tokens={tokens ?? dashboardTokens}
      className={className}
    >
      {children}
    </Stack>
  )
}
