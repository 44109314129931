import { Panel, PanelType, Separator } from '@fluentui/react'
import { FunctionComponent } from 'react'
import { ActionPanelFooter, ActionPanelFooterProps } from './ActionPanelFooter'

export interface ActionPanelProps {
  title: string
  type?: PanelType
  onDismiss: () => void
  footer?: ActionPanelFooterProps
}

export const ActionPanel: FunctionComponent<ActionPanelProps> = ({
  title,
  type = PanelType.medium,
  onDismiss,
  footer = {} as ActionPanelFooterProps,
  children,
}) => {
  const isFooterPresent = !!Object.keys(footer).length

  const onRenderFooterContent = () => {
    if (isFooterPresent) return <ActionPanelFooter {...footer} />
    else {
      return <></>
    }
  }

  return (
    <Panel
      isOpen={true}
      type={type}
      headerText={title}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={isFooterPresent}
      onDismiss={onDismiss}
      onOuterClick={() => {}}
      styles={({ theme }) => ({
        commands: {
          backgroundColor: theme?.semanticColors.bodyBackground,
        },
        footer: {
          backgroundColor: theme?.semanticColors.bodyBackground,
        },
      })}
    >
      <Separator />
      {children}
    </Panel>
  )
}
