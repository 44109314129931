import {
  AccountInfo,
  AuthenticationResult,
  AuthError,
  Configuration,
  EventType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser'
import { isValidAppSettings, loadAppSettings } from './appSettingsStore.js'

export class MsalSingleton {
  private static instance: MsalSingleton

  private msalInstance: IPublicClientApplication | null

  private constructor() {
    console.log('>>>> Creating MsalInstance singleton')
    this.msalInstance = null
  }

  public static get Instance(): MsalSingleton {
    if (!MsalSingleton.instance) {
      MsalSingleton.instance = new MsalSingleton()
    }

    return MsalSingleton.instance
  }

  public get MsalInstance(): IPublicClientApplication | null {
    if (this.msalInstance) {
      return this.msalInstance
    }

    this.msalInstance = this.createMsalInstance()
    if (!this.msalInstance) {
      console.warn(
        'Attempting to use MsalInstance before AppSettings have been obtained.',
        'If this is not during application bootstrap, expect a crash immediately!',
      )
    }

    return this.msalInstance
  }

  private createMsalInstance = (): IPublicClientApplication | null => {
    const appSettings = loadAppSettings()
    if (!isValidAppSettings(appSettings)) {
      return null
    }

    const pca = new PublicClientApplication(this.createMsalConfig())

    if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
      pca.setActiveAccount(pca.getAllAccounts()[0])
    }
    pca.enableAccountStorageEvents()

    pca.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload) {
        const { account } = event.payload as AuthenticationResult
        pca.setActiveAccount(account)
      } else if (
        event.eventType === EventType.ACQUIRE_TOKEN_FAILURE &&
        event.error &&
        event.error instanceof AuthError
      ) {
        const { errorCode } = event.error as AuthError
        if (
          errorCode === 'no_tokens_found' ||
          errorCode === 'interaction_required' ||
          errorCode === 'login_required' ||
          errorCode === 'consent_required'
        ) {
          pca.logoutRedirect()
        }
      }
    })

    return pca
  }

  private createMsalConfig = (): Configuration => {
    const ua = window.navigator.userAgent
    const isEdge = ua.indexOf('Edge/') > 0
    // Only needed if you need to support the redirect flow in Firefox incognito
    const isFirefox = ua.indexOf('Firefox') > 0

    const appSettings = loadAppSettings()

    return {
      auth: {
        clientId: String(appSettings.authClientId),
        authority: `https://login.microsoftonline.com/${appSettings.authTenantId}/`,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
      },
      cache: {
        storeAuthStateInCookie: isEdge || isFirefox,
        cacheLocation: 'localStorage',
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message)
                return
              case LogLevel.Info:
                console.info(message)
                return
              case LogLevel.Verbose:
                console.debug(message)
                return
              case LogLevel.Warning:
                console.warn(message)
                break
              default:
            }
          },
        },
      },
    }
  }
}

const createLoginRequest = () => {
  const appSettings = loadAppSettings()

  return {
    scopes: [appSettings.authScopes || ''],
    redirectUri: `${window.location.origin}/blank.html`,
  }
}

export const loginPopup = async (state: any) => {
  const authRequest = {
    ...createLoginRequest(),
    state: JSON.stringify(state),
  }
  try {
    await MsalSingleton.Instance.MsalInstance?.loginPopup(authRequest)
  } catch (err) {
    console.error(err)
  }
}

export const logoutRedirect = async () => {
  try {
    await MsalSingleton.Instance.MsalInstance?.logoutRedirect()
  } catch (err) {
    console.error(err)
  }
}
const acquireTokenSilent = async (): Promise<Promise<AuthenticationResult> | null> => {
  if (!MsalSingleton.Instance.MsalInstance) {
    return null
  }

  const activeAccount = MsalSingleton.Instance.MsalInstance.getActiveAccount()
  const accounts = MsalSingleton.Instance.MsalInstance.getAllAccounts()

  if (!activeAccount && accounts?.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     *
     * NOTE: parthadas: Leave this as is as AppSettings needs to be called before login.
     */

    return null
  }

  const account = activeAccount || (accounts && accounts[0])
  try {
    const authResult = await MsalSingleton.Instance.MsalInstance.acquireTokenSilent({
      ...createLoginRequest(),
      account,
    })

    return authResult
  } catch {
    await loginPopup({ path: `${location.pathname}${location.search}` })
    return null
  }
}

export const acquireAccessTokenSilent = async (): Promise<string | null> => {
  const token = await acquireTokenSilent()
  return token?.accessToken || null
}

export const acquireAccountSilent = async (): Promise<AccountInfo> => {
  const token = await acquireTokenSilent()
  return token?.account ?? ({} as AccountInfo)
}
