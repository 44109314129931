import React from 'react'
import { ProductOptionsProps, useProductOptions } from './useProductOptions'
import { useUrlAutoComplete } from '../../hooks'
import { CustomAutoCompleteProps, CustomFilterSizeProps } from '../Props'
import { AutoComplete } from '../../AutoComplete'
import { FilterSize } from 'src/components/Filters'
import { QueryStringParam } from 'src/enums'

interface ProductUrlAutoCompleteProps extends CustomAutoCompleteProps, ProductOptionsProps, CustomFilterSizeProps {}

export const ProductUrlAutoComplete: React.FunctionComponent<ProductUrlAutoCompleteProps> = (props) => {
  const { isOwnedProducts, filterSize = FilterSize.M } = props
  const options = useProductOptions({ isOwnedProducts, showTitle: true })
  const { urlValue, onChange } = useUrlAutoComplete({ urlKey: QueryStringParam.ProductId })

  return <AutoComplete {...options} selectedKey={urlValue} {...props} onChange={onChange} filterSize={filterSize} />
}
