import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/graphapi'
import { useQuery } from 'react-query'

const getSGIDatasets = async (runId: string) => {
  const response = await apiService.sGI_GetSGIDatasets(runId)
  return response
}

export function useSGIDatasets(runId: string) {
  return useQuery([SgQueryKey.sgiDatasets, runId], () => getSGIDatasets(runId), {})
}
