import { QueryStringParam } from 'src/enums'

export interface UrlFilterProps {
  urlKey: QueryStringParam
  dependents?: UrlFilterProps[]
  defaultUrlValue?: boolean | string
}

export const getEveryDependent = (dependents: UrlFilterProps[] | undefined): QueryStringParam[] | undefined => {
  return dependents?.flatMap((d) => d.urlKey) || undefined
}
