import { IStackItemStyles, Stack } from '@fluentui/react'
import { AlertPayloadComponent } from '../../../../features/SGAlerts/base/types'
import { ResourceAsymmetryAlertRawPayload } from './types'
import { ViolatedResourcesTable } from './ViolatedResourcesTable'
import { COLOR_LABELS } from 'src/constants'

const headerStackItemStyle: IStackItemStyles = {
  root: {
    color: COLOR_LABELS.MEDIUM_BLUE,
    fontSize: '14',
    fontWeight: 'bold',
  },
}

export const ResourceAsymmetryAlertPayload: AlertPayloadComponent<ResourceAsymmetryAlertRawPayload> = ({
  alert,
  rawPayload,
}) => {
  const dcTitles = rawPayload.DataClassification?.join(', ')
  return (
    <Stack>
      <Stack.Item styles={headerStackItemStyle}>Description:</Stack.Item>
      <Stack.Item>This is the EU Data Boundary violation.</Stack.Item>
      <Stack.Item>
        According to the EUDB compliance, Services from EUDB Scope must store and process EU customer data classified as
        CC&#x2F;EUII&#x2F;EUPI&#x2F;SD within the EU Data Boundary.
      </Stack.Item>
      <Stack.Item>
        We found the selected service is assigned to EUDB Scope and contains resource instances of&nbsp;
        <b>{alert.triggerTitle}</b> with <b>{dcTitles}</b> data classification.
      </Stack.Item>
      <Stack.Item>
        We suspect that your service is potentially transferring <b>{dcTitles}</b> data outside of EU, since no&nbsp;
        <b>{alert.triggerTitle}</b> were found in EU.
      </Stack.Item>
      <br />
      <Stack.Item styles={headerStackItemStyle}>Violated Resources:</Stack.Item>
      <Stack.Item>
        <ViolatedResourcesTable serviceId={alert.ownerId} resources={rawPayload.ViolatedResources} />
      </Stack.Item>
      <br />
      <Stack.Item styles={headerStackItemStyle}>Remediation steps:</Stack.Item>
      <Stack.Item>Please follow the instructions to remediate this alert.</Stack.Item>
      <Stack.Item>
        If you consider the alert is <b>correct</b> and issue exists:
      </Stack.Item>
      <Stack.Item>
        <ol type="1">
          <li>
            Set a new status <b>Investigating</b>, meaning that remediation is started.
          </li>
          <li>
            Fix a root cause of the alert; potential fixes:
            <ol type="A">
              <li>Deploy same resource in EU Data Boundary.</li>
              <li>
                Review and update data classification for all resources from <b>Violated Resources</b> list.
              </li>
            </ol>
          </li>
          <li>
            Set status <b>Resolved</b> after a root cause is fixed.
          </li>
          <li>It can take several days for Service Graph to get new information and close the alert automatically.</li>
        </ol>
      </Stack.Item>
      <Stack.Item>
        In case you already know the alert <b>is not valid</b> or have some concerns about its correctness, please do
        the following:
      </Stack.Item>
      <Stack.Item>
        <ol type="1">
          <li>
            Set status <b>Dispute</b> with justification in the comment box explaining why this alert is not correct.
          </li>
          <li>
            Optionally, reach out Service Graph EUDB team for clarifications:&nbsp;
            <a href="mailto:sg-eudb@microsoft.com">sg-eudb@microsoft.com</a>.
          </li>
        </ol>
      </Stack.Item>
    </Stack>
  )
}
