import { useQuery } from 'react-query'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const GetLinkableDataFlowSignals = async (serviceId?: string, dataFlowId?: string) => {
  const response = await apiService.serviceDataFlow_GetLinkableDataFlows(serviceId, dataFlowId)
  return response
}

export function useLinkableDataFlowSignals(serviceId?: string, dataFlowId?: string) {
  return useQuery(
    [SgQueryKey.linkableDataFlows, serviceId, dataFlowId],
    () => GetLinkableDataFlowSignals(serviceId, dataFlowId),
    {
      enabled: !!serviceId && !!dataFlowId,
    },
  )
}
