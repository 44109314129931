import { SearchType, apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery } from 'react-query'
import { FeatureType } from 'src/services/graphapi/lists'

export const getFeatures = async (
  ownersIds?: string,
  productsIds?: string,
  featureName?: string,
  featureNameSearchType?: SearchType,
  featureType?: FeatureType,
) => {
  const response = await apiService.features_GetFeatures(
    ownersIds ?? '',
    productsIds ?? '',
    '',
    featureName,
    featureNameSearchType,
    featureType,
  )
  return response
}

export function useFeatures(
  ownersIds?: string,
  productsIds?: string,
  featureName?: string,
  featureNameSearchType?: SearchType,
  featureType?: FeatureType,
) {
  return useQuery([SgQueryKey.features, ownersIds, productsIds, featureType], () =>
    getFeatures(ownersIds, productsIds, featureName, featureNameSearchType, featureType),
  )
}
