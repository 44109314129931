import {
  ActionButton,
  Callout,
  FontWeights,
  getTheme,
  IButtonStyles,
  IIconProps,
  mergeStyles,
  NeutralColors,
} from '@fluentui/react'
import { useBoolean, useId } from '@fluentui/react-hooks'
import React, { FunctionComponent, useMemo } from 'react'
import { tourTargetClasses } from '../Tour/tourTargets'
import { useSearchParams } from 'react-router-dom'
import { getEveryDependent, UrlFilterProps } from '../UrlFilter'

interface AdditionalFiltersProps {
  children: any
}

const theme = getTheme()

const filtersIcon: IIconProps = { iconName: 'Filter' }
const appliedFiltersIcon: IIconProps = { iconName: 'FilterSolid' }

const buttonTextColor = theme.palette.blue

const getButtonStyles: (boolean) => IButtonStyles = (boldFont) => ({
  root: {
    width: 165,
    color: buttonTextColor,
    fontWeight: boldFont ? FontWeights.semibold : FontWeights.regular,
  },
  rootHovered: {
    backgroundColor: NeutralColors.gray30,
  },
  rootPressed: {
    backgroundColor: NeutralColors.gray40,
    color: buttonTextColor,
  },
  flexContainer: { flexDirection: 'row-reverse' },
  icon: { color: buttonTextColor, marginLeft: 0 },
  iconPressed: { color: buttonTextColor },
  textContainer: { flexGrow: 1 },
  label: { margin: 0 },
})

const calloutClass = mergeStyles({
  width: 320,
  maxWidth: '90%',
  padding: '20px 24px',
})

export const AdditionalFilters: FunctionComponent<AdditionalFiltersProps> = ({ children }) => {
  const [searchParams] = useSearchParams()
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false)
  const buttonId = useId('callout-button')

  const elements = React.Children.toArray(children)
  const appliedFiltersCount = elements
    .map((element) => {
      if (!React.isValidElement(element)) return 0

      const { urlKey, dependents }: UrlFilterProps = element.props

      const urlKeys = [urlKey, ...(getEveryDependent(dependents) || [])]

      return urlKeys.map((u) => Number(searchParams.has(u) ? 1 : 0))
    })
    .flat()
    .reduce((sum, current) => sum + current, 0)

  const isAppliedFilters = appliedFiltersCount > 0

  const buttonText = 'Additional filters' + (isAppliedFilters ? ` (${appliedFiltersCount})` : '')
  const buttonIcon = isAppliedFilters ? appliedFiltersIcon : filtersIcon
  const buttonStyles = useMemo(() => getButtonStyles(isAppliedFilters), [isAppliedFilters])

  return (
    <>
      <ActionButton
        className={tourTargetClasses.additionalFilters}
        iconProps={buttonIcon}
        id={buttonId}
        onClick={toggleIsCalloutVisible}
        text={buttonText}
        styles={buttonStyles}
      />
      {isCalloutVisible && (
        <Callout
          className={calloutClass}
          target={`#${buttonId}`}
          hideOverflow
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
        >
          {children}
        </Callout>
      )}
    </>
  )
}
