import { useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { getEcsConfig, getServices, SgQueryKey } from 'src/queries'

export function DataPreloader({ children }) {
  const queryClient = useQueryClient()

  const preloadData = useCallback(async () => {
    await queryClient.prefetchQuery([SgQueryKey.configFlags], () => getEcsConfig())
    await queryClient.prefetchQuery([SgQueryKey.services], () => getServices())
  }, [queryClient])

  useEffect(() => {
    preloadData()
  }, [preloadData])

  return <>{children}</>
}
