import { useMutation, useQueryClient } from 'react-query'
import { UpdateFeatureAttributesCommand, apiService, FeatureAttributesVM } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const updateFeatureAttributes = async (command: UpdateFeatureAttributesCommand) => {
  const res = await apiService.features_UpdateFeatureAttributes(command.featureId ?? '', command)
  return res
}

export function useSaveFeatureAttributes() {
  const queryClient = useQueryClient()

  return useMutation((command: UpdateFeatureAttributesCommand) => updateFeatureAttributes(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<FeatureAttributesVM>(
        queryClient,
        SgQueryKey.featureAttributes,
        (queryKey, data) => {
          if (queryKey[1] === variables.featureId) {
            return data
          }
          return undefined
        },
      )
    },
  })
}
