import { FunctionComponent } from 'react'
import { getTheme, mergeStyleSets, Text } from '@fluentui/react'

export interface SGTableLongCellTextProps {
  text: string
}

const theme = getTheme()

const getClassNames = () =>
  mergeStyleSets({
    title: {
      width: '100%',
      fontSize: theme.fonts.small.fontSize,
      color: 'inherit',
    },
  })

export const SGTableLongCellText: FunctionComponent<SGTableLongCellTextProps> = ({ text }) => {
  const classes = getClassNames()

  return (
    <Text className={classes.title} block nowrap>
      {text}
    </Text>
  )
}
