import { FunctionComponent } from 'react'
import { useHref } from 'react-router-dom'
import { CompliantExternalLink } from 'src/components'

interface Props {
  serviceId: string
  resourceId: string
  resourceName: string
}

export const ResourceActionCell: FunctionComponent<Props> = ({ serviceId, resourceId, resourceName }) => {
  const managePanelUrl = useHref(`/resources?serviceId=${serviceId}&id=${resourceId}`)
  return (
    <CompliantExternalLink href={managePanelUrl} target="_blank">
      {resourceName}
    </CompliantExternalLink>
  )
}
