import { useQuery } from 'react-query'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export function useResourceProviderTypes(serviceId?: string) {
  return useQuery(
    [SgQueryKey.resourceProvidersWithTypes, serviceId],
    () =>
      apiService
        .resourceProviders_GetWithTypes(serviceId)
        .then((arr) =>
          arr.sort((a, b) =>
            (a.provider ?? '') + (a.resourceType ?? '') < (b.provider ?? '') + (b.resourceType ?? '') ? -1 : 1,
          ),
        ),
    { enabled: !!serviceId, refetchOnWindowFocus: false },
  )
}
