import { getTheme, mergeStyleSets, Stack, Text } from '@fluentui/react'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { CompliantExternalLink } from 'src/components'

const theme = getTheme()
const getClassNames = () =>
  mergeStyleSets({
    container: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.white,
    },
  })

export const Login = ({ onLoginClick }) => {
  const classes = getClassNames()

  const portalName = 'Microsoft Service Graph'
  return (
    <Stack className={classes.container}>
      <Text variant="xLargePlus">{portalName}</Text>
      <Text variant="medium">
        <b>For additional help: </b> Contact&nbsp;
        <CompliantExternalLink
          // eslint-disable-next-line max-len
          href={`mailto:servicegraphhelp@microsoft.com?subject=Request to access ${portalName}&amp;body=Hi, Please grant me access to the portal. Thanks!`}
          role="link"
        >
          servicegraphhelp@microsoft.com
        </CompliantExternalLink>
        <br />
      </Text>
      <br />
      <PrimaryButton text="Login" onClick={onLoginClick} />
    </Stack>
  )
}

export default Login
