import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FetchQueryOptions, QueryClient, QueryClientProvider } from 'react-query'
import { ErrorDialog } from 'src/components'
import { MINUTE } from 'src/constants/TimeConstants'
import { isClientError } from 'src/hooks'
import { getAppSettings, SgQueryKey } from 'src/queries'
import { SwaggerException } from 'src/services/graphapi'
const options: FetchQueryOptions = {
  cacheTime: 5 * MINUTE,
  staleTime: 1 * MINUTE,
  retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  retry: (failureCount, error: any) => !isClientError(error) && failureCount < 3,
}
export const ReactQueryProvider: React.FunctionComponent = ({ children }) => {
  const [queryError, setQueryError] = useState<SwaggerException | undefined>()

  const onError = useCallback((error: unknown) => setQueryError(error as SwaggerException), [])

  const client = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          ...options,
          onError,
        },
        mutations: {
          onError,
        },
      },
    })
  }, [onError])

  const preFetchData = useCallback(async () => {
    await client.prefetchQuery([SgQueryKey.appSettings], () => getAppSettings(), { ...options, staleTime: 10 * MINUTE })
  }, [client])

  useEffect(() => {
    preFetchData()
  }, [preFetchData])

  const onDismissErrorDialog = useCallback(() => {
    setQueryError(undefined)
  }, [])

  return (
    <QueryClientProvider client={client}>
      {children}
      {queryError && <ErrorDialog error={queryError} onDismiss={onDismissErrorDialog} />}
    </QueryClientProvider>
  )
}
