import { lazy } from 'react'
import { ILandingPage, IPage } from '../Models'
import { Pages } from 'src/enums'

const NotFoundPage = lazy(() => import('../../../pages/NotFound/NotFound'))

const pages = new Map<Pages, IPage>([[Pages.App, { title: 'Metagraph App', element: <NotFoundPage /> } as IPage]])

export const Metagraph = {
  navigationTitle: 'Metagraph',
  icon: 'ProductVariant',
  element: <NotFoundPage />,
  pages,
} as ILandingPage
