import { loadUsersServiceIds } from './userProfileStore'

const SettingsKey = 'SG_USER_SETTINGS_KEY'

export interface IUserSettings {
  selectedServiceId: string
}

export const saveUserSettings = (userSettings?: IUserSettings) =>
  localStorage.setItem(SettingsKey, JSON.stringify(userSettings || ({} as IUserSettings)))

export const loadUserSettings = (): IUserSettings => {
  const userSettings = localStorage.getItem(SettingsKey)
  if (userSettings) {
    return JSON.parse(userSettings) as IUserSettings
  }

  const usersServices = loadUsersServiceIds()
  return {
    selectedServiceId: usersServices[0],
  } as IUserSettings
}
