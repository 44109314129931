import { lazy } from 'react'
import { ILandingPage } from '../Models'
import { CLOUD_PARITY_REPORT, CONFIDENTIAL_FLAG } from 'src/constants'

const CloudParityReportPage = lazy(() => import('../../../pages/CloudParityReport/CloudParityReport'))

export const CloudParityReportRoute = {
  navigationTitle: CLOUD_PARITY_REPORT,
  icon: 'ShowResultsMirrored',
  description: [`(${CONFIDENTIAL_FLAG})`],
  externalLink: 'https://eng.ms/docs/experiences-devices/ed-inventory-management/experiencesymmetry/cloudparity',
  externalLinkText: 'Report Manual',
  securityGroup: 'Experience Symmetry Cloud Parity Report',
  element: <CloudParityReportPage />,
} as ILandingPage
