import React from 'react'
import { WizardMode } from './WizardModeEnum'

export interface WizardSummaryItem {
  label: string
  value: string
}

export interface IWizardStepFunctions {
  validate: () => Promise<boolean>
  getSummary: () => WizardSummaryItem[]
  save: () => Promise<boolean>
}

export interface IWizardStep extends IWizardStepFunctions {
  isSaving: boolean
  hasError: boolean
}

export interface ContextData {
  id: string
  mode: WizardMode
  summaryMessage: (allStepsSuccessful: boolean) => string
}

export interface IWizard {
  currentStep: number
  isSavingArr: boolean[]
  isDone: boolean
  isViewOnly: boolean
  wizardSteps: IWizardStep[]
  onDone: () => void
  backToFirstStep: () => void
  skipToSummary: () => void
  goToStep: (index: number) => void
  setData: (string, WizardMode, summaryMessage) => void
  getData: () => ContextData
  getSummary: () => WizardSummaryItem[]
  goBack: () => void
  goNext: () => void
  register: (id: number, functions: IWizardStepFunctions) => void
  makeViewOnly(): void
}

export const WizardContext = React.createContext<Partial<IWizard>>({})
