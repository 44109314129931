export const OnboardingSettingsKey = 'SG_USER_ONBOARDING'

interface SubTabOnboarding {
  [key: string]: boolean
}

export interface OnboardingStore {
  [key: string]: SubTabOnboarding
}

export interface UserOnboardedSettings {
  userOnboarded: OnboardingStore
}

export const saveOnboardingSettings = (userSettings?: UserOnboardedSettings) =>
  localStorage.setItem(OnboardingSettingsKey, JSON.stringify(userSettings || ({} as UserOnboardedSettings)))

export const loadOnboardingSettings = (): UserOnboardedSettings => {
  const userSettings = localStorage.getItem(OnboardingSettingsKey)
  if (userSettings) {
    return JSON.parse(userSettings) as UserOnboardedSettings
  }

  return {
    userOnboarded: {},
  } as UserOnboardedSettings
}
