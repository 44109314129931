import { lazy } from 'react'
import { ILandingPage } from '../Models'

const WelcomePage = lazy(() => import('../../../pages/Welcome/Welcome'))

export const Welcome = {
  index: true,
  title: 'Service Graph',
  navigationTitle: 'Home',
  icon: 'Home',
  imagePath: '/images/Logo.png',
  imageAltText: 'Service Graph Logo',
  description: [
    'Service Graph is a continuous monitoring platform designed to analyze system signals, \n\
    generate an accurate representation of the service and detect data boundary violations.\n\
    It works by connecting multiple disparate data sources to build an accurate representation \n\
    of underlying systems in a form of a graph. Primary focus is monitoring of EU Data Boundary compliance and \n\
    alerting of possible violations.',
    'You can start by following steps below:',
  ],
  element: <WelcomePage />,
} as ILandingPage
