import { FunctionComponent, useCallback } from 'react'
import {
  IShimmeredDetailsListProps,
  DetailsListLayoutMode,
  ConstrainMode,
  ProgressIndicator,
  IProgressIndicatorStyles,
  getTheme,
  Check,
  TooltipHost,
  Text,
  IRenderFunction,
  IDetailsHeaderProps,
  IDetailsColumnRenderTooltipProps,
  IDetailsListStyles,
  CheckboxVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  mergeStyleSets,
  ISelection,
  IDetailsFooterProps,
  IColumn,
  memoizeFunction,
  IDetailsColumnStyles,
  IDetailsRowProps,
} from '@fluentui/react'
import { SGTableFooter, SGTableFooterSharedProps } from './SGTableFooter'
import { SGTableMessage, SGTableMessageSharedProps } from './SGTableMessage'
import { tourTargetClasses } from '../Tour'

export interface SGTableErrorProps {
  error?: Error
  refetch?: () => void
  showErrorHeader?: boolean
}

export interface SGTableProps extends IShimmeredDetailsListProps, SGTableMessageSharedProps, SGTableFooterSharedProps {
  name?: string
  isLoading?: boolean
  isRefetching?: boolean
  noDataMsg?: string
  disabledSelectionTooltipContent?: JSX.Element
  isFetchingNextPage?: boolean
  onLoadMoreData?: () => void
}

const theme = getTheme()

const classes = mergeStyleSets({
  row: {
    flex: '0 0 auto',
  },
  column: {
    display: 'flex !important',
    alignItems: 'center',
  },
  checkboxCell: {
    display: 'flex',
    alignItems: 'center',
  },
})

const columnStyles: Partial<IDetailsColumnStyles> = {
  cellName: {
    width: '100%',
    '@media(max-width: 1439px)': {
      fontSize: theme.fonts.small.fontSize,
    },
    '@media(max-width: 1365px)': {
      fontSize: theme.fonts.tiny.fontSize,
    },
  },
}

const gridStyles: Partial<IDetailsListStyles> = {
  root: {
    height: '100%',
    overflow: 'hidden',
    '& [role=grid]': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
  },
  headerWrapper: {
    flex: '0 0 auto',
  },
  contentWrapper: {
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}

const progressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
  root: {
    margin: -10,
    marginBottom: 8,
    borderRadius: theme.effects.roundedCorner2,
  },
  itemProgress: {
    padding: 0,
    borderRadius: theme.effects.roundedCorner2,
  },
}

const defaultProps: IShimmeredDetailsListProps = {
  setKey: 'items',
  items: [],
  columns: [],
  checkboxVisibility: CheckboxVisibility.hidden,
  layoutMode: DetailsListLayoutMode.fixedColumns,
  isHeaderVisible: true,
  selectionMode: SelectionMode.none,
  constrainMode: ConstrainMode.unconstrained,
  enterModalSelectionOnTouch: true,
  selectionPreservedOnEmptyClick: true,
  ariaLabelForSelectionColumn: 'Toggle selection',
  ariaLabelForSelectAllCheckbox: 'Toggle selection for all items',
  checkButtonAriaLabel: 'select row',
}

const getWithColumnDefaultProps: IColumn[] | any = memoizeFunction((columns: IColumn[] | any) => {
  return columns.map((column) => {
    return { isPadded: false, className: classes.column, styles: columnStyles, ...column }
  })
})

export const SGTable: FunctionComponent<SGTableProps> = ({
  name = 'data',
  isLoading = false,
  isRefetching = false,
  noDataMsg,
  columns,
  items,
  errorFallback,
  disableSelectionZone,
  footerAction,
  footerColumns,
  disabledSelectionTooltipContent = <Text>Disabled</Text>,
  isFetchingNextPage,
  onLoadMoreData,
  ...restDListProps
}) => {
  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
      return null
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
      <TooltipHost {...tooltipHostProps} />
    )
    return (
      defaultRender?.({
        ...props,
        onRenderColumnHeaderTooltip,
      }) || <></>
    )
  }

  const onRenderDetailsFooter: IRenderFunction<IDetailsFooterProps> = (props, _defaultRender) => {
    if (!props) {
      return null
    }
    return <SGTableFooter {...props} footerAction={footerAction} footerColumns={footerColumns} />
  }

  const onRenderCustomCheckBox = (props): JSX.Element => {
    if (disableSelectionZone) {
      return (
        <TooltipHost content={disabledSelectionTooltipContent}>
          <Check checked={false} />
        </TooltipHost>
      )
    }
    return <Check checked={props.checked} />
  }

  const onRenderCustomPlaceholder = useCallback(
    (rowProps: IDetailsRowProps, _index?: number, defaultRender?: (props: IDetailsRowProps) => React.ReactNode) => {
      if (!isFetchingNextPage && !isLoading && !isRefetching && onLoadMoreData) {
        onLoadMoreData()
      }

      if (defaultRender) return defaultRender(rowProps)

      return <></>
    },
    [isFetchingNextPage, isLoading, isRefetching, onLoadMoreData],
  )

  const completeRestDListProps = disableSelectionZone
    ? { ...restDListProps, selectionZoneProps: { isSelectedOnFocus: false, selection: {} as ISelection } }
    : restDListProps

  return (
    <>
      {isRefetching && <ProgressIndicator styles={progressIndicatorStyles} />}
      <ShimmeredDetailsList
        {...defaultProps}
        items={items}
        columns={getWithColumnDefaultProps(columns)}
        ariaLabelForShimmer={`${name} is being fetched`}
        enableShimmer={isLoading}
        disableSelectionZone={disableSelectionZone}
        onRenderDetailsHeader={onRenderDetailsHeader}
        onRenderDetailsFooter={(footerAction || footerColumns) && items.length ? onRenderDetailsFooter : undefined}
        onRenderCheckbox={onRenderCustomCheckBox}
        detailsListStyles={gridStyles}
        checkboxCellClassName={classes.checkboxCell}
        onRenderCustomPlaceholder={onRenderCustomPlaceholder}
        {...completeRestDListProps}
        className={tourTargetClasses.shimmeredDetailsList}
      />
      <SGTableMessage
        errorFallback={errorFallback}
        isLoading={isLoading}
        itemCount={items.length}
        name={name}
        noDataMsg={noDataMsg}
      />
    </>
  )
}
