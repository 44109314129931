export enum ColumnDataFlowKey {
  DATA_FLOW_STATE = 'DataFlowState',
  FLOW_ACTION = 'FlowAction',
  HISTORY = 'History',
}

export enum DropDownPanelSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}
