import { getTheme, mergeStyles, Stack } from '@fluentui/react'
import { WarningIcon, InfoIcon, ErrorIcon } from '../Icons'
import { DisplayInformationType } from './'

export interface DisplayInformationProps {
  message: string
  type: DisplayInformationType
  displayIcon?: boolean
}
const theme = getTheme()
const themePalatte = theme.palette

const getColor = (type: DisplayInformationType) => {
  switch (type) {
    case DisplayInformationType.Information:
      return themePalatte.green
    case DisplayInformationType.Warning:
      return themePalatte.orange
    default:
      return themePalatte.red
  }
}

const getIcon = (type: DisplayInformationType) => {
  switch (type) {
    case DisplayInformationType.Information:
      return <InfoIcon />
    case DisplayInformationType.Warning:
      return <WarningIcon />
    default:
      return <ErrorIcon />
  }
}

const getInfoClass = (type: DisplayInformationType) => {
  return mergeStyles({
    fontSize: 15,
    color: getColor(type),
  })
}

export const DisplayInformation = ({ message, type, displayIcon = false }: DisplayInformationProps) => {
  const infoClassName = getInfoClass(type)
  return (
    <Stack
      horizontal
      className={infoClassName}
      verticalFill
      verticalAlign="center"
      horizontalAlign="center"
      tokens={{ padding: 15 }}
    >
      {displayIcon && getIcon(type)}
      {message}
    </Stack>
  )
}
