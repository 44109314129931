import { useMutation, useQueryClient } from 'react-query'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey, optimisticUpdateAndInvalidateQueries } from './Shared'

interface DeleteFeatureCommentCommand {
  commentId: string
  featureId: string
}

const deleteFeatureComment = async (command: DeleteFeatureCommentCommand) => {
  const { commentId, featureId } = command
  await apiService.features_DeleteFeatureComment(featureId, commentId)
}

export function useDeleteFeatureComment() {
  const queryClient = useQueryClient()

  return useMutation((command: DeleteFeatureCommentCommand) => deleteFeatureComment(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<any>(queryClient, SgQueryKey.featureComments, (queryKey, data) => {
        if (queryKey[1] === variables.featureId) {
          return data
        }
        return undefined
      })
    },
  })
}
