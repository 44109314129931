import { StatusCodes } from 'http-status-codes'

export const isClientError = (error: any) => {
  const status = Number(error?.status ?? error?.response?.status)
  return status >= StatusCodes.BAD_REQUEST && status < StatusCodes.INTERNAL_SERVER_ERROR
}

export const isServerError = (error: any) => {
  const status = Number(error?.status ?? error?.response?.status)
  return status >= StatusCodes.INTERNAL_SERVER_ERROR
}

export const useStatusCodes = () => {
  return { isClientError, isServerError }
}
