import { useQuery } from 'react-query'
import { KpiSummaryGroupingType } from 'src/enums'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getOverviewKpiSummary = async (
  groupingType: KpiSummaryGroupingType,
  divisionName?: string,
  organizationName?: string,
  serviceId?: string,
  workstream1Only?: boolean,
) => {
  const response = await apiService.kpi_GetOverviewKpiSummary(
    groupingType,
    divisionName,
    organizationName,
    serviceId,
    workstream1Only,
  )
  return response
}

export function useOverviewKpiSummary(
  groupingType: KpiSummaryGroupingType,
  divisionName?: string,
  organizationName?: string,
  serviceId?: string,
  workstream1Only?: boolean,
) {
  return useQuery(
    [SgQueryKey.overviewKpiSummary, groupingType, divisionName, organizationName, serviceId, workstream1Only],
    () => getOverviewKpiSummary(groupingType, divisionName, organizationName, serviceId, workstream1Only),
  )
}
