import { getTheme, memoizeFunction, mergeStyleSets, Stack, Checkbox } from '@fluentui/react'
import { memo, useState, useCallback, useEffect } from 'react'

const theme = getTheme()

export interface StepCustomizationProps {
  checkedCurrentStep?: boolean
  backgroundColor?: string
}

export interface StepBasicProps extends StepCustomizationProps {
  label: string
  checked?: boolean
  index?: number
  iconName?: string
  onStepChange?: (label: string, checked: boolean, index?: number) => void
}

export interface StepProps extends StepBasicProps {
  horizontal?: boolean
  firstStep?: boolean
  selectable?: boolean
}

const getClassNames = memoizeFunction((checked: boolean, horizontal: boolean) => {
  const getDimension = (direction: boolean) => {
    return direction ? 50 : 3
  }

  return mergeStyleSets({
    line: {
      display: 'inline-flex',
      width: getDimension(horizontal),
      height: getDimension(!horizontal),
      borderRadius: 5,
      margin: 6,
      backgroundColor: checked ? theme.palette.themePrimary : theme.palette.neutralQuaternary,
    },
    step: {
      flexDirection: horizontal ? 'column' : undefined,
    },
  })
})

const checkboxStyles = (
  horizontal: boolean,
  checkedCurrentStep: boolean,
  isChecked: boolean,
  backgroundColor?: string,
) => {
  return {
    checkbox: {
      width: 16,
      height: 16,
      marginRight: 0,
      borderRadius: '50%',
      backgroundColor: backgroundColor
        ? backgroundColor
        : checkedCurrentStep
        ? theme.palette.blue
        : isChecked
        ? theme.palette.themePrimary
        : theme.palette.white,
      borderColor: theme.palette.neutralQuaternaryAlt,
    },
    label: { flexDirection: horizontal ? 'column' : undefined, alignItems: 'center', wordBreak: 'normal' },
  }
}

export const Step = memo(
  ({
    label,
    checked = false,
    horizontal = false,
    firstStep,
    selectable = false,
    checkedCurrentStep = false,
    backgroundColor,
    iconName,
    onStepChange,
    index,
  }: StepProps) => {
    const classes = getClassNames(checked || checkedCurrentStep, horizontal)

    const [isChecked, setIsChecked] = useState<boolean>(checked)

    useEffect(() => {
      setIsChecked(checked)
    }, [checked])

    const onChange = useCallback(
      (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checkedFlag?: boolean): void => {
        if (!selectable) return
        if (onStepChange) onStepChange(label, checkedFlag ?? false, index)
      },
      [selectable, onStepChange, label, index],
    )

    return (
      <>
        {!firstStep && (
          <Stack.Item>
            <span className={classes.line} />
          </Stack.Item>
        )}
        <Checkbox
          label={label}
          styles={checkboxStyles(horizontal, checkedCurrentStep, isChecked, backgroundColor)}
          checked={isChecked}
          onChange={onChange}
          checkmarkIconProps={iconName ? { iconName } : undefined}
        />
      </>
    )
  },
)
