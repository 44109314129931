import { FunctionComponent } from 'react'
import { ISearchBoxProps, Label, SearchBox, Stack } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'
import { Filters } from 'src/enums'

export interface SearchBoxWithLabelProps extends ISearchBoxProps {
  label: Filters
}

export const SearchBoxWithLabel: FunctionComponent<SearchBoxWithLabelProps> = (props) => {
  const { label, placeholder } = props
  const id = useId(label)

  return (
    <Stack>
      <Label htmlFor={id}>{label}</Label>
      <SearchBox id={id} {...props} placeholder={placeholder ? placeholder : `Search by ${label} name`} />
    </Stack>
  )
}
