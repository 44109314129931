import React, { useCallback } from 'react'
import { Nav, INavLinkGroup, DefaultEffects, INavLink, getTheme, mergeStyleSets } from '@fluentui/react'
import { useNavigate, useLocation } from 'react-router-dom'
import { SIDE_NAV_WIDTH, TOP_HEADER_HEIGHT, Z_INDEX_1000, SIDE_NAV_WIDTH_EXPANDED } from 'src/constants'
import { landingPages } from 'src/components'
import { checkFeatureGateEnabled, ConditionalFeaturesList } from 'src/features'
import { useConditionalPageFeaturesList, useSearchParamValue } from 'src/hooks'
import { QueryStringParam } from 'src/enums'
import { useFullScreen } from '../../contexts/fullScreen/use'

const theme = getTheme()

const getClassNames = (isFullScreen: boolean) => {
  return mergeStyleSets({
    root: {
      gridArea: 'side-nav',
      display: 'block',
      position: 'fixed',
      zIndex: Z_INDEX_1000,
      top: isFullScreen ? 0 : TOP_HEADER_HEIGHT,
      width: isFullScreen ? 3 : SIDE_NAV_WIDTH - 2,
      height: '100vh',
      boxShadow: DefaultEffects.elevation8,
      backgroundColor: theme.palette.white,
      fontSize: 50,
      fontStyle: 'bold',
      padding: 1,
      paddingTop: 7,
      ':hover': {
        width: SIDE_NAV_WIDTH_EXPANDED,
      },
    },
    link: {
      backgroundColor: theme.palette.white,
      height: 25,
      padding: '0 11px',
      marginBottom: 12,
      borderRadius: 0,
      '::after': {
        borderLeftWidth: 4,
      },
    },
  })
}

const getEnabledPages = (conditionalFeaturesList: ConditionalFeaturesList) => {
  const keys = Array.from(landingPages)
  const pages = keys.filter(([path, _page]) => checkFeatureGateEnabled(conditionalFeaturesList, path))
  return pages.map((p) => p[0])
}

const getNavLinkGroups = (conditionalFeaturesList: ConditionalFeaturesList): INavLinkGroup[] => {
  const navLinkGroups = [
    {
      links: getEnabledPages(conditionalFeaturesList).map((landingPage) => {
        const page = landingPages.getPage(landingPage)
        const navLink: INavLink = {
          name: page?.navigationTitle,
          key: landingPage,
          url: '',
          title: page?.navigationTitle,
          iconProps: {
            iconName: page?.icon,
            styles: {
              root: {
                color: theme.palette.black,
              },
            },
          },
        }
        return navLink
      }),
    },
  ]
  return navLinkGroups
}

export const SideNav: React.FunctionComponent = React.memo(() => {
  const { isFullScreen } = useFullScreen()
  const [featureFlagUrlValue] = useSearchParamValue(QueryStringParam.FeatureFlag)
  const conditionalPageFeaturesList = useConditionalPageFeaturesList()
  const navLinkGroups = getNavLinkGroups(conditionalPageFeaturesList)
  const navigate = useNavigate()
  const location = useLocation()
  const selectedKey = location.pathname.split('/')[1]
  const sideNavClick = useCallback(
    (_ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
      let url = item?.key
      if (featureFlagUrlValue) {
        url += `?${QueryStringParam.FeatureFlag}=${featureFlagUrlValue}`
      }
      navigate(url ?? '/')
    },
    [navigate, featureFlagUrlValue],
  )

  return (
    <Nav
      selectedKey={selectedKey}
      styles={getClassNames(isFullScreen)}
      groups={navLinkGroups}
      onLinkClick={sideNavClick}
    />
  )
})

export default SideNav
