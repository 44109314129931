import { useMutation, useQueryClient } from 'react-query'
import { apiService, FeatureVM } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

export interface ImportFeaturesCommand {
  content: string
}

const importFeatures = async (command: ImportFeaturesCommand) => {
  const res = await apiService.features_ImportFeatures(command.content, '')
  return res
}

export function useImportFeatures() {
  const queryClient = useQueryClient()

  return useMutation((command: ImportFeaturesCommand) => importFeatures(command), {
    onSuccess: (_data, _variables) => {
      optimisticUpdateAndInvalidateQueries<FeatureVM[]>(queryClient, SgQueryKey.features, (_queryKey, data) => {
        return data
      })
      optimisticUpdateAndInvalidateQueries<FeatureVM[]>(
        queryClient,
        SgQueryKey.cloudParityReport,
        (_queryKey, data) => {
          return data
        },
      )
    },
  })
}
