import { useQuery } from 'react-query'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getResourceGroups = async (serviceId?: string) => {
  const response = await apiService.resourceGroups_Get(serviceId)
  return response
}

export function useResourceGroups(serviceId?: string) {
  return useQuery(
    [SgQueryKey.resourceGroups, serviceId],
    () =>
      getResourceGroups(serviceId).then((arr) =>
        arr.sort((a, b) =>
          (a.resourceGroup || '').localeCompare(b.resourceGroup || '', undefined, { sensitivity: 'base' }),
        ),
      ),
    { enabled: !!serviceId },
  )
}
