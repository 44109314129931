import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsListLayoutMode,
  FontSizes,
  IColumn,
  IDetailsColumnStyleProps,
  IDetailsColumnStyles,
  IShimmeredDetailsListProps,
  IStyleFunctionOrObject,
  memoizeFunction,
  mergeStyleSets,
  SelectionMode,
} from '@fluentui/react'

const classes = mergeStyleSets({
  column: {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const columnStyles: IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles> = {
  cellName: {
    width: '100%',
    '@media(max-width: 1439px)': {
      fontSize: FontSizes.size12,
    },
    '@media(max-width: 1365px)': {
      fontSize: FontSizes.size10,
    },
  },
}

export const shimmeredDetailsListDefaultProps: IShimmeredDetailsListProps = {
  setKey: 'items',
  items: [],
  columns: [],
  checkboxVisibility: CheckboxVisibility.hidden,
  layoutMode: DetailsListLayoutMode.fixedColumns,
  isHeaderVisible: true,
  selectionMode: SelectionMode.none,
  constrainMode: ConstrainMode.unconstrained,
  enterModalSelectionOnTouch: true,
  ariaLabelForShimmer: 'Data is being fetched',
}

export const shimmeredDetailsListDefaultColumns: IColumn[] | any = memoizeFunction((columns: IColumn[] | any) => {
  return columns.map((column) => {
    return { ...column, isPadded: false, className: classes.column, styles: columnStyles }
  })
})
