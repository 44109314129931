import { FunctionComponent, Suspense } from 'react'
import { Loader, NotFound } from 'src/components'
import { Route, Routes } from 'react-router-dom'

import { IPage } from './Models'
import { checkFeatureGateEnabled } from 'src/features'
import { landingPages } from './landingPages'
import { memoizeFunction } from '@fluentui/react'
import { useConditionalPageFeaturesList } from 'src/hooks'

export const CustomRouter: FunctionComponent = () => {
  const conditionalPageFeaturesList = useConditionalPageFeaturesList()
  const getRouterComponent = memoizeFunction((path: string, page: IPage) => {
    const { index, element, pages } = page
    return (
      <>
        <Route key={path} index={index} path={path} element={<Suspense fallback={<Loader />}>{element}</Suspense>} />
        {pages &&
          Array.from(pages, ([subPath, subPage]) => {
            const fullPath = `${path}/${subPath}`
            return (
              checkFeatureGateEnabled(conditionalPageFeaturesList, fullPath) && getRouterComponent(fullPath, subPage)
            )
          })}
      </>
    )
  })

  return (
    <Routes>
      {Array.from(landingPages, ([path, page]) => {
        return checkFeatureGateEnabled(conditionalPageFeaturesList, path) && getRouterComponent(path, page)
      })}
      {getRouterComponent('*', NotFound)}
    </Routes>
  )
}
