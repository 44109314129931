import { getTheme, mergeStyleSets } from '@fluentui/react'
import { FOOTER_HEIGHT, TOP_HEADER_HEIGHT } from 'src/constants/StyleConstants'
import { Header } from './Header'
import { Footer } from './Footer'
import { SideNav } from './SideNav'
import { FullScreenToggle } from '../FullScreenToggle'
import { useFullScreen } from '../../contexts/fullScreen'

const theme = getTheme()

const getClassNames = (isFullScreen: boolean) => {
  return mergeStyleSets({
    container: {
      display: 'grid',
      gridTemplateColumns: `${isFullScreen ? '0px' : '48px'} 1fr`,
      gridTemplateRows: `${isFullScreen ? '0px' : '48px'} 1fr`,
      gap: '0px 0px',
      gridTemplateAreas: `
      "app-header app-header"
      "side-nav body"
      `,
    },
    body: {
      display: 'grid',
      gridArea: 'body',
      backgroundColor: theme.palette.neutralLighter,
      overflowX: 'auto',
      whitespace: 'nowrap',
    },
    main: {
      minHeight: `calc(100vh - ${isFullScreen ? 17 : TOP_HEADER_HEIGHT + FOOTER_HEIGHT + 17}px)`,
      padding: '17px 38px 0 38px',
    },
  })
}
export const Layout = ({ children }) => {
  const { isFullScreen } = useFullScreen()
  const classes = getClassNames(isFullScreen)

  if (isFullScreen) {
    return (
      <div className={classes.container}>
        <SideNav />
        <div className={classes.body}>
          <main className={classes.main}>{children}</main>
          <FullScreenToggle />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <Header />
      <SideNav />
      <div className={classes.body}>
        <main className={classes.main}>{children}</main>
        <FullScreenToggle />
        <Footer />
      </div>
    </div>
  )
}
