import { useQuery } from 'react-query'
import { SgQueryKey } from './Shared'

export interface DataFlowGroupVM {
  name?: string | undefined
  order?: number
}

const getDataFlowGroups = () => {
  // TODO: become stub until dataflowGroup is completely removed
  const response: DataFlowGroupVM[] = []
  return response
}

export function useDataFlowGroups() {
  return useQuery(SgQueryKey.dataFlowGroups, getDataFlowGroups)
}
