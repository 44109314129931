import { AudienceEnum } from 'src/enums/AudienceEnum'
import { useAudience } from './useAudience'
import { useSearchParamValue } from './useSearchParamValue'
import { QueryStringParam } from 'src/enums'

const audienceMapping = new Map<AudienceEnum, number>([
  [AudienceEnum.LAPTOP, 0],
  [AudienceEnum.DEV, 1],
  [AudienceEnum.STAGING, 2],
  [AudienceEnum.PRODUCTION, 3],
])

export const useFeatureForAudience = (enableUpTo: AudienceEnum): boolean => {
  const audienceToUse = useAudience()
  const [globalOptIn] = useSearchParamValue(QueryStringParam.FeatureFlag)
  const checkedValue = globalOptIn ? JSON.parse(globalOptIn) : false
  if (checkedValue) {
    return true
  }
  const enableUpToValue = audienceMapping.get(enableUpTo)
  const audienceValue = audienceMapping.get(audienceToUse)
  return enableUpToValue !== undefined && audienceValue !== undefined && audienceValue <= enableUpToValue
}
