import { FunctionComponent } from 'react'
import { Stack } from '@fluentui/react'

export const RemediationSteps: FunctionComponent = () => {
  return (
    <Stack>
      <Stack.Item>Please follow the instructions to remediate this alert.</Stack.Item>
      <Stack.Item>
        If you consider the alert is <b>correct</b> and issue exists:
      </Stack.Item>
      <Stack.Item>
        <ol type="1">
          <li>
            Set a new status <b>Investigating</b>, meaning that remediation is started.
          </li>
          <li>
            Fix a root cause of the alert; potential fixes:
            <ol type="A">
              <li>
                Stop sending customer data to destination highlighted in <b>Violated Data Flows</b> list.
              </li>
              <li>
                Review and update privacy tags for data flow source or destination in case it's not properly classified.
              </li>
            </ol>
          </li>
          <li>
            Set status <b>Resolved</b> after a root cause is fixed.
          </li>
          <li>It can take several days for Service Graph to get new information and close the alert automatically.</li>
        </ol>
      </Stack.Item>
      <Stack.Item>
        In case you already know the alert <b>is not valid</b> or have some concerns about its correctness, please do
        the following:
      </Stack.Item>
      <Stack.Item>
        <ol type="1">
          <li>
            Set status <b>Dispute</b> with justification in the comment box explaining why this alert is not correct.
          </li>
          <li>
            Optionally, reach out Service Graph EUDB team for clarifications:&nbsp;
            <a href="mailto:sg-eudb@microsoft.com">sg-eudb@microsoft.com</a>.
          </li>
        </ol>
      </Stack.Item>
    </Stack>
  )
}
