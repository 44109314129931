import _ from 'lodash'
import { useMutation, useQueryClient } from 'react-query'
import {
  apiService,
  ResourceVM,
  ResourceTransferBatch,
  UpdateResourceTransferBatchCommand,
} from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const updateResourcesBatch = async (command: UpdateResourceTransferBatchCommand) => {
  await apiService.resources_BatchTransferUpdate(command)
}
export function useUpdateResourceTransferBatch() {
  const queryClient = useQueryClient()

  return useMutation((command: UpdateResourceTransferBatchCommand) => updateResourcesBatch(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<ResourceVM[]>(queryClient, SgQueryKey.resources, (queryKey, data) => {
        if (queryKey[1] === variables.serviceId) {
          data = data.map((resource) => {
            const rCommand = variables.resources?.find((r) => r.id === resource.id)
            if (resource.id !== rCommand?.id) return resource
            return { ...resource, ...rCommand }
          })
          return data
        }
        return undefined
      })
      queryClient.invalidateQueries([SgQueryKey.dataFlows])
      queryClient.invalidateQueries([SgQueryKey.tmNodes])
      queryClient.invalidateQueries([SgQueryKey.resource])
    },
  })
}

export const getResourceTransferBatch = (resource: ResourceVM): ResourceTransferBatch => {
  return {
    id: resource.id,
    m365Cloud: resource.m365Cloud,
    resourceId: resource.resourceId,
    targetServiceId: _.isEmpty(resource.targetServiceId) ? undefined : resource.targetServiceId,
    environment: resource.environment,
    dataClassifications: resource.dataClassification || [],
    dataClassificationComment: resource.dataClassificationComment,
    updateState: undefined,
    newNodes: resource.nodes?.map((node) => node.nodeId || '') || [],
  }
}
