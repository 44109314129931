import { apiService, FeatureAttributesVM } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery, useQueryClient } from 'react-query'

const getFeatureAttributes = async (featureId?: string, attributeNames?: string[]) => {
  const response = await apiService.features_GetFeatureAttributes(featureId || '', attributeNames)
  return response
}

export function useFeatureAttributes(featureId?: string, attributeNames?: string[]) {
  const queryClient = useQueryClient()
  return useQuery(
    [SgQueryKey.featureAttributes, featureId, attributeNames],
    () => getFeatureAttributes(featureId, attributeNames),
    {
      enabled: !!featureId,
      initialData: () => {
        const featureAttributes = queryClient.getQueryData<FeatureAttributesVM>(SgQueryKey.featureAttributes)
        return featureAttributes
      },
    },
  )
}
