import { Link, LinkProps } from 'react-router-dom'
import { FunctionComponent } from 'react'

export const CompliantRouterLink: FunctionComponent<LinkProps> = ({ children, ...props }) => {
  return (
    <Link rel="noreferrer noopener" {...props}>
      {children}
    </Link>
  )
}
