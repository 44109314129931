import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getUserProfile = async () => {
  const response = await apiService.userProfile_Get()
  return response
}

export function useUserProfile() {
  return useQuery(SgQueryKey.userProfile, getUserProfile, {
    staleTime: 10 * MINUTE,
    refetchInterval: 10 * MINUTE,
    onError: undefined,
  })
}
