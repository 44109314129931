import { IComboBoxProps, VirtualizedComboBox } from '@fluentui/react'
import { FunctionComponent } from 'react'

export const SGVirtualizedComboBox: FunctionComponent<IComboBoxProps> = (props) => {
  return (
    <VirtualizedComboBox
      allowFreeform={props.allowFreeform}
      autoComplete={props.autoComplete}
      calloutProps={props.calloutProps}
      componentRef={props.componentRef}
      disabled={props.disabled}
      errorMessage={props.errorMessage}
      id={props.id}
      multiSelect={props.multiSelect}
      onChange={props.onChange}
      onClick={props.onClick}
      onInputValueChange={props.onInputValueChange}
      onRenderLabel={props.onRenderLabel}
      openOnKeyboardFocus={props.openOnKeyboardFocus}
      onMenuDismissed={props.onMenuDismissed}
      options={props.options}
      placeholder={props.placeholder}
      scrollSelectedToTop={props.scrollSelectedToTop}
      selectedKey={props.selectedKey}
      shouldRestoreFocus={props.shouldRestoreFocus}
      styles={props.styles}
      text={props.text}
      data-automation-key={props['data-automation-key']}
    />
  )
}
