export interface ConditionalFeaturesList {
  [key: string]: boolean
}

export const checkFeatureGateEnabled = (conditionalFeaturesList: ConditionalFeaturesList, featureName: string) => {
  if (Object.prototype.hasOwnProperty.call(conditionalFeaturesList, featureName)) {
    return conditionalFeaturesList[featureName]
  }
  return true
}
