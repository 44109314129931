import { useMutation, useQueryClient } from 'react-query'
import { UpsertDataFlowCommand, apiService, ServiceDataFlowVM } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const mapUpsertCommandToModel = (command: UpsertDataFlowCommand): ServiceDataFlowVM => {
  return {
    ...command,
    name: command.dataflowName,
    order: command.dataflowOrder,
    groupOrder: command.dataflowGroupOrder,
    m365Cloud: command.cloud,
  }
}

const saveServiceDataFlow = async (command: UpsertDataFlowCommand) => {
  await apiService.serviceDataFlow_Upsert(command)
}

export function useSaveServiceDataFlow() {
  const queryClient = useQueryClient()

  return useMutation((command: UpsertDataFlowCommand) => saveServiceDataFlow(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<ServiceDataFlowVM[]>(
        queryClient,
        SgQueryKey.serviceDataFlows,
        (queryKey, data) => {
          if (queryKey[1] === variables.sourceServiceId) {
            if (data.find((d) => d.dataFlowGUID === variables?.id)) {
              data = data.map((dataflow) => {
                if (dataflow.dataFlowGUID !== variables?.id) return dataflow
                return { ...dataflow, ...mapUpsertCommandToModel(variables) }
              })
            } else {
              data.push(mapUpsertCommandToModel(variables))
            }
            return data
          }
          return undefined
        },
      )
    },
  })
}
