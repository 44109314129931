import { useQuery } from 'react-query'
import { apiService, TMErrorVM, SwaggerException } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getTMErrors = async (serviceId?: string) => {
  const response = await apiService.threatModels_GetErrors(serviceId)
  return response
}

export function useTMErrors(serviceId?: string) {
  return useQuery<TMErrorVM[], SwaggerException>([SgQueryKey.tmErrors, serviceId], () => getTMErrors(serviceId), {
    enabled: !!serviceId,
  })
}
