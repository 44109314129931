import { SubTabs } from './enum'
import { Step } from './interface'
import { tourTargetClasses, tourTargetIds } from './tourTargets'
import { DirectionalHint } from '@fluentui/react'

const THREAT_MODEL_UPLOAD: Step[] = [
  {
    target: `#${tourTargetIds.autoCompleteSelector}`,
    title: 'Service selection',
    content: 'Please verify that the selected service is correct.',
  },
  {
    target: `#${tourTargetIds.tmUploadIcon}`,
    title: 'Threat model upload',
    content: 'After you have verified your selected service, please upload your Threat Model',
  },
  {
    target: `.${tourTargetClasses.shimmeredDetailsList}`,
    title: 'Threat model errors',
    content:
      'List of errors may appear after Threat Model upload. Please fix all errors according to the instructions. \n\
      Data Classification can be fixed directly in Service Graph portal. \n\
      For other Errors, please revise and reupload your Threat Model.',
  },
  {
    target: `#${tourTargetIds.tmUploadPivot1}`,
    title: 'Next step',
    content: 'Next step is to continue working with your data flows in the Flows section :-)',
  },
]

const FLOWS: Step[] = [
  {
    target: `.${tourTargetClasses.shimmeredDetailsList}`,
    title: 'Flows',
    content:
      'Please provide all mandatory information for individual \n\
    flows from your Threat Model by using the Threat model "Edit Flow" button and \n\
     reconcile them with Signals (automatically detected flows from other sources)',
  },
  {
    target: `#${tourTargetIds.tmAttestButton}`,
    title: 'Flows',
    content: 'Once you are done with updates, please attest your data flows',
  },
  {
    title: 'Apply filtering',
    target: `.${tourTargetClasses.additionalFilters}`,
    directionalHint: DirectionalHint.rightCenter,
    content: 'If you want to select specific subset of flows, please use "Additional filters" button.',
  },
  {
    target: `#${tourTargetIds.tmUploadPivot2}`,
    title: 'Next step',
    content: 'Next step is to continue working with your nodes in the Nodes section :-)',
  },
]

const NODES: Step[] = [
  {
    target: `.${tourTargetClasses.shimmeredDetailsList}`,
    title: 'Nodes',
    content:
      'Nodes visible on this screen are representative of your Threat Model nodes. \n\
    Please provide mandatory information and link Resources \n\
    (e.g. VMs, Databases from your subscription) used by your Service',
  },
]

const EUDB_ALERTS: Step[] = [
  {
    target: `#${tourTargetIds.documentTitle}`,
    title: 'EUDB Alerts',
    content: 'Welcome to the new and improved alerts page',
  },
  {
    target: `#${tourTargetIds.autoCompleteSelector}`,
    title: 'Service selection',
    content: 'Please verify that the selected service is correct.',
  },
  {
    title: 'What input is expected on Boundary alerts screen?',
    target: `.${tourTargetClasses.shimmeredDetailsList}`,
    content: 'Please go through all the alerts and their details which show exactly why the alert was generated.',
  },
]

const RESOURCES: Step[] = [
  {
    target: `#${tourTargetIds.autoCompleteSelector}`,
    title: 'Service selection',
    content: 'Please verify that the selected service is correct.',
  },
  {
    title: 'What input is expected on Resource Management screen?',
    target: `.${tourTargetClasses.shimmeredDetailsList}`,
    directionalHint: DirectionalHint.rightCenter,
    content:
      'Please verify that all resources displayed here are valid and used for your Service. \n\
       In case some of the resources are being used by a different service, \n\
     please change the resource ownership accordingly.',
  },
  {
    title: 'Apply filtering',
    target: `.${tourTargetClasses.additionalFilters}`,
    directionalHint: DirectionalHint.rightCenter,
    content: 'If you want to select specific subset of resources, please use "Additional filters" button.',
  },
]

export const stepsConfig = {
  [SubTabs.THREAT_MODEL_UPLOAD]: THREAT_MODEL_UPLOAD,
  [SubTabs.FLOWS]: FLOWS,
  [SubTabs.NODES]: NODES,
  [SubTabs.EUDB_ALERTS]: EUDB_ALERTS,
  [SubTabs.RESOURCES]: RESOURCES,
}
