import { useMutation, useQueryClient } from 'react-query'
import { apiService, FeatureServiceVM, UpdateFeatureServicesCommand } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const saveFeatureServices = async (command: UpdateFeatureServicesCommand) => {
  if (command.featureId) {
    await apiService.features_UpdateFeatureServices(command.featureId, command)
  }
}

export function useSaveFeatureServices() {
  const queryClient = useQueryClient()

  return useMutation((command: UpdateFeatureServicesCommand) => saveFeatureServices(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<FeatureServiceVM[]>(
        queryClient,
        SgQueryKey.featureServices,
        (queryKey, data) => {
          if (queryKey[1] === variables.featureId) {
            return data
          }
          return undefined
        },
      )
      queryClient.invalidateQueries([SgQueryKey.features])
    },
  })
}
