export enum Filters {
  ECDeployments = 'E+C Deployment Scope',
  Cloud = 'Cloud',
  CloudRegion = 'Cloud/Region',
  CommerceServicePlan = 'Commerce Service Plan',
  CustomerRing = 'CustomerRing',
  Division = 'Division',
  DataFlow = 'DataFlow',
  EndDate = 'End Date',
  Feature = 'Feature',
  FeatureNameOrDescription = 'Feature Name or Description',
  FeatureParity = 'Feature Parity',
  FeatureType = 'Feature Type',
  Intent = 'Intent',
  InventoryEnvironment = 'M365 Cloud Environment',
  InventoryTeam = 'Inventory Workload',
  MyFeatures = 'My Features',
  Organization = 'Organization',
  Platform = 'Platform',
  PMOwners = 'PM Owners',
  PMMOwners = 'PMM Owners',
  Product = 'Product',
  Service = 'Service',
  ServiceComponent = 'Service Component',
  ServiceTreeProduct = 'Service Tree Product',
  SKU = 'SKU',
  SKUType = 'SKU Type',
  StartDate = 'Start Date',
  Status = 'Status',
  ApplicationName = 'Application Name',
  RunId = 'Run Id',
  JobEnvironment = 'Job Environment',
  State = 'State',
  ServiceTier = 'Service Tier',
}
