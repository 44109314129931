import { DatePicker, IDatePickerProps, IStyle, IconButton, Stack, getTheme } from '@fluentui/react'
import { useCallback } from 'react'
import { Filters, QueryStringParam } from 'src/enums'
import { useSearchParamValue } from 'src/hooks'
import { getNumericDate } from 'src/utils'
import { useDateRange } from './hooks'

const theme = getTheme()

const getDefaultDatePickerProps = (index: number, clearDate: () => void): IDatePickerProps => {
  const fieldGroup: IStyle =
    index === 0
      ? { borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }
      : { borderLeft: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
  return {
    ariaLabel: 'Select a date.',
    highlightSelectedMonth: true,
    showCloseButton: true,
    textField: {
      onRenderSuffix: () => (
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          styles={{
            icon: { fontSize: 14 },
            root: { width: 30, height: 30 },
            rootHovered: { backgroundColor: theme.semanticColors.bodyBackgroundHovered },
            rootPressed: { backgroundColor: theme.semanticColors.bodyBackgroundChecked },
          }}
          title="Clear"
          ariaLabel="Clear"
          onClick={clearDate}
        />
      ),
      style: { paddingLeft: 30, paddingRight: 0, width: 136 },
      styles: {
        suffix: { padding: 0, backgroundColor: theme.semanticColors.inputBackground },
        fieldGroup,
      },
    },
    styles: {
      root: { height: 62 },
      icon: { left: 2, right: 'unset' },
    },
  }
}

export const UrlDateRangePicker = () => {
  const [, setStartDateValue] = useSearchParamValue<string>(QueryStringParam.StartDate)
  const [, setEndDateValue] = useSearchParamValue<string>(QueryStringParam.EndDate)
  const { startDate, endDate } = useDateRange()

  const setStartDate = useCallback(
    (date: Date | null | undefined) => {
      const numericDate = getNumericDate(date as Date | undefined)
      setStartDateValue(numericDate)
    },
    [setStartDateValue],
  )

  const setEndDate = useCallback(
    (date: Date | null | undefined) => {
      const numericDate = getNumericDate(date as Date | undefined)
      setEndDateValue(numericDate)
    },
    [setEndDateValue],
  )

  const clearStartDate = useCallback(() => {
    setStartDate(undefined)
  }, [setStartDate])

  const clearEndDate = useCallback(() => {
    setEndDate(undefined)
  }, [setEndDate])

  return (
    <Stack horizontal>
      <DatePicker
        {...getDefaultDatePickerProps(0, clearStartDate)}
        label={Filters.StartDate}
        value={startDate}
        onSelectDate={setStartDate}
        maxDate={endDate}
      />
      <DatePicker
        {...getDefaultDatePickerProps(1, clearEndDate)}
        label={Filters.EndDate}
        value={endDate}
        onSelectDate={setEndDate}
        minDate={startDate}
      />
    </Stack>
  )
}
