import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getServiceAffiliations = async (serviceId?: string) => {
  const response = await apiService.services_GetAffiliations(serviceId)
  return response
}

export function useServiceAffiliations(serviceId?: string, enabled?: boolean) {
  return useQuery([SgQueryKey.serviceAffiliations, serviceId], () => getServiceAffiliations(serviceId), {
    enabled: enabled && !!serviceId,
    staleTime: 5 * MINUTE,
  })
}
