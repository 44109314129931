import { FunctionComponent } from 'react'
import { TooltipHost, ITooltipProps, Text, Stack, ITooltipHostStyles } from '@fluentui/react'
import { ClockIcon } from 'src/components/Icons'

export interface RefreshTimeItemProps {
  label: string
  value: string
}

export interface RefreshTimeProps {
  data: RefreshTimeItemProps[]
}

const tokens = { childrenGap: 5 }
const textStyle = { lineHeight: '27px' }

const styles: Partial<ITooltipHostStyles> = { root: { paddingLeft: 10 } }

export const RefreshTime: FunctionComponent<RefreshTimeProps> = ({ data }) => {
  const items: JSX.Element[] = []
  data.forEach((item) => {
    items.push(
      <tr>
        <td>{`${item.label.toLocaleUpperCase()}:`}</td>
        <td>{item.value}</td>
      </tr>,
    )
  })

  const tooltipProps: ITooltipProps = {
    onRenderContent: () => <table>{items}</table>,
  }

  return (
    <>
      <TooltipHost tooltipProps={tooltipProps} styles={styles}>
        <Stack horizontal tokens={tokens}>
          <Text variant="medium" nowrap block style={textStyle}>
            Last Update Time
          </Text>
          <div style={textStyle}>
            <ClockIcon />
          </div>
        </Stack>
      </TooltipHost>
    </>
  )
}
