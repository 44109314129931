import { IStackStyles, MessageBar, MessageBarType, Stack } from '@fluentui/react'
import { FunctionComponent } from 'react'

const stackStyles: IStackStyles = {
  root: {
    marginTop: 15,
    maxHeight: '35vh',
    minHeight: '10vh',
  },
}

export interface MessageBarProps {
  message: string
  messageType: MessageBarType
}

export const MessageBarInfo: FunctionComponent<MessageBarProps> = ({ messageType, message }) => {
  return (
    <Stack styles={stackStyles}>
      <MessageBar messageBarType={messageType} isMultiline={false} dismissButtonAriaLabel="Close">
        {message}
      </MessageBar>
    </Stack>
  )
}
