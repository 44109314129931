import { ILandingPage } from '../Models'
import { lazy } from 'react'

const SGIOverviewPage = lazy(() => import('../../../pages/SGI/Overview'))

export const SGIOverview = {
  navigationTitle: 'Service Graph Insights',
  icon: 'CRMCustomerInsightsApp',
  description: [
    `Overview of all work happening in Service Graph!
    If you want more information about a particular App, please click on it.
    It will bring you to a more detailed breakdown of the latest run of that App`,
  ],
  element: <SGIOverviewPage />,
} as ILandingPage
