import { FallbackProps } from 'react-error-boundary'
import { IButtonStyles, IIconProps, PrimaryButton, Stack } from '@fluentui/react'
import { FunctionComponent } from 'react'
import { WarningIcon } from 'src/components/Icons'
import { useStatusCodes } from 'src/hooks'
import { ErrorFallbackHeader, ErrorFallbackHeaderProps } from './ErrorFallbackHeader'
import { ErrorFallbackMessage, ErrorFallbackMessageProps } from './ErrorFallbackMessage/ErrorFallbackMessage'

export interface ErrorFallbackProps extends FallbackProps, ErrorFallbackHeaderProps, ErrorFallbackMessageProps {
  isReloadPage?: boolean
  showResetBtn?: boolean
}

const refreshIcon: IIconProps = { iconName: 'Refresh' }

const buttonStyles: IButtonStyles = {
  root: {
    width: 'fit-content',
    marginTop: '20px !important',
  },
}

export const ErrorFallback: FunctionComponent<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
  isReloadPage,
  showResetBtn = true,
  showErrorHeader,
  header,
  extendedContent,
}) => {
  const { isClientError } = useStatusCodes()
  showResetBtn = showResetBtn && !isClientError(error)

  return (
    <Stack verticalFill verticalAlign="center" horizontalAlign="center">
      <WarningIcon />
      <ErrorFallbackHeader showErrorHeader={showErrorHeader} header={header} error={error} />
      <ErrorFallbackMessage error={error} extendedContent={extendedContent} />
      {showResetBtn && (
        <PrimaryButton
          onClick={resetErrorBoundary}
          iconProps={refreshIcon}
          styles={buttonStyles}
          text={isReloadPage ? 'Reload page' : 'Try again'}
        />
      )}
    </Stack>
  )
}
