import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery } from 'react-query'

export const getFeatureComments = async (featureId?: string) => {
  if (featureId) {
    const response = await apiService.features_GetFeatureComments(featureId)
    return response
  } else return []
}

export function useFeatureComments(featureId?: string) {
  return useQuery([SgQueryKey.featureComments, featureId], () => getFeatureComments(featureId), {
    enabled: !!featureId,
  })
}
