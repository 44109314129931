import { Stack } from '@fluentui/react'
import { Step, StepProps } from './Step'

export interface StepperProps {
  steps: StepProps[]
  horizontal?: boolean
  selectable?: boolean
  onStepChange?: (label: string, checked: boolean, index?: number) => void
}

export const Stepper = ({ steps, horizontal, selectable = false, onStepChange }: StepperProps) => {
  return (
    <>
      {steps.length > 0 && (
        <Stack horizontal={horizontal}>
          {steps.map((step: StepProps, index: number) => (
            <Step
              key={step.label}
              {...step}
              horizontal={horizontal}
              onStepChange={onStepChange}
              index={index}
              firstStep={index === 0}
              selectable={selectable}
            />
          ))}
        </Stack>
      )}
    </>
  )
}
