import React, { FormEvent } from 'react'
import { IComboBox } from '@fluentui/react'
import { ProductOptionsProps, useProductOptions } from './useProductOptions'
import { AutoCompleteMultiselect } from '../../AutoCompleteMultiselect'
import { CustomMultiAutoCompleteProps } from '../Props'

interface ProductAutoCompleteProps extends CustomMultiAutoCompleteProps, ProductOptionsProps {
  onKeysChange?: (selectedOptions: string[]) => void
}

export const ProductAutoCompleteMultiselect: React.FunctionComponent<ProductAutoCompleteProps> = (props) => {
  const { onKeysChange, isOwnedProducts } = props
  const options = useProductOptions({ isOwnedProducts })

  const onChange = (_event: FormEvent<IComboBox>, selectedOptions?: string[]) => {
    if (onKeysChange) {
      onKeysChange(selectedOptions ?? [])
    }
  }

  return <AutoCompleteMultiselect {...options} {...props} onChangeMultipleValues={onChange} />
}
