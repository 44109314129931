import { UseOptionsProps } from './useOptions'
import { GraphApiListSelect } from './GraphApiListSelect'
import { useSearchParamValue } from 'src/hooks'
import { FilterSize } from '../Filters'
import { useCallback } from 'react'
import { Filters, QueryStringParam } from 'src/enums'

export interface GraphApiListUrlSelectProps<TEnum extends number> extends UseOptionsProps<TEnum> {
  urlKey: QueryStringParam
  initialKey?: TEnum
  defaultKey?: TEnum
  filterSize?: FilterSize
  sortByName?: boolean
  disabled?: boolean
  label?: Filters
}

export const GraphApiListUrlSelect = <TEnum extends number>({
  enumDescriptorService,
  urlKey,
  initialKey,
  defaultKey,
  filterSize = FilterSize.M,
  sortByName = true,
  disabled,
  label,
}: GraphApiListUrlSelectProps<TEnum>) => {
  const [urlValue, setUrlValue] = useSearchParamValue<TEnum>(urlKey)

  const setValue = useCallback(
    (value: TEnum) => {
      setUrlValue(value === defaultKey ? undefined : value)
    },
    [setUrlValue, defaultKey],
  )

  return (
    <GraphApiListSelect
      name={label}
      selectedKey={urlValue ?? initialKey}
      enumDescriptorService={enumDescriptorService}
      setValue={setValue}
      filterSize={filterSize}
      sortByName={sortByName}
      label={label}
      disabled={disabled}
    />
  )
}
