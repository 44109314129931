import { useMutation, useQueryClient } from 'react-query'
import { SgQueryKey } from './Shared'
import { apiService, UncollapseNodeCommand } from 'src/services/graphapi'

const uncollapseNode = async (uncollapseNodeCommand: UncollapseNodeCommand) => {
  await apiService.nodes_UncollapseNode(uncollapseNodeCommand)
}

export function useTMUncollapseNode() {
  const queryClient = useQueryClient()

  return useMutation((uncollapseNodeCommand: UncollapseNodeCommand) => uncollapseNode(uncollapseNodeCommand), {
    onSuccess: (_data) => {
      queryClient.invalidateQueries([SgQueryKey.tmNodes])
      queryClient.invalidateQueries([SgQueryKey.dataFlows])
    },
  })
}
