import { ILandingPage } from '../Models'
import { lazy } from 'react'

const CardinalityViewPage = lazy(() => import('../../../pages/Cardinality/CardinalityView'))

export const CardinalityView = {
  navigationTitle: 'Service Graph Cardinality View',
  icon: 'StackedLineChart',
  description: [`Cardinality Analysis of data extracted from external sources`],
  element: <CardinalityViewPage />,
} as ILandingPage
