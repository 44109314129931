import { FilterSize } from 'src/components/Filters'
import { SERVICES_AUTOCOMPLETE_PLACEHOLDER } from 'src/constants'
import { AutoCompleteMultiselect } from '../../AutoCompleteMultiselect'
import { useUrlAutoCompleteMultiSelect } from '../../hooks'
import { CustomFilterSizeProps } from '../Props'
import { useServicesByProductOptions } from './useServicesByProductOptions'
import { QueryStringParam } from 'src/enums'

export const ServiceUrlAutoCompleteMultiselect = ({ filterSize = FilterSize.M }: CustomFilterSizeProps) => {
  const options = useServicesByProductOptions()
  const { urlValue, onChangeMultipleValues } = useUrlAutoCompleteMultiSelect({ urlKey: QueryStringParam.ServiceId })
  const selectedKeys = urlValue?.split('_') ?? []
  return (
    <AutoCompleteMultiselect
      {...options}
      selectedKey={selectedKeys}
      onChangeMultipleValues={onChangeMultipleValues}
      filterSize={filterSize}
      selectedOptionLimit={5}
      placeholder={SERVICES_AUTOCOMPLETE_PLACEHOLDER}
    />
  )
}
