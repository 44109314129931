import { FunctionComponent } from 'react'
import { getTheme, ILinkStyles } from '@fluentui/react'
import { useHref } from 'react-router-dom'
import { SGTableLongCellTooltip } from './SGTableLongCellTooltip'
import { SGTableLongCellText } from './SGTableLongCellText'
import { SGTableLongTextCellProps } from './SGTableLongTextCell'
import { CompliantExternalLink } from 'src/components'

export interface SGTableLongLinkCellProps extends SGTableLongTextCellProps {
  searchParams: URLSearchParams
}

const theme = getTheme()
const { semanticColors } = theme

const linkStyles: Partial<ILinkStyles> = {
  root: [
    {
      color: semanticColors.link,
      selectors: {
        '&:active, &:hover, &:active:hover': {
          color: semanticColors.linkHovered,
        },
        '&:active': {
          color: semanticColors.linkHovered,
        },
      },
      overflow: 'hidden',
      width: '100%',
    },
  ],
}

export const SGTableLongLinkCell: FunctionComponent<SGTableLongLinkCellProps> = ({ searchParams, text }) => {
  const href = useHref(`?${searchParams?.toString()}`)

  return (
    <SGTableLongCellTooltip content={text}>
      <CompliantExternalLink href={href} underline styles={linkStyles}>
        <SGTableLongCellText text={text} />
      </CompliantExternalLink>
    </SGTableLongCellTooltip>
  )
}
