import { Text } from '@fluentui/react'
import { FunctionComponent } from 'react'

export interface FormTitleProps {
  value?: string
}

export const FormTitle: FunctionComponent<FormTitleProps> = ({ value }) => {
  return <>{value && <Text variant="xLarge">{value}</Text>}</>
}
