import { lazy } from 'react'
import { ILandingPage } from '../Models'
import { RESOURCES } from 'src/constants/StringConstants'

const ResourcesPage = lazy(() => import('../../../pages/Resources/Resources'))

export const Resources = {
  navigationTitle: RESOURCES,
  icon: 'Tablet',
  element: <ResourcesPage />,
} as ILandingPage
