import { IComboBoxOption } from '@fluentui/react'
import { useEcsConfig, useServiceComponents } from 'src/queries'
import { FeatureFlag, ServiceComponentVM } from 'src/services/graphapi'
import { useOptions } from '../../hooks'
import { Filters } from 'src/enums'

export const useServiceComponentOptions = (skuIds?: string, productIds?: string, servicePlanIds?: string) => {
  const { data: configFlags } = useEcsConfig()
  const skuMappingV2Enabled = configFlags?.skuMappingV2Flag === FeatureFlag.Enabled
  const queryResult = useServiceComponents(
    skuIds,
    productIds,
    skuMappingV2Enabled ? '' : servicePlanIds,
    skuMappingV2Enabled,
  )
  const map = (item: ServiceComponentVM) => ({ key: item.id || '', text: item.name || '' } as IComboBoxOption)
  const transform = (items: ServiceComponentVM[]) => items.map((item) => map(item))
  const options = useOptions<ServiceComponentVM>({
    queryResult,
    transform,
    name: Filters.ServiceComponent,
    showTitle: true,
  })

  return options
}
