import React, { FormEvent } from 'react'
import { IComboBox, IComboBoxOption } from '@fluentui/react'
import { ProductOptionsProps, useProductOptions } from './useProductOptions'
import { CustomAutoCompleteProps } from '../Props'
import { AutoComplete } from '../../AutoComplete'

interface ProductAutoCompleteProps extends CustomAutoCompleteProps, ProductOptionsProps {}

export const ProductAutoComplete: React.FunctionComponent<ProductAutoCompleteProps> = (props) => {
  const { onKeyChange, isOwnedProducts } = props
  const options = useProductOptions({ isOwnedProducts })

  const onChange = (_event: FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (onKeyChange) {
      onKeyChange(!option?.key ? '' : String(option?.key))
    }
  }

  return <AutoComplete {...options} {...props} onChange={onChange} />
}
