import { IPage } from './Models'
export {}

declare global {
  interface Map<K, V> {
    getPage(path: K | string): V
  }
}

const getPageRecursive = (pages: Map<string, IPage>, parts: string[]): IPage | undefined => {
  const part = parts[0]
  const page = pages.get(part)
  parts.shift()
  if (page === undefined) return undefined
  if (parts.length === 0) return page
  if (page.pages === undefined) return undefined
  return getPageRecursive(page.pages, parts)
}

Map.prototype.getPage = function (path: string): IPage {
  const parts = path.split('/')
  if (parts.length > 1 && parts[0] === '') parts.shift()
  return getPageRecursive(this, parts) ?? ({} as IPage)
}
