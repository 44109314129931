import { IComboBoxOption } from '@fluentui/react'
import { RTITeamVM } from 'src/services/graphapi'
import { useOptions } from '../../../hooks'
import { useRTIVulnerabilityTeams } from 'src/queries/'
import { Filters } from 'src/enums'

export const useRTIVulnerabilityTeamsOptions = () => {
  const queryResult = useRTIVulnerabilityTeams()

  const map = ({ name }: RTITeamVM) => ({ name, key: name || '', text: name || '' } as IComboBoxOption)
  const transform = (items: RTITeamVM[]) => items.map((item) => map(item))

  const options = useOptions<RTITeamVM>({ queryResult, transform, name: Filters.InventoryTeam, showTitle: true })

  return options
}
