import { apiService, FeatureProductVM } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery, useQueryClient } from 'react-query'

const getFeatureProducts = async (featureId?: string) => {
  const response = await apiService.features_GetFeatureProducts(featureId || '')
  return response
}

export function useFeatureProducts(featureId?: string) {
  const queryClient = useQueryClient()
  return useQuery([SgQueryKey.featureProduct, featureId], () => getFeatureProducts(featureId), {
    enabled: !!featureId,
    initialData: () => {
      const featureProducts = queryClient.getQueryData<FeatureProductVM[]>(SgQueryKey.featureProduct)
      return featureProducts
    },
  })
}
