import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getServicesFeatureManagement = async (productIds?: string) => {
  const response = await apiService.services_GetByProducts(productIds)
  return response
}

export function useServicesFeatureManagement(productIds?: string, enabled = true) {
  return useQuery([SgQueryKey.servicesFeatureManagement, productIds], () => getServicesFeatureManagement(productIds), {
    staleTime: 5 * MINUTE,
    enabled,
  })
}
