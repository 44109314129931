import { ITag } from '@fluentui/react'
import { FunctionComponent } from 'react'
import { m365CloudDescriptorService } from 'src/services/graphapi/lists'
import { Picker } from '../Picker'
import { useCloudParityClouds } from 'src/queries'
import { Filters } from 'src/enums'

export interface IntentsPickerProps {
  id?: string
  onChange?: (items?: ITag[]) => void
  selectedFeatureIntents: ITag[] | undefined
  disabled?: boolean
}

export const IntentsPicker: FunctionComponent<IntentsPickerProps> = ({
  onChange,
  selectedFeatureIntents,
  disabled,
}) => {
  const items = m365CloudDescriptorService
    .getDescriptors(useCloudParityClouds())
    .map((item) => ({ key: item?.enum, name: item?.name } as ITag))

  return (
    <Picker
      name={Filters.Intent}
      itemsList={items}
      onChange={onChange}
      selectedItems={selectedFeatureIntents}
      disabled={disabled}
    />
  )
}
