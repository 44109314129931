import { IComboBox } from '@fluentui/react'
import { useCallback, useRef } from 'react'

export interface FocusOnClickResponse {
  componentRef: React.RefObject<IComboBox>
  onClick: React.MouseEventHandler<IComboBox>
}

export const useFocusOnClick = (): FocusOnClickResponse => {
  const componentRef = useRef<IComboBox>(null)

  const onClick = useCallback((e) => {
    if (e.target.role == 'combobox') {
      componentRef.current?.focus(true)
    }
  }, [])

  return {
    componentRef,
    onClick,
  }
}
