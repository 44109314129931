import { useMutation, useQueryClient } from 'react-query'
import { UpdateFeatureCommentCommand, apiService } from 'src/services/graphapi'
import { SgQueryKey, optimisticUpdateAndInvalidateQueries } from './Shared'

const updateFeatureComment = async (command: UpdateFeatureCommentCommand) => {
  const { commentId, comment, featureId } = command
  if (commentId && comment && featureId) {
    await apiService.features_UpdateFeatureComment(featureId, commentId, command)
  }
}

export function useUpdateFeatureComment() {
  const queryClient = useQueryClient()

  return useMutation((command: UpdateFeatureCommentCommand) => updateFeatureComment(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<any>(queryClient, SgQueryKey.featureComments, (queryKey, data) => {
        if (queryKey[1] === variables.featureId) {
          return data
        }
        return undefined
      })
    },
  })
}
