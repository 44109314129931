import { IComboBoxOption } from '@fluentui/react'
import { useSkus } from 'src/queries'
import { SkuVM } from 'src/services/graphapi'
import { useOptions } from '../../hooks'
import { Filters } from 'src/enums'

export const useSkuTypeOptions = () => {
  const queryResult = useSkus()
  const map = (item: string | undefined) => ({ key: item, text: item } as IComboBoxOption)
  const transform = (items: SkuVM[]) =>
    [
      ...new Set(
        items
          .map((item) => item.skuType)
          .filter((item) => item !== '')
          .sort(),
      ),
    ].map((item) => map(item))
  const options = useOptions<SkuVM>({ queryResult, transform, name: Filters.SKUType, showTitle: true })

  return options
}
