import { getTheme, IStackTokens, mergeStyleSets, Stack, Text } from '@fluentui/react'
import { ContentErrorBoundary } from '../ErrorBoundary'

export interface DashboardCardProps {
  grow?: number | boolean
  title?: string
  description?: string
  children?: any
  className?: string
}

const theme = getTheme()

export const cardTokens: IStackTokens = {
  childrenGap: 5,
}

const getClassNames = () =>
  mergeStyleSets({
    card: {
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: theme.palette.white,
      display: 'flex',
      width: '100%',
      height: '30%',
      boxShadow: theme.effects.elevation8,
      borderRadius: 6,
      padding: 10,
    },
    title: {
      color: theme.palette.themePrimary,
      fontWeight: 'bold',
      maxWidth: '100%',
      '@media(min-height: 850px)': {
        fontSize: theme.fonts.mediumPlus.fontSize,
      },
    },
    titleLeft: {
      alignSelf: 'start',
    },
    description: {
      maxWidth: '100%',
      '@media(min-height: 850px)': {
        fontSize: theme.fonts.xSmall.fontSize,
      },
    },
    overflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
    },
  })

export const DashboardCard = ({ grow, title, description, children, className = '' }: DashboardCardProps) => {
  const classes = getClassNames()

  return (
    <Stack grow={grow} className={`${classes.card} ${className}`} verticalAlign="space-between" tokens={cardTokens}>
      {title && (
        <Text variant="medium" className={`${classes.title} ${!description && classes.titleLeft}`}>
          {title}
        </Text>
      )}
      <ContentErrorBoundary>{children}</ContentErrorBoundary>
      {description && (
        <Text variant="medium" className={classes.description}>
          {description}
        </Text>
      )}
    </Stack>
  )
}
