import { useQuery } from 'react-query'
import { TrendKpiType } from 'src/enums'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getTrendKpi = async (
  trendKpiType: string,
  divisionName?: string,
  organizationName?: string,
  serviceId?: string,
  workstream1Only?: boolean,
) => {
  const response = await apiService.kpi_GetTrendKpi(
    trendKpiType,
    divisionName,
    organizationName,
    serviceId,
    workstream1Only,
  )
  return response
}

export function useTrendKpi(
  trendKpiType: TrendKpiType,
  divisionName?: string,
  organizationName?: string,
  serviceId?: string,
  workstream1Only?: boolean,
) {
  return useQuery(
    [SgQueryKey.trendKpi, trendKpiType, divisionName, organizationName, serviceId, workstream1Only],
    () => getTrendKpi(trendKpiType, divisionName, organizationName, serviceId, workstream1Only),
    {
      enabled: trendKpiType.length > 0,
      useErrorBoundary: true,
      onError: undefined,
    },
  )
}
