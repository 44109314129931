import { ILandingPage } from '../Models'
import { REAL_TIME_INVENTORY } from 'src/constants'
import { lazy } from 'react'

const RtiPage = lazy(() => import('../../../pages/RealTimeInventory/RTI'))

export const Rti = {
  navigationTitle: REAL_TIME_INVENTORY,
  icon: 'DietPlanNotebook',
  securityGroup: 'Service Graph M365 Asset Inventory Users',
  element: <RtiPage />,
} as ILandingPage
