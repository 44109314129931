import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getAppSettings = async () => {
  const response = await apiService.appSettings_Get()
  return response
}

export function useAppSettings() {
  return useQuery(SgQueryKey.appSettings, getAppSettings, {
    staleTime: 10 * MINUTE,
  })
}
