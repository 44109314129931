import { useQuery } from 'react-query'
import { SgQueryKey } from './Shared'
import { useServices } from './useServices'

export function useServiceDetail(serviceId: string | undefined) {
  const { data } = useServices()
  return useQuery([SgQueryKey.services, serviceId], () => data?.find((service) => service.id === serviceId), {
    enabled: !!serviceId && !!data,
  })
}
