import { FilterSize } from 'src/components/Filters'
import { Filters } from 'src/enums'
import { IComboBoxOption } from '@fluentui/react'
import { UseQueryResult } from 'react-query'

interface UseOptionsProps<T> {
  queryResult: UseQueryResult<T[], unknown>
  transform: (item: T[]) => IComboBoxOption[]
  name?: Filters
  showTitle?: boolean
}

export interface OptionResponse {
  options: IComboBoxOption[]
  isFetching?: boolean
  isLoading?: boolean
  error?: Error
  label?: string
  placeholder?: string
  filterSize?: FilterSize
}

export const useOptions = <T>({ transform, queryResult, name, showTitle }: UseOptionsProps<T>): OptionResponse => {
  const { data: queryData = [], isFetching, isLoading, error } = queryResult
  const options = transform(queryData)
  return {
    options,
    isFetching,
    isLoading,
    error: error as Error,
    label: showTitle ? name : undefined,
    placeholder: name ? `Type name of ${name.toLowerCase()}` : undefined,
  }
}
