import { AppSettingsVM } from './graphapi'

const AppSettingsKey = 'SG_APP_SETTINGS_KEY'

export const saveAppSettings = (appSettings?: AppSettingsVM) =>
  localStorage.setItem(AppSettingsKey, JSON.stringify(appSettings || ({} as AppSettingsVM)))

export const isValidAppSettings = (appSettings?: AppSettingsVM): boolean => !!appSettings && !!appSettings.authTenantId

export const loadAppSettings = (): AppSettingsVM => {
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem(AppSettingsKey) || '{}') as AppSettingsVM
  }
  return '{}' as AppSettingsVM
}
