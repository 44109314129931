import { mergeStyleSets, SharedColors, Stack, Text, Image, FontSizes, TooltipHost } from '@fluentui/react'
import { ExtLinkIconBlue, CompliantExternalLink, landingPages } from 'src/components'
import { FunctionComponent } from 'react'
import DocumentTitle from 'react-document-title'
import { useLocation } from 'react-router-dom'
import { useServiceDetail } from 'src/queries'
import { useAppSettings, useFullScreen } from 'src/contexts'
import { SERVICE_GRAPH } from 'src/constants'
import { ServiceInfo } from './ServiceInfo'
import { tourTargetIds } from '../Tour'

export interface PageContentProps {
  children?: any
  withServiceLink?: boolean
  serviceId?: string
  titleParts?: string[]
  showServiceInfo?: boolean
}

const getClassNames = () =>
  mergeStyleSets({
    header: {
      padding: 15,
    },
    title: {
      color: SharedColors.cyanBlue10,
      fontWeight: 'bold',
    },
  })

const getWindowTitle = (pageTitle: string, serviceName?: string, envName?: string, ...args) => {
  const envPart = /^prod/i.test(envName || '?') ? '' : ` [${envName}]`

  const joinedArgs = args[0]
    .filter((x) => x)
    .map((x) => x.toString())
    .join(' · ')
  const serviceNamePart = serviceName ? `${serviceName}: ` : ''
  const argsPart = joinedArgs.length ? `${joinedArgs} · ` : ''
  return `${serviceNamePart}${argsPart}${pageTitle} · ${SERVICE_GRAPH}${envPart}`
}

const getDescription = (text?: string[]) => {
  const lines: JSX.Element[] = []
  text?.forEach((data) => {
    lines.push(<Stack>{data}</Stack>)
  })
  return lines
}

export const PageContent: FunctionComponent<PageContentProps> = ({
  children,
  withServiceLink,
  serviceId,
  titleParts = [],
  showServiceInfo = true,
}) => {
  const classes = getClassNames()
  const { deploymentEnvironmentName } = useAppSettings()
  const { data: serviceDetail } = useServiceDetail(serviceId)
  const { isFullScreen } = useFullScreen()

  const location = useLocation()
  const page = landingPages.getPage(location.pathname)
  const { description, title, externalLink, externalLinkText, imagePath, imageAltText } = page
  const pageTitle = title ?? page.navigationTitle ?? 'Not Found'
  const windowTitle = getWindowTitle(pageTitle, serviceDetail?.name, deploymentEnvironmentName, titleParts)
  const pageDescription = getDescription(description)

  return (
    <DocumentTitle title={windowTitle}>
      <Stack
        styles={{ root: { margin: '0 auto', maxWidth: 3000, minWidth: 1000, paddingTop: 0 } }}
        id={tourTargetIds.documentTitle}
      >
        {!isFullScreen && (
          <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }}>
            <Stack.Item className={classes.header}>
              <Stack
                styles={{
                  root: {
                    fontSize: FontSizes.size18,
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                }}
                tokens={{ childrenGap: 10 }}
                horizontal
              >
                <Text variant="xxLargePlus" block className={classes.title}>
                  {pageTitle}
                </Text>
                {externalLink && (
                  <TooltipHost content={externalLinkText}>
                    <CompliantExternalLink href={externalLink} target="_blank">
                      <ExtLinkIconBlue />
                    </CompliantExternalLink>
                  </TooltipHost>
                )}
              </Stack>

              {showServiceInfo && withServiceLink && <ServiceInfo withServiceLink={withServiceLink} />}
              {pageDescription && (
                <Stack
                  styles={{ root: { fontSize: FontSizes.size18, textAlign: 'justify', marginTop: '0.5em' } }}
                  tokens={{ childrenGap: 20 }}
                >
                  {pageDescription}
                </Stack>
              )}
            </Stack.Item>
            {imagePath && (
              <Stack.Item align="start">
                <Image src={imagePath} alt={imageAltText} height={120} />
              </Stack.Item>
            )}
          </Stack>
        )}
        {children}
      </Stack>
    </DocumentTitle>
  )
}
