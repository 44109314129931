import { useQuery } from 'react-query'
import { SgQueryKey } from './Shared'
import { graphClient } from 'src/contexts'

export interface User {
  id: string
  displayName: string
}

export const getUsers = async (filterText?: string, userIds?: string[]) => {
  const client = graphClient
    .api('/users')
    .header('ConsistencyLevel', 'eventual')
    .filter("endswith(mail,'@microsoft.com')")
    .orderby('userPrincipalName')
    .select('id,displayName,mail')
    .orderby('displayName')
    .count(true)

  if (filterText) {
    client.search(`"displayName:${filterText}"`)
  } else if (userIds && userIds.length) {
    client.filter(`id in (${userIds.map((uid) => '"' + uid + '"').toString()})`)
  } else {
    return {} as User[]
  }

  const response = await client.get()

  return response.value as User[]
}

export function useUsers(filterText?: string, userIds?: string[]) {
  return useQuery([SgQueryKey.users, filterText, userIds], () => getUsers(filterText, userIds), {
    enabled: !!filterText || !!userIds,
    refetchOnWindowFocus: false,
  })
}
