import { useQuery } from 'react-query'
import { apiService } from 'src/services/graphapi'
import { DataFlowState } from 'src/services/graphapi/lists'
import { SgQueryKey } from './Shared'

const getServiceDataFlows = async (serviceId?: string, State?: DataFlowState) => {
  const response = await apiService.serviceDataFlow_Get(serviceId, State)
  return response
}

export function useServiceDataFlows(serviceId?: string, State?: DataFlowState) {
  return useQuery([SgQueryKey.serviceDataFlows, serviceId, State], () => getServiceDataFlows(serviceId, State), {
    enabled: !!serviceId,
  })
}
