export const SERVICE_GRAPH = 'Service Graph'
export const ARIA_LABEL_FOR_DATA_GROUP_SHIMMER = 'Data Flow Groups are being fetched'
export const DATA_FLOW_GROUP_HEADER_TEXT = 'Manage Data Flow Groups'
export const CANCEL_BUTTON_TEXT = 'Cancel'
export const SAVING_BUTTON_TEXT = 'Saving'
export const YES_BUTTON_TEXT = 'Yes'
export const NO_BUTTON_TEXT = 'No'
export const OK_BUTTON_TEXT = 'Ok'
export const SKIP_BUTTON_TEXT = 'Skip'
export const SAVE_BUTTON_TEXT = 'Save'
export const CLOSE_BUTTON_TEXT = 'Close'
export const CLOSE_BUTTON_ICON = ''
export const REJECT_BUTTON_TEXT = 'Reject'
export const NEXT_BUTTON_TEXT = 'Next'
export const BACK_BUTTON_TEXT = 'Back'
export const DELETE_BUTTON_TEXT = 'Delete Feature'
export const CONMON_DOWNLOAD = 'Submit to ConMon'
export const ADD_NEW_DATA_FLOW_HEADER_TEXT = 'Add new Dataflow'
export const EDIT_DATA_FLOW_HEADER_TEXT = 'Edit Dataflow'
export const ALL = 'All'
export const RESOURCE_GROUP_LABEL = 'Resource Group'
export const DIAGRAM_NAME_LABEL = 'Diagram Name'
export const RESOURCE_ENV_LABEL = 'Environment'
export const NODE_LABEL = 'Node'
export const M365_CLOUD_LABEL = 'M365 Cloud'
export const RESOURCE_PROVIDER_LABEL = 'Resource Provider'
export const RESOURCE_TYPE_LABEL = 'Resource Type'
export const CLICK_HERE = 'click here'
export const HERE = 'here'
export const ALERTS_HEADER_TEXT = 'Alerts'
export const DATA_CLASSIFICATION_TEXT = 'Data Classification'
export const DATA_CLASSIFICATION_ID = 'dataClassification'
export const SELECT_TEXT = 'Select'
export const SELECT_ALL_TEXT = 'Select All'
export const CLEAR_SELECTION_TEXT = 'Clear Selection'
export const SOURCE_SERVICE_TEXT = 'Source Service:'
export const DESTINATION_SERVICE_TEXT = 'Destination Resource:'
export const NO_SERVICE_SELECTED_FOR_TM =
  'Please select your service and make sure your Threat Model is uploaded for these items to be populated'
export const SERVICE_SELECTION_HINT =
  'Service Graph displays services that have prod subscription and less than 1 million resources.'
export const SGI_SELECTION_HINT = 'You can use this dropdown to select a particular run.'
export const SGI_APP_SELECTION_HINT = 'You can use this dropdown to select a particular application.'
export const UPLOAD_NONEXISTING_TM = "Threat Model doesn't exist. Please upload Threat Model first."
export const NODE_TYPE_RESOURCE_LINKING_LIMITATION =
  'Only nodes with type "Resources" are available for linking to resources.'
export const NODE_MATCH_RESOURCE_LINKING_LIMITATION =
  'Nodes with resource match "Automated" or "Partially Manual" cannot be linked or unlinked manually.'

export const OVERVIEW_KPI_REPORT_FILENAME = 'Overview KPI'
export const VALUE_NOT_AVAILABLE = 'N/A'
export const CLOUD_PARITY_REPORT = 'Cloud Parity Report'
export const SKU_MAPPING_REPORT = 'Sku Mapping Report'
export const CONFIDENTIAL_FLAG = 'Confidential: Microsoft internal use only'
export const AIML_EUDB_DASHBOARD = 'AIML EUDB Dashboard'
export const REAL_TIME_INVENTORY = 'Real Time Inventory'
export const RESOURCES = 'Resources'
export const MODELS = 'Models'
export const EUDB_ALERTS = 'EUDB alerts'
export const E3_FRANCE_SCOPE_LINK = 'PSL 1268 E3 France scope'
export const PSL_1271_SCOPE_LINK = 'PSL 1271 E3 GoLocal scope'

export const SERVICES_AUTOCOMPLETE_PLACEHOLDER = 'Select Services'

export const getFeatureUpdateSummaryMessage = (allStepsSuccessful) =>
  `Your feature has been ${allStepsSuccessful ? '' : '(partially)'} updated`

export const TABLE_TEXTS = {
  NO_SERVICE_SELECTED_MSG: 'Please, select a service to view the items here',
}

export const SG_HELP_EMAIL = 'servicegraphhelp@microsoft.com'

export const NOT_SERVICE_OWNER_HINT = 'You are not the service owner'

export const FOOTER_TEXT = {
  DOCUMENTATION_LINK: 'Microsoft Service Graph',
  DATA_PROTECTION_NOTICE_LINK: 'Data Protection Notice',
}

export const DELETED_USER = 'Deleted User'

export const REQUIRED_MESSAGE = (field: string) => `${field} is a required field.`
export const SUBMIT_FEEDBACK_TEXT = 'Submit Feedback'

export const ITEMS_INDICATOR_ID = 'Numerical value for '

export const TO_FULL_SCREEN_ICON = 'FullScreen'
export const TO_WINDOW_SCREEN_ICON = 'BackToWindow'

export const multiSelectControlPlaceholder = (label) => `Select ${label ?? ''}`
