import { IStackItemStyles, Stack } from '@fluentui/react'
import { AlertPayloadComponent } from '../../../../features/SGAlerts/base/types'
import { CrossBoundaryDataFlowAlertRawPayload } from './types'
import { ViolatedDataFlowsTable } from './ViolatedDataFlowsTable'
import { COLOR_LABELS } from 'src/constants'
import { RemediationSteps } from './RemediationSteps'
import { TogglePanel } from 'src/components'

const headerStackItemStyle: IStackItemStyles = {
  root: {
    color: COLOR_LABELS.MEDIUM_BLUE,
    fontSize: '14',
    fontWeight: 'bold',
  },
}

export const CrossBoundaryDataFlowAlertPayload: AlertPayloadComponent<CrossBoundaryDataFlowAlertRawPayload> = ({
  rawPayload,
}) => {
  const dcTitles = rawPayload.DataClassification?.join(', ')
  return (
    <Stack>
      <Stack.Item styles={headerStackItemStyle}>Description:</Stack.Item>
      <Stack.Item>This is the EU Data Boundary violation.</Stack.Item>
      <Stack.Item>
        According to the EUDB compliance, Services from EUDB Scope must store and process EU customer data classified as
        CC&#x2F;EUII&#x2F;EUPI&#x2F;SD within the EU Data Boundary.
      </Stack.Item>
      <Stack.Item>
        We found the selected service is assigned to EUDB Scope and transfers data outside EU Data Boundary.
      </Stack.Item>
      <Stack.Item>
        We suspect that your service is potentially transferring <b>{dcTitles}</b> data outside of EU, based on the
        information collected from partner teams.
      </Stack.Item>
      <br />
      <Stack.Item styles={headerStackItemStyle}>Violated Data Flows:</Stack.Item>
      <Stack.Item>
        <ViolatedDataFlowsTable dataFlows={[rawPayload as CrossBoundaryDataFlowAlertRawPayload]} />
      </Stack.Item>
      <br />
      <Stack.Item styles={headerStackItemStyle}>Extended properties:</Stack.Item>
      <Stack.Item>
        <TogglePanel text={rawPayload.ExtendedProperties as string} />
      </Stack.Item>
      <br />
      <Stack.Item styles={headerStackItemStyle}>Remediation steps:</Stack.Item>
      <RemediationSteps />
    </Stack>
  )
}
