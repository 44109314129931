import { IStackTokens, mergeStyles, Stack } from '@fluentui/react'
import { FunctionComponent } from 'react'

export interface DashboardCardListProps {
  grow?: number | boolean
  children?: any
  className?: string
  tokens?: IStackTokens
}

const cardListTokens: IStackTokens = { childrenGap: 25 }
const cardListClassName = mergeStyles({
  height: 'calc(100vh - 220px)',
  minHeight: 500,
  width: 100,
})

export const DashboardCardList: FunctionComponent<DashboardCardListProps> = ({ grow, children, className, tokens }) => {
  return (
    <Stack grow={grow} className={`${cardListClassName} ${className}`} tokens={tokens ?? cardListTokens}>
      {children}
    </Stack>
  )
}
