import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IModalProps,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useCallback } from 'react'

const modalProps: IModalProps = {
  isBlocking: true,
  topOffsetFixed: true,
}

export const ConfirmationDialog = ({ message, onConfirm, onDismiss }) => {
  const [disableButtons, { toggle: toggleDisableButtons }] = useBoolean(false)

  const onClickConfirm = useCallback(async () => {
    toggleDisableButtons()
    onConfirm()
  }, [onConfirm, toggleDisableButtons])

  const dialogContentProps = {
    type: DialogType.normal,
    subText: message,
    closeButtonAriaLabel: 'Close',
  }

  return (
    <Dialog hidden={false} onDismiss={onDismiss} dialogContentProps={dialogContentProps} modalProps={modalProps}>
      <DialogFooter>
        <PrimaryButton
          disabled={disableButtons}
          onClick={onClickConfirm}
          text={'Yes'}
          onRenderIcon={() => (disableButtons ? <Spinner size={SpinnerSize.xSmall} /> : null)}
        />
        <DefaultButton disabled={disableButtons} onClick={onDismiss} text={'No'} />
      </DialogFooter>
    </Dialog>
  )
}

export default ConfirmationDialog
