import React from 'react'
import { getTheme, IProgressIndicatorStyles, ProgressIndicator } from '@fluentui/react'

const theme = getTheme()

const progressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
  root: {
    marginTop: -3,
    borderRadius: theme.effects.roundedCorner2,
  },
  itemProgress: {
    padding: 0,
    borderRadius: theme.effects.roundedCorner2,
  },
}

export interface FetchingProgressIndicatorProps {
  label?: string
}

export const FetchingProgressIndicator = React.memo(({ label }: FetchingProgressIndicatorProps) => (
  <ProgressIndicator styles={progressIndicatorStyles} label={label} />
))
