import { FontSizes, SharedColors } from '@fluentui/react'
import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts'

export interface CircularProgressProps {
  percentage: number
  size: number
  color?: string
}

export const CircularProgress = ({ percentage, size, color }: CircularProgressProps) => {
  const data = [{ name: 'data', percentage }]
  const halfSize = size / 2

  return (
    <RadialBarChart
      width={size}
      height={size}
      cx={halfSize}
      cy={halfSize}
      innerRadius={halfSize - 1}
      outerRadius={halfSize + 2}
      barSize={4}
      data={data}
      startAngle={90}
      endAngle={-270}
    >
      <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
      <RadialBar background dataKey="percentage" cornerRadius={size / 2} fill={color ?? SharedColors.cyan30} />
      <text
        x={halfSize}
        y={halfSize}
        textAnchor="middle"
        dominantBaseline="middle"
        className="progress-label"
        fontSize={FontSizes.size24}
        fill={SharedColors.cyan30}
      >
        {percentage}%
      </text>
    </RadialBarChart>
  )
}
