import { IPersonaProps } from '@fluentui/react'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { getUsers, SgQueryKey, useUsersByIds, User } from 'src/queries'
import { BasicPeoplePicker } from '../BasicPeoplePicker'
import { DELETED_USER } from '../../../constants/StringConstants'
import { Filters } from 'src/enums'

export interface PeoplePickerProps {
  id?: string
  name?: Filters
  initialUsersIds?: string[]
  disabled?: boolean
  omitError?: boolean
  onChangeItems?: (ids: string[]) => void
}

export const PeoplePicker: FunctionComponent<PeoplePickerProps> = ({
  initialUsersIds,
  disabled,
  omitError,
  name,
  onChangeItems,
}) => {
  const queryClient = useQueryClient()
  const results = useUsersByIds(initialUsersIds, omitError)
  const data = results.map((result) => result.data)
  const isLoadingInitialUsersIds = results.some((result) => result.isLoading)
  const initialUsers = useMemo(
    () =>
      data.map((it) => ({
        key: it?.id,
        text: isLoadingInitialUsersIds ? undefined : it?.displayName || DELETED_USER,
      })) || [],
    [data, isLoadingInitialUsersIds],
  )
  const pickerName = name ? name : 'People'

  const getItems = useCallback(
    async (filterText: string) => {
      const users = await queryClient.fetchQuery<User[]>([SgQueryKey.users, filterText], () => getUsers(filterText))
      const people = users.map(({ id, displayName }) => ({ key: id, text: displayName }))
      return people
    },
    [queryClient],
  )

  const onPeopleItemsChange = useCallback(
    (items?: IPersonaProps[]) => {
      if (onChangeItems && items) {
        onChangeItems(items.map((it) => it.key?.toString() || ''))
      }
    },
    [onChangeItems],
  )
  return (
    <BasicPeoplePicker
      name={pickerName}
      initialUsers={initialUsers}
      disabled={disabled}
      isLoading={isLoadingInitialUsersIds}
      getItems={getItems}
      onChangeItems={onPeopleItemsChange}
      placeholder={`Search for ${pickerName}`}
    />
  )
}
