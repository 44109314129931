import { AutoCompleteMultiselect } from '../../AutoCompleteMultiselect'
import { useUrlAutoCompleteMultiSelect } from '../../hooks'
import { CustomFilterSizeProps } from '../Props'
import { useServicePlanOptions } from './useServicePlanOptions'
import { useSearchParamValue } from 'src/hooks'
import { QueryStringParam } from 'src/enums'

interface ServicePlanOptionsProps extends CustomFilterSizeProps {
  isSkuMappingReport?: boolean
}

export const ServicePlanAutoCompleteMultiSelect = ({ filterSize, isSkuMappingReport }: ServicePlanOptionsProps) => {
  const [skuIds] = useSearchParamValue(QueryStringParam.SkuIds)
  const options = useServicePlanOptions(skuIds, isSkuMappingReport)
  const { urlValue, onChangeMultipleValues } = useUrlAutoCompleteMultiSelect({
    urlKey: QueryStringParam.ServicePlanIds,
    dependents: [QueryStringParam.ProductIds, QueryStringParam.ServiceComponentIds],
  })
  const selectedKeys = urlValue?.split('_') ?? []
  return (
    <AutoCompleteMultiselect
      {...options}
      selectedKey={selectedKeys}
      onChangeMultipleValues={onChangeMultipleValues}
      filterSize={filterSize}
      selectedOptionLimit={5}
    />
  )
}
