import { useContext, useMemo } from 'react'
import { AppSettingsContext } from 'src/contexts'
import { AudienceEnum } from 'src/enums/AudienceEnum'

export const useAudience = () => {
  const appSettings = useContext(AppSettingsContext)

  const audience = useMemo(() => {
    const environment = appSettings.deploymentEnvironmentName
    return environment ? (environment?.toLowerCase() as AudienceEnum) : AudienceEnum.PRODUCTION
  }, [appSettings.deploymentEnvironmentName])

  return audience
}
