import { IComboBox, IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react'
import { FormEvent } from 'react'
import { QueryStringParam } from 'src/enums'
import { useSearchParamValue } from 'src/hooks'

interface UrlAutoCompleteProps {
  urlKey: QueryStringParam
  dependents?: QueryStringParam[]
}

interface UrlAutoCompleteResponse {
  urlValue?: string
  onChange: (_event: FormEvent<IComboBox>, option?: IComboBoxOption) => void
}

export const useUrlAutoComplete = ({ urlKey, dependents }: UrlAutoCompleteProps): UrlAutoCompleteResponse => {
  const [urlValue, setUrlValue] = useSearchParamValue(urlKey, dependents)

  const onChange = (_event: FormEvent<IComboBox>, option?: IComboBoxOption) => {
    const isClearAllOption = option?.itemType === SelectableOptionMenuItemType.SelectAll
    setUrlValue(isClearAllOption ? undefined : option ? String(option.key) : undefined)
  }

  return {
    urlValue,
    onChange,
  }
}
