import { UserProfile } from 'src/contexts'

const UserProfileKey = 'SG_APP_USER_PROFILE'

export const saveUserProfile = (userProfile?: UserProfile) =>
  localStorage.setItem(UserProfileKey, JSON.stringify(userProfile || ({} as UserProfile)))

export const isValidUserProfile = (userProfile?: UserProfile): boolean =>
  !!userProfile && !!userProfile.owningServiceIds && !!userProfile.userRoles

export const loadUserProfile = (): UserProfile =>
  JSON.parse(localStorage.getItem(UserProfileKey) || '{}') as UserProfile

export const loadUsersServiceIds = (): string[] => loadUserProfile().owningServiceIds ?? []

export const isUserServiceOwner = (): boolean => loadUsersServiceIds().length > 0
