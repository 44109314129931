import { useQuery } from 'react-query'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getAlertDetails = async (serviceId?: string) => {
  const response = await apiService.sGAlert_GetAlertDetails(serviceId)
  return response
}

export function useSGAlertDetails(alertId?: string) {
  return useQuery([SgQueryKey.sgAlertsDetail, alertId], () => getAlertDetails(alertId), {
    enabled: !!alertId,
  })
}
