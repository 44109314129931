import { useMutation, useQueryClient } from 'react-query'
import {
  apiService,
  DataFlowNodeType,
  DataFlowVM,
  OtherNodeMetadataVM,
  ResourcesNodeMetadataVM,
  ServiceNodeMetadataVM,
  TMErrorVM,
  TMNodeVM,
} from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

interface ICommandParams {
  node: TMNodeVM
  metadata: ServiceNodeMetadataVM | ResourcesNodeMetadataVM | OtherNodeMetadataVM
}

const updateNodeMetadata = async ({ node, metadata }: ICommandParams) => {
  const owningTeamServiceId = node.serviceId
  if (metadata.type == DataFlowNodeType.Service) {
    return apiService.nodes_UpdateServiceNodeMetadata({
      owningTeamServiceId,
      metadata,
    })
  } else if (metadata.type == DataFlowNodeType.Resources) {
    return apiService.nodes_UpdateResourcesNodeMetadata({
      owningTeamServiceId,
      metadata,
    })
  } else {
    return apiService.nodes_UpdateOtherNodeMetadata({
      owningTeamServiceId,
      metadata,
    })
  }
}

export function useUpdateNodeMetadata() {
  const queryClient = useQueryClient()

  return useMutation(updateNodeMetadata, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries([SgQueryKey.resources])
      optimisticUpdateAndInvalidateQueries<TMNodeVM[]>(queryClient, SgQueryKey.tmNodes, (_queryKey, data) => {
        const foundedItem = data.find((item) => item.nodeId == variables.node.nodeId)
        if (foundedItem == undefined) {
          return undefined
        }
        return data.map((item) => {
          if (item !== foundedItem) return item
          return {
            ...item,
            ...variables.node,
            type: variables.metadata.type,
          }
        })
      })
      optimisticUpdateAndInvalidateQueries(queryClient, SgQueryKey.tmNodeMetadata, (queryKey) => {
        if (queryKey[1] == variables.node.nodeId) {
          return variables.metadata
        }

        return null
      })
      optimisticUpdateAndInvalidateQueries<DataFlowVM[]>(queryClient, SgQueryKey.dataFlows, (_queryKey, data) => {
        const foundedItem = data.find(
          (item) => item.sourceNodeId == variables.node.nodeId || item.destinationNodeId == variables.node.nodeId,
        )
        if (foundedItem == undefined) {
          return undefined
        }
        return data
      })
      optimisticUpdateAndInvalidateQueries<TMErrorVM[]>(queryClient, SgQueryKey.tmErrors, (_queryKey, data) => {
        const foundedItem = data.find((item) => item.entityId == variables.node.nodeId)
        if (foundedItem == undefined) {
          return undefined
        }
        return data
      })
    },
  })
}
