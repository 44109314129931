import { lazy } from 'react'
import { ILandingPage } from '../Models'
import { MODELS } from 'src/constants/StringConstants'

const ThreatModelsPage = lazy(() => import('../../../pages/ThreatModels/ThreatModels'))

export const Models = {
  navigationTitle: MODELS,
  icon: 'Sort',
  element: <ThreatModelsPage />,
} as ILandingPage
