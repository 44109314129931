import { lazy } from 'react'
import { ILandingPage } from '../Models'

const DataFlowsPage = lazy(() => import('../../../pages/DataFlows/DataFlows'))

export const DataFlows = {
  navigationTitle: 'Data Flow Management',
  icon: 'Dataflows',
  element: <DataFlowsPage />,
} as ILandingPage
