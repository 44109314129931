import { useMutation, useQueryClient } from 'react-query'
import { CreateFeatureCommand, apiService, FeatureVM, UpdateFeatureCommand } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const createFeature = async (command: CreateFeatureCommand) => {
  const res = await apiService.features_CreateFeature(command)
  return res
}

const updateFeature = async (command: UpdateFeatureCommand) => {
  const res = await apiService.features_UpdateFeature(command.featureId ?? '', command)
  return res
}

export function useSaveFeature() {
  const queryClient = useQueryClient()

  return useMutation(
    (command: UpdateFeatureCommand) => (command.featureId ? updateFeature(command) : createFeature(command)),
    {
      onSuccess: (_data, _variables) => {
        optimisticUpdateAndInvalidateQueries<FeatureVM[]>(queryClient, SgQueryKey.features, (_queryKey, data) => {
          return data
        })
        optimisticUpdateAndInvalidateQueries<FeatureVM[]>(
          queryClient,
          SgQueryKey.cloudParityReport,
          (_queryKey, data) => {
            return data
          },
        )
      },
    },
  )
}
