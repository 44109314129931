import { Icon, mergeStyleSets, FontSizes, getTheme, DefaultEffects } from '@fluentui/react'
import { useContext } from 'react'
import { Z_INDEX_1000 } from 'src/constants'
import { AppSettingsContext, useUserProfile } from 'src/contexts'
import { SignOut } from '../SignOut'

const theme = getTheme()
const getClassNames = () => {
  return mergeStyleSets({
    header: {
      gridArea: 'app-header',
      display: 'grid',
      position: 'fixed',
      gridTemplateColumns: '48px 1fr',
      zIndex: Z_INDEX_1000,
      alignItems: 'center',
      width: '100vw',
      background: theme.palette.themePrimary,
      color: theme.palette.white,
      cursor: 'default',
      boxShadow: DefaultEffects.elevation8,
    },
    icon: {
      fontSize: FontSizes.size20,
      color: theme.palette.white,
      padding: 14,
    },
    title: {
      fontSize: FontSizes.size16,
      paddingLeft: 8,
    },
    cornerRibbon: {
      left: '-80px',
      position: 'absolute',
      top: '16px',
      height: '24px',
      width: '216px',
      transform: 'rotate(-45deg)',
      backgroundColor: theme.palette.red,
      textAlign: 'center',
    },
    userName: {
      fontSize: FontSizes.size14,
      gridColumn: '3 / 4',
      paddingRight: 4,
    },
  })
}

export const Header = () => {
  const classes = getClassNames()
  const appSettings = useContext(AppSettingsContext)
  const environment = appSettings.deploymentEnvironmentName?.toLocaleLowerCase() || ''
  const { email } = useUserProfile()

  return (
    <header className={classes.header}>
      {!/^prod/i.test(environment) && <div className={classes.cornerRibbon}>{environment}</div>}
      <Icon className={classes.icon} iconName="Waffle" />
      <span className={classes.title}>Microsoft Service Graph</span>
      <span className={classes.userName}>{email}</span>
      <SignOut />
    </header>
  )
}
