import { lazy } from 'react'
import { ILandingPage } from '../Models'
import { AIML_EUDB_DASHBOARD } from 'src/constants'

const AimlEuDashboardPage = lazy(() => import('../../../pages/AIML/AimlEuDashboard'))

export const AimlEUDB = {
  navigationTitle: AIML_EUDB_DASHBOARD,
  icon: 'ViewDashboard',
  element: <AimlEuDashboardPage />,
} as ILandingPage
