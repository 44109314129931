import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/Services'
import { useQuery } from 'react-query'

export const getSGIJobEnvironments = async () => {
  const response = await apiService.sGI_GetSGIJobEnvironments()
  return response
}

export function useSGIJobEnvironments() {
  return useQuery(SgQueryKey.sgiJobEnvironments, getSGIJobEnvironments)
}
