import { AutoComplete } from '../../../AutoComplete'
import { FilterSize } from 'src/components/Filters'
import { QueryStringParam } from 'src/enums'
import React from 'react'
import { useRTITeamsOptions } from './useRTITeamsOptions'
import { useUrlAutoComplete } from '../../../hooks'

export const RTITeamUrlAutoComplete: React.FunctionComponent = () => {
  const urlKey = QueryStringParam.RTITeam

  const { urlValue, onChange } = useUrlAutoComplete({ urlKey })

  const options = useRTITeamsOptions()

  return <AutoComplete {...options} selectedKey={urlValue} onChange={onChange} filterSize={FilterSize.M} />
}
