import { useMutation, useQueryClient } from 'react-query'
import { apiService, UpdateFeatureProductsCommand, FeatureProductVM } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const saveFeatureProduct = async (command: UpdateFeatureProductsCommand) => {
  if (command.featureId) {
    await apiService.features_UpdateFeatureProducts(command.featureId, command)
  }
}

export function useSaveFeatureProduct() {
  const queryClient = useQueryClient()

  return useMutation((command: UpdateFeatureProductsCommand) => saveFeatureProduct(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<FeatureProductVM[]>(
        queryClient,
        SgQueryKey.featureProduct,
        (queryKey, data) => {
          if (queryKey[1] === variables.featureId) {
            return data
          }
          return undefined
        },
      )
      queryClient.invalidateQueries([SgQueryKey.features])
    },
  })
}
