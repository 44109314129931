import { Stack } from '@fluentui/react'
import { ErrorFallback } from '../ErrorBoundary'

export interface SGTableErrorProps {
  error?: Error
  refetch?: () => void
  showErrorHeader?: boolean
}

export interface SGTableMessageSharedProps {
  errorFallback?: SGTableErrorProps
  isLoading?: boolean
  name?: string
  noDataMsg?: string
}

export interface SGTableMessageProps extends SGTableMessageSharedProps {
  itemCount: number
}

export const SGTableMessage = ({
  errorFallback,
  isLoading,
  itemCount,
  name,
  noDataMsg,
}: SGTableMessageProps): JSX.Element | null => {
  if (isLoading) return null

  if (errorFallback?.error) {
    const { error, refetch, showErrorHeader = true } = errorFallback
    const showResetBtn = refetch != null
    return (
      <ErrorFallback
        error={error}
        resetErrorBoundary={refetch || (() => {})}
        showResetBtn={showResetBtn}
        showErrorHeader={showErrorHeader}
      />
    )
  } else if (!itemCount) {
    return (
      <Stack verticalFill horizontalAlign="center" tokens={{ padding: 15 }}>
        {noDataMsg ?? `No ${name} can be found`}
      </Stack>
    )
  }

  return null
}
