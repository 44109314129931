import { IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react'
import { useSearchParamValue } from 'src/hooks'
import { useServices } from 'src/queries'
import { loadUsersServiceIds } from 'src/services'
import { ServiceVM } from 'src/services/graphapi'
import { useOptions } from '../../hooks'
import { Filters, QueryStringParam } from 'src/enums'

export interface ServiceOptionsProps {
  showTitle?: boolean
  areServiceOptionsGrouped?: boolean
  displayOnlyMyServices?: boolean
}

const name = Filters.Service

const sortByGroup = (services: ServiceVM[]) => {
  if (!services.length) {
    return []
  }

  const usersServiceIds = loadUsersServiceIds()
  return [
    ...usersServiceIds
      .map((usersServiceId) => services.find((service) => service.id === usersServiceId) || {})
      .filter((service) => !!service),
    ...services.filter((service) => service.id && !usersServiceIds.includes(service.id)),
  ]
}

const findMyServices = (services: ServiceVM[]) => {
  if (!services.length) {
    return []
  }
  const usersServiceIds = loadUsersServiceIds()
  return usersServiceIds
    .map((usersServiceId) => services.find((service) => service.id === usersServiceId) || {})
    .filter((service) => !!service)
}

const map = (service: ServiceVM) => ({ key: service.id || '', text: service.name || '' } as IComboBoxOption)

const insert = (options: IComboBoxOption[]) => {
  const usersServices = loadUsersServiceIds()
  if (!usersServices.length) {
    return options
  }

  const indexOtherServices = options.findIndex((option) => !usersServices.includes(option.key as string))
  return [
    { key: 'MyServices', text: 'My Services', itemType: SelectableOptionMenuItemType.Header },
    ...options.slice(0, indexOtherServices),
    { key: 'OtherServices', text: 'Other Services', itemType: SelectableOptionMenuItemType.Header },
    ...options.slice(indexOtherServices),
  ]
}

const getTransformedServiceList = (areServiceOptionsGrouped?: boolean, displayOnlyMyServices?: boolean) => {
  return displayOnlyMyServices
    ? (services: ServiceVM[]) => findMyServices(services).map((service) => map(service))
    : areServiceOptionsGrouped
    ? (services: ServiceVM[]) => insert(sortByGroup(services).map((service) => map(service)))
    : (services: ServiceVM[]) => services.map((service) => map(service))
}

export const useServiceOptions = ({
  showTitle,
  areServiceOptionsGrouped,
  displayOnlyMyServices,
}: ServiceOptionsProps) => {
  const queryResult = useServices()
  const transform = getTransformedServiceList(areServiceOptionsGrouped, displayOnlyMyServices)

  const options = useOptions<ServiceVM>({
    queryResult,
    transform,
    name,
    showTitle,
  })

  return options
}

export const useServiceOptionsWithFilters = ({
  showTitle,
  areServiceOptionsGrouped,
  displayOnlyMyServices,
}: ServiceOptionsProps) => {
  const [divisionUrlValue] = useSearchParamValue(QueryStringParam.Division)
  const [organizationUrlValue] = useSearchParamValue(QueryStringParam.Organization)
  const queryResult = useServices(divisionUrlValue, organizationUrlValue)
  const transform = getTransformedServiceList(areServiceOptionsGrouped, displayOnlyMyServices)

  const options = useOptions<ServiceVM>({ queryResult, transform, name, showTitle })

  return options
}
