import { useMutation, useQueryClient } from 'react-query'
import { UpdateSGAlertCommand, apiService, EUDBAlertVM, SGAlertDetailsVM } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const updateBoundaryAlertState = async (command: UpdateSGAlertCommand) => {
  await apiService.sGAlert_UpdateAlert(command)
}

export function useUpdateSGAlert() {
  const queryClient = useQueryClient()

  return useMutation((command: UpdateSGAlertCommand) => updateBoundaryAlertState(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<EUDBAlertVM[]>(queryClient, SgQueryKey.sgAlerts, (_queryKey, data) => {
        let itemFound = false
        data = data.map((alert) => {
          if (alert.id !== variables?.alertId) return alert
          itemFound = true
          return { ...alert, state: variables.newState }
        })
        return itemFound ? data : undefined
      })
      optimisticUpdateAndInvalidateQueries<SGAlertDetailsVM>(
        queryClient,
        SgQueryKey.sgAlertsDetail,
        (queryKey, data) => {
          if (queryKey[1] === variables.alertId) {
            return data
          }
          return undefined
        },
      )
    },
  })
}
