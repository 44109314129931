import { IColumn } from '@fluentui/react'
import { CrossBoundaryDataFlowAlertRawPayload } from '../types'
import { ResourceActionCell } from '../../utils/ResourceActionCell'
import { ServiceActionCell } from '../../utils/ServiceActionCell'

export const getColumns = (): IColumn[] => [
  {
    key: 'Source',
    name: 'Source',
    fieldName: 'Source',
    minWidth: 80,
    flexGrow: 1,
    isMultiline: true,
    onRender: (item: CrossBoundaryDataFlowAlertRawPayload) =>
      item.SourceType === 'Resource' ? (
        <ResourceActionCell
          serviceId={item.SourceServiceId}
          resourceId={item.SourceSgId}
          resourceName={item.SourceName}
        />
      ) : (
        <ServiceActionCell serviceId={item.SourceServiceId} serviceName={item.SourceName} />
      ),
  },
  {
    key: 'Destination',
    name: 'Destination',
    fieldName: 'Destination',
    minWidth: 80,
    flexGrow: 1,
    isMultiline: true,
    onRender: (item: CrossBoundaryDataFlowAlertRawPayload) =>
      item.DestinationType === 'Resource' ? (
        <ResourceActionCell
          serviceId={item.DestinationServiceId}
          resourceId={item.DestinationSgId}
          resourceName={item.DestinationName}
        />
      ) : (
        <ServiceActionCell serviceId={item.DestinationServiceId} serviceName={item.DestinationName} />
      ),
  },
  {
    key: 'SourceRegion',
    name: 'Source Location',
    fieldName: 'SourceRegion',
    minWidth: 80,
    flexGrow: 1,
    isMultiline: true,
  },
  {
    key: 'DestinationRegion',
    name: 'Destination Location',
    fieldName: 'DestinationRegion',
    minWidth: 80,
    flexGrow: 1,
    isMultiline: true,
  },
  {
    key: 'DataClassification',
    name: 'Data classification',
    fieldName: 'DataClassification',
    minWidth: 40,
    flexGrow: 1,
    isMultiline: true,
    onRender: (item: CrossBoundaryDataFlowAlertRawPayload) => <span>{item.DataClassification.join(', ')}</span>,
  },
]
