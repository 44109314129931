import { useQueries, useQuery } from 'react-query'
import { SgQueryKey } from './Shared'
import { graphClient } from 'src/contexts'
import { User } from './useUsers'

export const getUserById = async (id?: string) => {
  if (!id || id === '') return undefined

  const client = graphClient
    .api(`/users/${id}`)
    .header('ConsistencyLevel', 'eventual')
    .orderby('userPrincipalName')
    .select('id,displayName,mail')
    .orderby('displayName')
    .count(true)

  const response = await client.get()

  return response as User
}

function userQueryOptions(id?: string, omitError?: boolean) {
  const defaultOptions = {
    queryKey: [SgQueryKey.userById, id],
    queryFn: () => getUserById(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
  }
  return omitError ? { ...defaultOptions, onError: undefined } : defaultOptions
}

export function useUserById(id?: string, omitError?: boolean) {
  return useQuery(userQueryOptions(id, omitError))
}

export function useUsersByIds(ids?: string[], omitError?: boolean) {
  return useQueries(ids?.map((id) => userQueryOptions(id, omitError)) ?? [userQueryOptions(undefined, omitError)])
}
