import { useQuery } from 'react-query'
import { apiService, DataFlowNodeType, TMNodeVM } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getNodeMetadata = async (node: TMNodeVM) => {
  if (node.type == DataFlowNodeType.Service) {
    return apiService.nodes_GetServiceNodeMetadata(node.serviceId, node.nodeId)
  } else if (node.type == DataFlowNodeType.Resources) {
    return apiService.nodes_GetResourcesNodeMetadata(node.serviceId, node.nodeId)
  } else {
    return apiService.nodes_GetOtherNodeMetadata(node.serviceId, node.nodeId)
  }
}

export function useNodeMetadata(node: TMNodeVM) {
  return useQuery([SgQueryKey.tmNodeMetadata, node.nodeId, node.type], () => getNodeMetadata(node), {
    enabled: !!node,
    refetchOnWindowFocus: false,
  })
}
