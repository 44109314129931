import { FunctionComponent } from 'react'
import { AlertPayloadComponentsContext } from '../../features/SGAlerts/contexts/alertPayloadComponents'
import { AlertPayloadComponent } from '../../features/SGAlerts/base/types'
import { SGAlertType } from 'src/services/graphapi'
import { ResourceAsymmetryAlertPayload } from 'src/pages/EUDBAlerts/alertPayload/ResourceAsymmetryAlertPayload'
import { AimlStorageAlertPayload } from 'src/pages/EUDBAlerts/alertPayload/AimlAlerts/AimlStorageAlertPayload'
import { AimlHostingAlertPayload } from 'src/pages/EUDBAlerts/alertPayload/AimlAlerts/AimlHostingAlertPayload'
// eslint-disable-next-line max-len
import { AimlTrainingDatasetsAlertPayload } from '../../pages/EUDBAlerts/alertPayload/AimlAlerts/AimlTrainingDatasetsAlertPayload'
import { AimlInferencingAlertPayload } from 'src/pages/EUDBAlerts/alertPayload/AimlAlerts/AimlInferencingAlertPayload'
import {
  FimDnsRequestAlertPayload,
  CrossBoundaryDataFlowAlertPayload,
} from 'src/pages/EUDBAlerts/alertPayload/CrossBoundaryDataFlowAlertPayload'
// eslint-disable-next-line max-len
import { AimlTrainingEnvironmentAlertPayload } from '../../pages/EUDBAlerts/alertPayload/AimlAlerts/AimlTrainingEnvironmentAlertPayload'

const components = new Map<SGAlertType, AlertPayloadComponent<object>>([
  [SGAlertType.EudbResourceAsymmetry, ResourceAsymmetryAlertPayload],
  [SGAlertType.EudbAimlHosting, AimlHostingAlertPayload],
  [SGAlertType.EudbAimlStorage, AimlStorageAlertPayload],
  [SGAlertType.EudbAimlTraining, AimlTrainingDatasetsAlertPayload],
  [SGAlertType.EudbAimlTrainingDatasets, AimlTrainingDatasetsAlertPayload],
  [SGAlertType.EudbAimlTrainingEnvironment, AimlTrainingEnvironmentAlertPayload],
  [SGAlertType.EudbAimlInferencing, AimlInferencingAlertPayload],
  [SGAlertType.EudbAppInsightsToLogAnalytics, CrossBoundaryDataFlowAlertPayload],
  [SGAlertType.EudbDiagnosticLogsToLogAnalytics, CrossBoundaryDataFlowAlertPayload],
  [SGAlertType.EudbGenevaToBlueshift, CrossBoundaryDataFlowAlertPayload],
  [SGAlertType.EudbGenevaToKusto, CrossBoundaryDataFlowAlertPayload],
  [SGAlertType.EudbGenevaToCosmos, CrossBoundaryDataFlowAlertPayload],
  [SGAlertType.EudbRedisReplication, CrossBoundaryDataFlowAlertPayload],
  [SGAlertType.EudbSqlDb, CrossBoundaryDataFlowAlertPayload],
  [SGAlertType.EudbFimDnsRequest, FimDnsRequestAlertPayload],
])

export const SGAlertsPayloadComponentsProvider: FunctionComponent = ({ children }) => {
  return <AlertPayloadComponentsContext.Provider value={components}>{children}</AlertPayloadComponentsContext.Provider>
}
