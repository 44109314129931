import { useQuery } from 'react-query'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getResourceProviders = async (serviceId?: string) => {
  const response = await apiService.resourceProviders_Get(serviceId)
  return response
}

export function useResourceProviders(serviceId?: string) {
  return useQuery(
    [SgQueryKey.resourceProviders, serviceId],
    () =>
      getResourceProviders(serviceId).then((arr) =>
        arr.sort((a, b) => ((a.provider || '') < (b.provider || '') ? -1 : 1)),
      ),
    { enabled: !!serviceId },
  )
}
