import { Text, Spinner, SpinnerSize, Stack } from '@fluentui/react'
import { FunctionComponent } from 'react'
import { ITEMS_INDICATOR_ID } from 'src/constants'

const textStyle = { lineHeight: '27px' }
const tokens = { childrenGap: 5 }

export interface ItemsIndicatorProps {
  label: string
  number: number
  isLoading?: boolean
}

export const ItemsIndicator: FunctionComponent<ItemsIndicatorProps> = ({ label, number, isLoading }) => {
  return (
    <Stack horizontal tokens={tokens}>
      <Text variant="medium" nowrap block style={textStyle}>
        {label}
      </Text>
      {!isLoading ? (
        <Text variant="xLarge" nowrap block style={textStyle} title={`${ITEMS_INDICATOR_ID}${label}`}>
          {' '}
          {number}
        </Text>
      ) : (
        <Spinner size={SpinnerSize.medium} />
      )}
    </Stack>
  )
}
