import { memoizeFunction } from '@fluentui/react'

export const getFormattedDate = memoizeFunction((date: Date | undefined) => {
  return date?.toLocaleDateString('en-us', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
})

export const getMonthAndDay = memoizeFunction((date: Date | undefined) => {
  return date?.toLocaleDateString('en-us', { month: 'numeric', day: 'numeric' })
})

export const getNumericDate = memoizeFunction((date: Date | undefined) => {
  return date?.toLocaleDateString('en-us', { month: 'numeric', day: 'numeric', year: '2-digit' })
})

export const getHourWithAmPm = (dateInput: Date) => {
  const d = new Date(dateInput)
  return d.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
}
