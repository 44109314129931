import { useQuery } from 'react-query'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getAlerts = async (serviceId?: string, state?: string) => {
  const response = await apiService.sGAlert_GetEUDBAlerts(serviceId, state)
  return response
}

export function useEUDBAlerts(serviceId?: string, state?: string) {
  return useQuery([SgQueryKey.sgAlerts, serviceId, state], () => getAlerts(serviceId, state), {
    enabled: !!serviceId,
  })
}
