import { useState, useEffect, useMemo, FunctionComponent } from 'react'
import { ITag } from '@fluentui/react'
import { useLinkableDataFlowSignals } from 'src/queries'
import { Picker } from '../Picker'
import { Filters } from 'src/enums'
export interface DataFlowPickerProps {
  serviceId?: string
  dataFlowId?: string
  selectedItems?: string[]
  onChange: (items?: ITag[]) => void
}

export const DataFlowPicker: FunctionComponent<DataFlowPickerProps> = ({
  serviceId,
  dataFlowId,
  selectedItems = [],
  onChange,
}) => {
  const { data: signalFlows = [], isLoading, isFetching } = useLinkableDataFlowSignals(serviceId, dataFlowId)
  const [flowTags, setFlowTags] = useState<ITag[]>([])

  useEffect(() => {
    if (signalFlows.length) {
      setFlowTags(
        signalFlows.map((flow) => ({
          key: flow.dataFlowGUID ?? '',
          name: flow.name ? flow.name : `[${flow.sourceNode}] to [${flow.destinationNode}] flow`,
        })),
      )
    }
  }, [signalFlows])

  const selectedTags = useMemo<ITag[]>(() => {
    return flowTags.filter(({ key }) => selectedItems.includes(key as string))
  }, [flowTags, selectedItems])

  return (
    <Picker
      name={Filters.DataFlow}
      itemsList={flowTags}
      onChange={onChange}
      selectedItems={selectedTags}
      isLoading={isLoading || isFetching}
      isFetching={isFetching}
    />
  )
}
