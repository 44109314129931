import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getSkus = async (skuType?: string | undefined) => {
  const response = await apiService.skus_GetSkus(skuType)
  return response
}

export function useSkus(skuType?: string | undefined, enabled = true) {
  return useQuery([SgQueryKey.skus, skuType], () => getSkus(skuType), {
    staleTime: 5 * MINUTE,
    enabled,
  })
}
