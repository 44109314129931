import { ComboBox, IComboBoxProps } from '@fluentui/react'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { HookFormProps } from './HookFormProps'

export const ControlledComboBox: FC<HookFormProps & IComboBoxProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ''}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <ComboBox
          {...props}
          selectedKey={value !== '' ? value : undefined}
          onChange={(_, option) => {
            const key = option?.key
            onChange(key !== '' ? key : null)
          }}
          onBlur={onBlur}
          errorMessage={error && error.message}
          defaultValue={undefined}
        />
      )}
    />
  )
}
