import { IComboBoxOption } from '@fluentui/react'
import { RTIEnvironmentVM } from 'src/services/graphapi'
import { useOptions } from '../../../hooks'
import { useRTIVulnerabilityEnvironments } from 'src/queries'
import { Filters } from 'src/enums'

export const useRTIVulnerabilityEnvironmentsOptions = () => {
  const queryResult = useRTIVulnerabilityEnvironments()

  const map = ({ name }: RTIEnvironmentVM) => ({ name, key: name || '', text: name || '' } as IComboBoxOption)
  const transform = (items: RTIEnvironmentVM[]) => items.map((item) => map(item))

  const options = useOptions<RTIEnvironmentVM>({
    queryResult,
    transform,
    name: Filters.InventoryEnvironment,
    showTitle: true,
  })

  return options
}
