import { Stack } from '@fluentui/react'
import { AlertPayloadComponent } from '../../../../features/SGAlerts/base/types'
import { EUDBAimlAlertRawPayload } from './types'
import { content, header, headerStackItemStyle } from './Styles'
import { ResourceActionCell } from '../utils/ResourceActionCell'
import { DatasetsList } from './DatasetsList'

export const AimlHostingAlertPayload: AlertPayloadComponent<EUDBAimlAlertRawPayload> = ({ rawPayload }) => {
  return (
    <Stack>
      <Stack.Item styles={headerStackItemStyle}>Description:</Stack.Item>
      <Stack.Item>
        The model <b>{rawPayload.ViolatedModel?.Name}</b> (version:
        <b>{rawPayload.ViolatedModel?.Version}</b>) has been detected to be deployed outside of the EU, which is in
        violation of Microsoft EUDB commitments. The model is classified as {rawPayload.DataClassification?.join(', ')},
        which means that it contains in-scope EU customer data that must be hosted and processed in the EU. Please refer
        to the next section for incident details.
      </Stack.Item>
      <br></br>

      <Stack.Item styles={headerStackItemStyle}>To resolve violation:</Stack.Item>
      <ul>
        <li>Remove model from the {rawPayload.ModeInstance?.PathOnResource || '"Unknown"'}</li>
        <li>Set alert as &quot;Resolved&quot;</li>
      </ul>

      <Stack.Item>
        If you believe this is a false positive, please update alert state as &quot;Dispute&quot; and provide
        appropriate justification in Comment section.
      </Stack.Item>
      <br></br>

      <Stack.Item styles={headerStackItemStyle}>Information:</Stack.Item>
      <Stack.Item styles={header}>Model:</Stack.Item>
      <Stack styles={content}>
        <Stack.Item>Name: {rawPayload.ViolatedModel?.Name} </Stack.Item>
        <Stack.Item>Version: {rawPayload.ViolatedModel?.Version} </Stack.Item>
        <Stack.Item>Owners: {rawPayload.ViolatedModel?.OwnerAlias} </Stack.Item>
        <Stack.Item>DataClassificaiton: {(rawPayload.ViolatedModel?.DataClassification ?? []).join(', ')}</Stack.Item>
        <Stack.Item>
          DataBoundary: {(rawPayload.ViolatedModel?.DataBoundary ?? '').length == 0 ? 'No Boundary' : 'EUDB'}{' '}
        </Stack.Item>
      </Stack>

      <Stack.Item styles={header}>Model training datasets:</Stack.Item>
      <DatasetsList datasets={rawPayload.Datasets} />

      <Stack.Item styles={header}>Affected resource:</Stack.Item>
      <Stack styles={content}>
        <Stack.Item>
          Name:&nbsp;
          <ResourceActionCell
            serviceId={rawPayload.ViolatedResource?.ServiceId || ''}
            resourceId={rawPayload.ViolatedResource?.Id || ''}
            resourceName={rawPayload.ViolatedResource?.Name || ''}
          />
        </Stack.Item>
        <Stack.Item>Id: {rawPayload.ViolatedResource?.ArmId} </Stack.Item>
      </Stack>
    </Stack>
  )
}
