import { IButtonProps, IButtonStyles, PrimaryButton, SharedColors, Spinner, SpinnerSize } from '@fluentui/react'
import { ActionPanelFooterButtonStyle } from './ActionPanelFooterButtonStyle'

const ButtonStyleColor = new Map<ActionPanelFooterButtonStyle, [string, string]>([
  [ActionPanelFooterButtonStyle.Cancel, [SharedColors.red10, SharedColors.red20]],
  [ActionPanelFooterButtonStyle.Proceed, [SharedColors.green10, SharedColors.green20]],
  [ActionPanelFooterButtonStyle.Default, [SharedColors.cyanBlue10, SharedColors.cyanBlue20]],
])

const getFooterButtonStyles = (buttonStyle: ActionPanelFooterButtonStyle = ActionPanelFooterButtonStyle.Default) => {
  const color = ButtonStyleColor.get(buttonStyle) || [SharedColors.cyanBlue10, SharedColors.cyanBlue20]
  return {
    root: {
      backgroundColor: color[0],
      borderColor: color[0],
    },
    rootHovered: {
      backgroundColor: color[1],
      borderColor: color[1],
    },
    textContainer: { whiteSpace: 'nowrap' },
  } as IButtonStyles
}

export interface ActionPanelFooterButtonProps extends IButtonProps {
  showSpinnerIcon?: boolean
  buttonStyle?: ActionPanelFooterButtonStyle
}

export const ActionPanelFooterButton = (buttonProps: ActionPanelFooterButtonProps) => {
  return (
    <PrimaryButton
      styles={getFooterButtonStyles(buttonProps.buttonStyle)}
      onRenderIcon={() => (buttonProps.showSpinnerIcon ? <Spinner size={SpinnerSize.xSmall} /> : null)}
      {...buttonProps}
    />
  )
}
