import { useMutation, useQueryClient } from 'react-query'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'
import { DataFlowGroupVM } from './useDataFlowGroups'

export interface DeleteDataFlowGroupCommand {
  groupName?: string
  groupOrder?: string
}

// TODO: become stub until dataflowGroup is completely removed
const deleteDataFlowGroups = async (_command: DeleteDataFlowGroupCommand) => {}

export function useDeleteDataFlowGroups() {
  const queryClient = useQueryClient()

  return useMutation((command: DeleteDataFlowGroupCommand) => deleteDataFlowGroups(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<DataFlowGroupVM[]>(
        queryClient,
        SgQueryKey.dataFlowGroups,
        (_queryKey, data) => {
          if (data.find((d) => d.name === variables?.groupName && d.order === Number(variables.groupOrder))) {
            data = data.filter((d) => d.name !== variables?.groupName && d.order !== variables.groupOrder)
            return data
          }
          return undefined
        },
      )
    },
  })
}
