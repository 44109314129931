import { SharedColors } from '@fluentui/react'
import { CartesianGrid, Legend, LineChart, Line, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts'

export interface TrendChartPoint {
  name: string
  value: number
}

export interface TrendChartProps {
  name: string
  data: TrendChartPoint[]
}

export const TrendChart = ({ name, data }: TrendChartProps) => {
  return (
    <ResponsiveContainer>
      <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 3 }}>
        <CartesianGrid vertical={false} strokeDasharray="0" />
        <XAxis axisLine={false} dataKey="name" minTickGap={100} />
        <YAxis axisLine={false} tickLine={false} tickCount={3} />
        <Tooltip />
        <Legend align="left" />
        <Line name={name} type="linear" dataKey="value" stroke={SharedColors.cyanBlue10} strokeWidth={4} dot={false} />
      </LineChart>
    </ResponsiveContainer>
  )
}
