import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getServiceComponents = async (skuIds?: string, productIds?: string, servicePlanIds?: string) => {
  const response = await apiService.serviceComponents_GetServiceComponents(
    skuIds,
    productIds,
    servicePlanIds,
    null,
    null,
    null,
  )
  return response
}

export function useServiceComponents(
  skuIds?: string,
  productIds?: string,
  servicePlanIds?: string,
  isV2Enabled?: boolean,
  enabled = true,
) {
  return useQuery(
    isV2Enabled
      ? [SgQueryKey.serviceComponents, skuIds, productIds]
      : [SgQueryKey.serviceComponents, skuIds, productIds, servicePlanIds],
    () => getServiceComponents(skuIds, productIds, servicePlanIds),
    {
      staleTime: 5 * MINUTE,
      enabled,
    },
  )
}
