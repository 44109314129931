import { FontSizes, mergeStyles, SharedColors } from '@fluentui/react'
import { FunctionComponent, memo } from 'react'
import { LandingPages, QueryStringParam } from 'src/enums'
import { useSearchParamValue, useUrlSearchParamsWithDefaults } from 'src/hooks'
import { useServiceAffiliations } from 'src/queries'
import { CompliantRouterLink } from 'src/components'

export const subTitleClassName = mergeStyles({
  fontSize: FontSizes.size16,
  color: SharedColors.gray40,
  textDecoration: 'underline',
  display: 'inline-block',
})

export interface ServiceInfoProps {
  withServiceLink?: boolean
}

export const ServiceInfo: FunctionComponent<ServiceInfoProps> = memo(({ withServiceLink }) => {
  const [serviceUrlValue] = useSearchParamValue(QueryStringParam.ServiceId)
  const urlSearchParams = useUrlSearchParamsWithDefaults()

  const { data: serviceAffiliations } = useServiceAffiliations(serviceUrlValue)

  if (!serviceUrlValue || !serviceAffiliations) {
    return <span className={subTitleClassName} />
  }

  const { name } = serviceAffiliations

  return (
    <span>
      {withServiceLink ? (
        <CompliantRouterLink
          to={{ pathname: LandingPages.DataFlows, search: urlSearchParams.toString() }}
          className={subTitleClassName}
        >
          {name}
        </CompliantRouterLink>
      ) : (
        name
      )}
      {': ' + serviceUrlValue}
    </span>
  )
})
