import { LandingPages } from 'src/enums'
import { SubTabs } from './enum'
import { MappingDescriptor, Step } from './interface'
import { stepsConfig } from './stepsConfig'

const mapping: MappingDescriptor[] = [
  {
    name: LandingPages.Models,
    shortName: 'm',
    defaultTabName: SubTabs.THREAT_MODEL_UPLOAD,
    tabs: [
      {
        name: SubTabs.THREAT_MODEL_UPLOAD,
        shortName: 'm_u',
        steps: stepsConfig[SubTabs.THREAT_MODEL_UPLOAD],
      },
      {
        name: SubTabs.FLOWS,
        shortName: 'm_f',
        steps: stepsConfig[SubTabs.FLOWS],
      },
      {
        name: SubTabs.NODES,
        shortName: 'm_n',
        steps: stepsConfig[SubTabs.NODES],
      },
    ],
  },
  {
    name: LandingPages.EUDBAlerts,
    shortName: 'nba',
    defaultTabName: SubTabs.EUDB_ALERTS,
    tabs: [
      {
        name: SubTabs.EUDB_ALERTS,
        shortName: 'nba_a',
        steps: stepsConfig[SubTabs.EUDB_ALERTS],
      },
    ],
  },
  {
    name: LandingPages.Resources,
    shortName: 'r',
    defaultTabName: SubTabs.RESOURCES,
    tabs: [
      {
        name: SubTabs.RESOURCES,
        shortName: 'r_r',
        steps: stepsConfig[SubTabs.RESOURCES],
      },
    ],
  },
]

export const getTabShortName = (pageName: LandingPages, tabName: SubTabs | undefined) => {
  const page = mapping.find((item) => item.name === pageName)
  tabName = tabName || page?.defaultTabName
  const tab = page?.tabs.find((item) => item.name === tabName)
  return tab?.shortName
}

export const getPageShortName = (pageName: LandingPages) => {
  const page = mapping.find((item) => item.name === pageName)
  return page?.shortName
}

export const getTourSteps = (pageName: LandingPages, tabName: SubTabs | undefined): Step[] => {
  const page = mapping.find((item) => item.name === pageName)
  tabName = tabName || page?.defaultTabName
  const tab = page?.tabs.find((item) => item.name === tabName)
  return tab?.steps || []
}
