import { useMutation, useQueryClient } from 'react-query'
import { UpdateStatusForDataFlowsCommand, apiService, ServiceDataFlowVM } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const updateDataFlowStatus = async (command: UpdateStatusForDataFlowsCommand) => {
  await apiService.serviceDataFlow_UpdateStatusForDataFlows(command)
}

export function useUpdateDataFlowStatus() {
  const queryClient = useQueryClient()

  return useMutation((command: UpdateStatusForDataFlowsCommand) => updateDataFlowStatus(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<ServiceDataFlowVM[]>(
        queryClient,
        SgQueryKey.dataFlows,
        (_queryKey, data) => {
          let itemFound = false
          data = data.map((dataflow) => {
            const rCommand = variables.dataFlowsById?.find((r) => r.id === dataflow.dataFlowGUID)
            if (dataflow.dataFlowGUID !== rCommand?.id) return dataflow
            itemFound = true
            return { ...dataflow, state: '' }
          })
          return itemFound ? data : undefined
        },
      )
      queryClient.invalidateQueries([SgQueryKey.serviceDataFlows])
    },
  })
}
