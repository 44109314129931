const downloadFile = (fileName: string, blob: Blob) => {
  const link = document.createElement('a')
  if (link.download !== undefined) {
    // feature detection - browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export const useDownloadFile = () => {
  return downloadFile
}
