import { ProductsSelectionType, apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery } from 'react-query'

const getProducts = async (
  skuIds?: string,
  servicePlanIds?: string,
  ownersIds?: string,
  productsSelectionType?: ProductsSelectionType,
  isSkuMappingReport?: boolean,
) => {
  const response = await apiService.products_GetProducts(
    skuIds,
    servicePlanIds,
    ownersIds,
    productsSelectionType,
    isSkuMappingReport,
  )
  return response
}

export function useProducts(
  skuIds?: string,
  servicePlanIds?: string,
  ownersIds?: string,
  productsSelectionType?: ProductsSelectionType,
  isSkuMappingReport?: boolean,
  skuMappingV2Enabled?: boolean,
) {
  return useQuery(
    isSkuMappingReport && skuMappingV2Enabled
      ? [SgQueryKey.products, skuIds, productsSelectionType]
      : [SgQueryKey.products, skuIds, servicePlanIds, ownersIds, productsSelectionType],
    () => getProducts(skuIds, servicePlanIds, ownersIds, productsSelectionType, isSkuMappingReport),
  )
}
