import { FunctionComponent } from 'react'
import { SGTable } from 'src/components'
import { ViolatedResources } from '../types'
import { getColumns } from './columns'

interface Props {
  serviceId?: string
  resources?: ViolatedResources[]
}

export const ViolatedResourcesTable: FunctionComponent<Props> = ({ serviceId = '', resources = [] }) => {
  return <SGTable name="Violated resources" items={resources} columns={getColumns(serviceId)} />
}
