import { Dropdown, IDropdownOption, IDropdownProps } from '@fluentui/react'
import { ControlledDropdown, HookFormOptionalProps } from '../ControlledInput'
import { getDropdownStyles } from '../Filters'
import { GraphApiListComponentProps } from './props'
import { useOptions, UseOptionsProps } from './useOptions'

export interface GraphApiListSelectProps<TEnum extends number>
  extends UseOptionsProps<TEnum>,
    HookFormOptionalProps,
    GraphApiListComponentProps {
  selectedKey?: TEnum
  setValue: (value: TEnum) => void
  sortByName?: boolean
  label?: string
}

export const GraphApiListSelect = <TEnum extends number>({
  selectedKey,
  setValue,
  enumDescriptorService,
  filteredOptions,
  control,
  name,
  disabled,
  required,
  errorMessage,
  filterSize,
  sortByName = true,
  label,
}: GraphApiListSelectProps<TEnum>) => {
  const options = useOptions({ enumDescriptorService, filteredOptions, sortByName })

  const styles = getDropdownStyles(filterSize)

  const onChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option?.key !== undefined) {
      setValue(option.key as TEnum)
    }
  }

  const dropdownProps: IDropdownProps = {
    onChange,
    options,
    label,
    placeholder: `Select ${label ?? ''}`,
    selectedKey,
    disabled,
    required,
    errorMessage,
    styles,
  }

  return (
    <>
      {control && name ? (
        <ControlledDropdown {...dropdownProps} name={name} control={control} />
      ) : (
        <Dropdown {...dropdownProps} />
      )}
    </>
  )
}
