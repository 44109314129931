import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { useAppSettings } from 'src/contexts'

export const AppInsightsProvider: React.FunctionComponent = ({ children }) => {
  const { uiAppInsightsInstrumentationKey } = useAppSettings()
  const reactPlugin: any = new ReactPlugin()

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: uiAppInsightsInstrumentationKey,
      disableFetchTracking: true,
      autoTrackPageVisitTime: true,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
    },
  })

  appInsights.loadAppInsights()

  return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
}
