import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getOrganizations = async (divisionName?: string) => {
  const response = await apiService.organizations_Get(divisionName)
  return response
}

export function useOrganizations(divisionName?: string) {
  return useQuery([SgQueryKey.organizations, divisionName], () => getOrganizations(divisionName), {
    enabled: !!divisionName,
    staleTime: 5 * MINUTE,
  })
}
