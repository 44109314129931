import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { EventType } from '@azure/msal-browser'
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react'
import { Login } from '../../pages/Login/Login'
import { loginPopup, MsalSingleton } from 'src/services/msalInstance'
import { GraphClientProvider, UserProfileProvider } from 'src/contexts'
import { DataPreloader } from 'src/components'

export function ProtectedContent({ children }) {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const onMsalEvent = (event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.state) {
        try {
          const state = JSON.parse(event.payload.state)
          if (state.path) {
            navigate(state.path)
          }
        } catch (e) {
          console.error(e)
        }
      }
    }

    const callbackId = MsalSingleton.Instance.MsalInstance?.addEventCallback(onMsalEvent)

    return () => {
      if (callbackId) {
        MsalSingleton.Instance.MsalInstance?.removeEventCallback(callbackId)
      }
    }
  }, [navigate])

  const handleLogin = async () => {
    await loginPopup({ path: `${location.pathname}${location.search}` })
  }

  const instance = MsalSingleton.Instance.MsalInstance

  return (
    <>
      {instance && (
        <MsalProvider instance={instance}>
          <AuthenticatedTemplate>
            <GraphClientProvider>
              <DataPreloader>
                <UserProfileProvider>{children}</UserProfileProvider>
              </DataPreloader>
            </GraphClientProvider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Login onLoginClick={handleLogin} />
          </UnauthenticatedTemplate>
        </MsalProvider>
      )}
    </>
  )
}
