import { FC, useCallback, useEffect, useState } from 'react'
import { IComboBox, IComboBoxOption, IComboBoxProps, SelectableOptionMenuItemType, useTheme } from '@fluentui/react'
import { CLEAR_SELECTION_TEXT } from 'src/constants'
import { ControlledVirtualizedComboBox, HookFormOptionalProps } from '../ControlledInput'
import { FilterSize } from '../Filters'
import { FetchingProgressIndicator } from '../ProgressIndicator'
import { SGVirtualizedComboBox } from '../SGVirtualizedComboBox'
import { getComboBoxProps } from './props'
import { useFocusOnClick, useOnRenderLabel } from './hooks'

export interface AutoCompleteProps extends IComboBoxProps {
  filterSize?: FilterSize
  isLoading?: boolean
  isFetching?: boolean
  error?: Error
  hint?: string
}

export const AutoComplete: FC<HookFormOptionalProps & AutoCompleteProps> = ({
  selectedKey,
  filterSize,
  options,
  isLoading,
  isFetching,
  error,
  onChange,
  label,
  placeholder,
  disabled,
  hint,
  name,
  control,
}) => {
  const focusOnClick = useFocusOnClick()
  const onRenderLabel = useOnRenderLabel(hint, label)
  const [inputValue, setInputValue] = useState<string | undefined>()
  const [key, setKey] = useState<any>()
  const [filteredOptions, setFilteredOptions] = useState<IComboBoxOption[]>([])
  const isError = !!error

  const theme = useTheme()

  useEffect(() => {
    setKey(selectedKey)
  }, [setKey, selectedKey])

  useEffect(() => {
    const value = options.find((o) => o.key === key)?.text ?? ''
    setInputValue(value)
  }, [setInputValue, key, options])

  const onChangeOption = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (event.type === 'blur' && option) {
      return
    }
    const isClearAllOption = option?.itemType === SelectableOptionMenuItemType.SelectAll
    setKey(isClearAllOption || !option ? undefined : String(option.key))
    if (onChange) {
      onChange(event, option)
    }
  }

  const onChangeInputFilter = useCallback(
    (value: string | undefined) => {
      setInputValue(value)

      if (!value) {
        setFilteredOptions([])
        return
      }

      const fOptions = options.filter(
        (i) =>
          i.itemType === SelectableOptionMenuItemType.Header ||
          i.text.toLowerCase().includes(value.toLowerCase().trim()),
      )
      setFilteredOptions(fOptions)
    },
    [options],
  )

  const onMenuDismissed = useCallback(() => {
    if (!key) {
      onChangeInputFilter('')
    } else if (!inputValue) {
      setKey(undefined)
    }
  }, [inputValue, key, onChangeInputFilter])

  const clearOption = {
    key: '',
    text: CLEAR_SELECTION_TEXT,
    itemType: SelectableOptionMenuItemType.SelectAll,
    disabled: !key,
    styles: {
      root: {
        color: theme.semanticColors.link,
        fontWeight: 'bold',
      },
    },
  }

  const comboboxProps: IComboBoxProps = {
    ...getComboBoxProps(focusOnClick, filterSize, isError),
    disabled: disabled || isLoading,
    onChange: onChangeOption,
    onInputValueChange: onChangeInputFilter,
    onRenderLabel,
    options: [clearOption, ...(filteredOptions.length > 0 ? filteredOptions : options)],
    placeholder,
    selectedKey: key,
    text: inputValue,
    onMenuDismissed,
  }

  return (
    <>
      {control && name ? (
        <ControlledVirtualizedComboBox {...comboboxProps} name={name} control={control} />
      ) : (
        <SGVirtualizedComboBox {...comboboxProps} />
      )}
      {isFetching && <FetchingProgressIndicator />}
    </>
  )
}
