import React from 'react'
import { UserProfileVM } from 'src/services/graphapi'

export interface UserProfile extends UserProfileVM {
  userId: string
  displayName: string
  email: string
}

export const UserProfileContext = React.createContext<Partial<UserProfile>>({})
