import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery } from 'react-query'

const getRefreshTime = async () => {
  const response = await apiService.reports_GetCloudParityRefreshTime()
  return response
}

export function useRefreshTime() {
  return useQuery([SgQueryKey.refreshTime], () => getRefreshTime())
}

export function useRefreshTimeTable() {
  const { data } = useRefreshTime()
  const formattedData: any[] = []
  for (const key in data) {
    const obj: any = {
      label: key,
      value: new Date(data[key]).toLocaleString(),
    }
    formattedData.push(obj)
  }
  return formattedData
}
