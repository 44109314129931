import { useQuery } from 'react-query'
import {
  apiService,
  ResourcesNodeMetadataVM,
  NodeResourcesPreviewVM,
  GetNodeResourcesPreviewQuery,
} from 'src/services/graphapi'
import { ResourceMatch } from 'src/services/graphapi/lists'
import { SgQueryKey } from './Shared'

const emptyResponse: NodeResourcesPreviewVM = { count: 0, nodeResources: [] }

const getResourcesPreview = async (
  serviceId?: string,
  resourceGroup?: string,
  provider?: string,
  resourceType?: string,
) => {
  if (!resourceGroup && !provider && !resourceType) return emptyResponse
  const request: GetNodeResourcesPreviewQuery = {
    serviceId: serviceId,
    resourceGroup: resourceGroup,
    provider: provider,
    resourceType: resourceType,
  }
  const response = await apiService.nodes_GetResourcesPreview(request)
  return response
}

const getResourcesPreviewByIds = async (serviceId?: string, resourceIds?: string[]) => {
  if (!resourceIds || resourceIds.length == 0) return emptyResponse
  const request: GetNodeResourcesPreviewQuery = {
    serviceId: serviceId,
    resourceIds: resourceIds,
  }
  const response = await apiService.nodes_GetResourcesPreview(request)
  return response
}

export function useNodeResourcesPreview(nodeMetadata: ResourcesNodeMetadataVM, serviceId?: string) {
  return useQuery(
    [
      SgQueryKey.nodeResourcesPreview,
      serviceId,
      nodeMetadata.resourceGroup,
      nodeMetadata.provider,
      nodeMetadata.resourceType,
      nodeMetadata.resourceIds,
    ],
    () => {
      return (
        nodeMetadata.resourceMatch == ResourceMatch.FullyManual
          ? getResourcesPreviewByIds(serviceId, nodeMetadata.resourceIds)
          : getResourcesPreview(serviceId, nodeMetadata.resourceGroup, nodeMetadata.provider, nodeMetadata.resourceType)
      ).then((r) => {
        r.nodeResources = r.nodeResources?.sort((a, b) => ((a.armId ?? 0) < (b.armId ?? 0) ? -1 : 1))
        return r
      })
    },
    {
      enabled: !!serviceId,
      refetchOnWindowFocus: false,
    },
  )
}
