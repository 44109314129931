import { apiService, FeatureVM } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery, useQueryClient } from 'react-query'

const getFeature = async (featureId?: string) => {
  const response = await apiService.features_GetSingleFeature(featureId || '')
  return response
}

export function useFeature(featureId?: string) {
  const queryClient = useQueryClient()
  return useQuery([SgQueryKey.features, featureId], () => getFeature(featureId), {
    enabled: !!featureId,
    initialData: () => {
      const features = queryClient.getQueryData<FeatureVM[]>(SgQueryKey.features)
      const feature = features?.find((f) => f.id === featureId)
      return feature
    },
  })
}
