import { UseOptionsProps } from './useOptions'
import { GraphApiListMultiSelect } from './GraphApiListMultiSelect'
import { useSearchParamValue } from 'src/hooks'
import { useCallback, useEffect } from 'react'
import { FilterSize } from '../Filters'
import { Filters, QueryStringParam } from 'src/enums'

export interface GraphApiListUrlMultiSelectProps<TEnum extends number> extends UseOptionsProps<TEnum> {
  urlKey: QueryStringParam
  initialKeys?: TEnum[]
  label?: Filters
  filteredOptions?: TEnum[]
  filterSize?: FilterSize
}

export const GraphApiListUrlMultiSelect = <TEnum extends number>({
  enumDescriptorService,
  urlKey,
  initialKeys,
  label,
  filteredOptions,
  filterSize = FilterSize.M,
}: GraphApiListUrlMultiSelectProps<TEnum>) => {
  const [urlValue, setUrlValue] = useSearchParamValue<string | null>(urlKey)
  const selectedKeys =
    urlValue
      ?.toString()
      .split('_')
      .map((uValue) => enumDescriptorService.getDescriptorByValue(Number(uValue))?.enum ?? (0 as TEnum)) ?? initialKeys

  const setValue = useCallback(
    (value: TEnum[]) => {
      setUrlValue(value?.length ? value.join('_') : null)
    },
    [setUrlValue],
  )

  useEffect(() => {
    if (initialKeys !== undefined) {
      setValue(initialKeys)
    }
  }, [initialKeys, setValue])

  return (
    <GraphApiListMultiSelect
      selectedKeys={selectedKeys}
      enumDescriptorService={enumDescriptorService}
      setValue={setValue}
      label={label}
      filteredOptions={filteredOptions}
      filterSize={filterSize}
    />
  )
}
