import { ILandingPage } from '../Models'
import { lazy } from 'react'

const SGIAppViewPage = lazy(() => import('../../../pages/SGI/AppView'))

export const SGIAppView = {
  navigationTitle: 'Service Graph Insights: App View',
  icon: 'VisioDiagram',
  description: [
    `Here we can see a detailed breakdown of each Run of Each App.
    If you mouseover the Nodes, you will see important information about how much data these nodes represent.
    SOON: Clicking on the nodes will eventually bring you to their location in storage`,
  ],
  element: <SGIAppViewPage />,
} as ILandingPage
