import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getDivisions = async () => {
  const response = await apiService.divisions_Get()
  return response
}

export function useDivisions() {
  return useQuery(SgQueryKey.divisions, getDivisions, {
    staleTime: 5 * MINUTE,
  })
}
