import { IDropdownOption } from '@fluentui/react'
import { useMemo } from 'react'
import { EnumDescriptorService } from 'src/services/graphapi/EnumDescriptor'

export interface UseOptionsProps<TEnum extends number> {
  enumDescriptorService: EnumDescriptorService<TEnum>
  filteredOptions?: TEnum[]
  sortByName?: boolean
}

export const useOptions = <TEnum extends number>({
  enumDescriptorService,
  filteredOptions,
  sortByName = true,
}: UseOptionsProps<TEnum>): IDropdownOption<any>[] => {
  const options = useMemo(() => {
    const enumOptions = enumDescriptorService
      .getAllDescriptors()
      .filter((e) => !filteredOptions || !filteredOptions.includes(e.enum))
      .map(
        (x) =>
          ({
            key: x.enum,
            text: x.name,
            title: x.data.description,
          } as IDropdownOption),
      )
    return sortByName ? enumOptions.sort((d1, d2) => d1.text.localeCompare(d2.text)) : enumOptions
  }, [enumDescriptorService, filteredOptions, sortByName])

  return options
}
