import React from 'react'
import { useServiceOptions } from './useServiceOptions'
import { SERVICE_SELECTION_HINT } from 'src/constants'
import { useUrlAutoComplete } from '../../hooks'
import { useDefaultService } from 'src/hooks'
import { isUserServiceOwner } from 'src/services'
import { AutoComplete } from '../../AutoComplete'
import { FilterSize } from 'src/components'
import { QueryStringParam } from 'src/enums'

export interface ServiceUrlAutocompleteProps {
  customizationByUserType?: boolean
  displayOnlyMyServices?: boolean
}

export const ServiceUrlAutoComplete: React.FunctionComponent<ServiceUrlAutocompleteProps> = ({
  customizationByUserType = false,
  displayOnlyMyServices = false,
}: ServiceUrlAutocompleteProps) => {
  useDefaultService()
  const params = {
    showTitle: true,
    areServiceOptionsGrouped: customizationByUserType ? isUserServiceOwner() : false,
    displayOnlyMyServices,
  }
  const options = useServiceOptions(params)
  const { urlValue, onChange } = useUrlAutoComplete({ urlKey: QueryStringParam.ServiceId })

  return (
    <AutoComplete
      {...options}
      selectedKey={urlValue}
      onChange={onChange}
      filterSize={FilterSize.M}
      hint={SERVICE_SELECTION_HINT}
    />
  )
}
