import { Stack, Text } from '@fluentui/react'
import { StatusCodes } from 'http-status-codes'
import { FunctionComponent } from 'react'
import { SwaggerException } from 'src/services/graphapi'
import { ForbiddenErrorMessage } from './ForbiddenErrorMessage'

export interface ErrorFallbackMessageContentProps {
  error: Error | AggregateError
}

const getResponseErrors = (responseObj: any) => {
  if (responseObj.errors) {
    const errorArray = Object.values(responseObj.errors).flat(1)
    const uniqueErrors = [...new Set(errorArray)]
    return (
      <>
        {uniqueErrors.map((err) => (
          <Text>{err as string}</Text>
        ))}
      </>
    )
  }
  return <></>
}

export const ErrorFallbackMessageContent: FunctionComponent<ErrorFallbackMessageContentProps> = ({ error }) => {
  if (!error) return <>{'undefined error!'}</>
  if (error instanceof SwaggerException) {
    const { response } = error as SwaggerException
    const responseObj = JSON.parse(response ? response : '{}')

    switch (error.status) {
      case StatusCodes.BAD_REQUEST:
        return getResponseErrors(responseObj)
      case StatusCodes.FORBIDDEN:
        return <ForbiddenErrorMessage error={error} />
      default:
        return <>{responseObj.title || responseObj}</>
    }
  }
  if (error instanceof AggregateError) {
    const responseObjs = error.errors.map((err) => JSON.parse(err.response))
    const errorItems = responseObjs.map((r) => getResponseErrors(r))
    return <Stack>{errorItems}</Stack>
  }

  return <></>
}
