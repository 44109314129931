import { GraphClientContext } from './context'
import { Loader } from 'src/components'
import { MsalSingleton } from 'src/services/msalInstance'
// eslint-disable-next-line max-len
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser'
import { InteractionType, PublicClientApplication } from '@azure/msal-browser'
import { Client } from '@microsoft/microsoft-graph-client'

export let graphClient: Client

export const GraphClientProvider: React.FunctionComponent = ({ children }) => {
  if (MsalSingleton.Instance.MsalInstance === null) {
    return <Loader></Loader>
  }

  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    MsalSingleton.Instance.MsalInstance as PublicClientApplication,
    {
      account: MsalSingleton.Instance.MsalInstance?.getActiveAccount() || {
        environment: '',
        homeAccountId: '',
        localAccountId: '',
        tenantId: '',
        username: '',
      },
      scopes: ['https://graph.microsoft.com/.default'],
      interactionType: InteractionType.Popup,
    },
  )

  graphClient = Client.initWithMiddleware({ authProvider: authProvider })

  return <GraphClientContext.Provider value={graphClient}>{children}</GraphClientContext.Provider>
}
