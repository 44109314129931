import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { DatePicker, defaultDatePickerStrings, IDatePickerProps } from '@fluentui/react'
import { HookFormProps } from './HookFormProps'

export const ControlledDatePicker: FC<HookFormProps & IDatePickerProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.initialValue}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <DatePicker
          {...props}
          textField={{
            name: fieldName,
            errorMessage: error && error.message,
          }}
          onSelectDate={(date) => {
            onChange(date)
          }}
          value={value}
          onBlur={onBlur}
          placeholder="Select a date..."
          ariaLabel="Select a date"
          strings={defaultDatePickerStrings}
        />
      )}
    />
  )
}
