import { KpiType, QueryStringParam } from 'src/enums'
import { useSearchParamValue } from 'src/hooks'
import { useKpi } from 'src/queries'
import { DashboardCardContent } from '../Dashboard'
import { Loader } from '../Loader'

export interface NumericalProgressWithKpiProps {
  kpiType: KpiType
  divisionUrlKey: QueryStringParam
  organizationUrlKey: QueryStringParam
  serviceUrlKey: QueryStringParam
  workstream1OnlyUrlKey: QueryStringParam
}

export const NumericalProgressWithKpi = ({
  kpiType,
  divisionUrlKey,
  organizationUrlKey,
  serviceUrlKey,
  workstream1OnlyUrlKey,
}: NumericalProgressWithKpiProps) => {
  const [divisionUrlValue] = useSearchParamValue(divisionUrlKey)
  const [organizationUrlValue] = useSearchParamValue(organizationUrlKey)
  const [serviceUrlValue] = useSearchParamValue(serviceUrlKey)
  const [workstream1OnlyUrlValue] = useSearchParamValue<boolean>(workstream1OnlyUrlKey)

  const { data, isLoading } = useKpi(
    kpiType,
    divisionUrlValue,
    organizationUrlValue,
    serviceUrlValue,
    workstream1OnlyUrlValue,
  )

  return isLoading ? <Loader /> : <DashboardCardContent value={data?.value ?? 0} />
}
