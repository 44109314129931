import { lazy, Suspense, useEffect, useState } from 'react'
import { UserProfile, UserProfileContext } from './context'
import { useUserProfile } from 'src/queries'
import { saveUserProfile } from 'src/services'
import { Loader, CompliantExternalLink } from 'src/components'
import { ErrorProps } from '../../pages/Error/Error'
import { ILinkProps, Text } from '@fluentui/react'
import { SG_HELP_EMAIL } from 'src/constants'
import { acquireAccountSilent } from 'src/services/msalInstance'

const ErrorPage = lazy(() => import('../../pages/Error/Error'))

const helpLink: ILinkProps = {
  href: `mailto:${SG_HELP_EMAIL}?subject=Encountered Error`,
  role: 'link',
}

export const UserProfileProvider: React.FunctionComponent = ({ children }) => {
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>()
  const { data, isLoading, isError, error } = useUserProfile()

  useEffect(() => {
    if (data) {
      const acquireUserName = async () => {
        const account = await acquireAccountSilent()
        const uProfile = {
          ...data,
          userId: account.localAccountId,
          displayName: account.name,
          email: account.username,
        } as UserProfile
        setUserProfile(uProfile)
      }
      acquireUserName()
    }
  }, [data])

  if (isLoading) {
    return <Loader></Loader>
  }

  if (isError) {
    const errorPageProps: ErrorProps = {
      error: error as Error,
      header: 'Unauthorized',
      extendedContent: (
        <Text>
          <b>For additional help: </b> Contact&nbsp;
          <CompliantExternalLink {...helpLink}>{SG_HELP_EMAIL}</CompliantExternalLink>
        </Text>
      ),
    }
    return (
      <Suspense fallback={<Loader />}>
        <ErrorPage {...errorPageProps}></ErrorPage>
      </Suspense>
    )
  }

  saveUserProfile(userProfile)
  return <UserProfileContext.Provider value={userProfile || {}}>{children}</UserProfileContext.Provider>
}
