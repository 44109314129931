import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getServicePlans = async (skusIds?: string, isSkuMappingReport?: boolean) => {
  const response = await apiService.servicePlans_GetServicePlans(skusIds, isSkuMappingReport)
  return response
}

export function useServicePlans(skusIds?: string, isSkuMappingReport?: boolean, enabled = true) {
  return useQuery(
    [SgQueryKey.servicePlans, skusIds, isSkuMappingReport],
    () => getServicePlans(skusIds, isSkuMappingReport),
    {
      staleTime: 5 * MINUTE,
      enabled,
    },
  )
}
