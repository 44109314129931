import { FunctionComponent, memo } from 'react'
import { ITag } from '@fluentui/react'
import { Picker } from '../../Picker'
import { useProductListOptions } from './useProductListOptions'
import { Filters } from 'src/enums'

export interface ProductsPickerProps {
  isLoading: boolean
  selectedFeatureProducts: ITag[]
  onChangeFeatureProducts: (items?: ITag[]) => void
  disabled?: boolean
  error?: string | undefined
  isRequired?: boolean
}

export const ProductsPicker: FunctionComponent<ProductsPickerProps> = memo(
  ({ onChangeFeatureProducts, selectedFeatureProducts, isLoading, disabled, error, isRequired }) => {
    const { options, isLoadingOptions } = useProductListOptions({ selectedFeatureProducts })

    return (
      <Picker
        name={Filters.Product}
        itemsList={options}
        onChange={onChangeFeatureProducts}
        selectedItems={selectedFeatureProducts}
        isLoading={isLoading || isLoadingOptions}
        disabled={disabled}
        error={error}
        isRequired={isRequired}
      />
    )
  },
)
