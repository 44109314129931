export enum DashboardCardItem {
  // Overview
  AttestationStatus = 'Attestation status',
  AttestationStatusTrend = 'Attestation status trend',
  InProgressAlerts = 'In-progress alerts',
  ResolvedViolationsTrend = 'Resolved violations trend',
  CompletedAlerts = 'Completed alerts',
  UnresolvedViolationsTrend = 'Unresolved violations trend',
  // Platform Integrity
  ResourceIngestionCompleteness = 'Resource ingestion completeness',
  ResourcesWithoutAnyDataFlows = 'Resources without any Data Flows',
  ServicesWithoutDataFlows = 'Services without data flows',
  DataFlowCompleteness = 'Data Flow completeness',
  CompletenessOfIndividualDataFlows = 'Completeness of individual data flows',
  CorrectnessOfIndividualDataFlows = 'Correctness of individual data flows',
  MeanTimeToFailure = 'Mean time to failure',
  MeanTimeToRecovery = 'Mean time to recovery',
  RejectedDataFlows = 'Rejected Data Flows',
  DataSourceNum1Validity = 'Data source #1 validity',
  // AIML
  ActiveAlerts = 'Number of Active Alerts by Model Development Phase',
  ClosedAlerts = 'Number of Closed Alerts by Model Development Phase',
  InfoBox = 'How is the data gathered to generate violations',
}

export const DashboardCardItemDescriptions = new Map<DashboardCardItem, string>([
  // Overview
  [DashboardCardItem.AttestationStatus, '% of attested data flows out of all data flows'],
  [DashboardCardItem.InProgressAlerts, 'Number of violations in Active, Suppressed & Remediating states'],
  [DashboardCardItem.CompletedAlerts, 'Number of violations in Invalid and Remediated states'],
  // Platform Integrity
  [DashboardCardItem.ResourceIngestionCompleteness, '% of ingested resources for in-scope services'],
  [DashboardCardItem.ResourcesWithoutAnyDataFlows, '% of attested data flows out of all data flows'],
  [DashboardCardItem.ServicesWithoutDataFlows, '% of services'],
  [
    DashboardCardItem.DataFlowCompleteness,
    '% of manually added data flows divided by all data flows (only for attested services)',
  ],
  [
    DashboardCardItem.CompletenessOfIndividualDataFlows,
    '% of automatically pre-filled fields out of all data flow fields (only for attested services)',
  ],
  [
    DashboardCardItem.CorrectnessOfIndividualDataFlows,
    '% of corrected data flow fields out of all pre-filled fields (only for attested services)',
  ],
  [DashboardCardItem.MeanTimeToFailure, 'Mean time between downtimes'],
  [DashboardCardItem.MeanTimeToRecovery, 'Mean time for recovery after downtime'],
  [
    DashboardCardItem.RejectedDataFlows,
    '% of rejected data flows out of all auto detected data flows (only for attested services)',
  ],
  [DashboardCardItem.DataSourceNum1Validity, '% of attested data flows out of all data flows'],
])
