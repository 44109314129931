import { FunctionComponent } from 'react'
import { SGTable } from 'src/components'
import { CrossBoundaryDataFlowAlertRawPayload } from '../types'
import { getColumns } from './columns'

interface Props {
  dataFlows?: CrossBoundaryDataFlowAlertRawPayload[]
}

export const ViolatedDataFlowsTable: FunctionComponent<Props> = ({ dataFlows = [] }) => {
  return <SGTable name="Violated data flows" items={dataFlows} columns={getColumns()} />
}
