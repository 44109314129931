import { FontSizes, mergeStyles, SharedColors, Text } from '@fluentui/react'

export interface DashboardCardContentProps {
  value: number | string
}

const cardNumberClassName = mergeStyles({
  color: SharedColors.cyan30,
  '@media(min-height: 850px)': {
    fontSize: FontSizes.mega,
  },
})

export const DashboardCardContent = ({ value }: DashboardCardContentProps) => {
  return (
    <Text block nowrap variant="xxLargePlus" className={cardNumberClassName}>
      {value}
    </Text>
  )
}
