import React, { lazy, Suspense } from 'react'
import { AppSettingsContext } from './context'
import { useAppSettings } from 'src/queries'
import { saveAppSettings } from 'src/services'
import { Loader } from 'src/components'

const ErrorPage = lazy(() => import('../../pages/Error/Error'))

export const AppSettingsProvider: React.FunctionComponent = ({ children }) => {
  const { data, isLoading, isError, error } = useAppSettings()

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return (
      <Suspense fallback={<Loader />}>
        <ErrorPage error={error as Error} />
      </Suspense>
    )
  }

  saveAppSettings(data)
  return <AppSettingsContext.Provider value={data || {}}>{children}</AppSettingsContext.Provider>
}
