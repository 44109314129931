import { useEffect, useRef } from 'react'

export const useEffectSkipFirstRender = (func: () => void, deps?: React.DependencyList) => {
  const timelineLoaded = useRef<boolean>(false)

  useEffect(() => {
    if (timelineLoaded.current) func()
    else timelineLoaded.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
