import { useEffect, useRef } from 'react'

export const useEffectOnlyFirstRender = (func: () => void, deps?: React.DependencyList) => {
  const timelineLoaded = useRef<boolean>(false)

  useEffect(() => {
    if (!timelineLoaded.current) {
      func()
      timelineLoaded.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
