import React, { FormEvent } from 'react'
import { IComboBox, IComboBoxOption } from '@fluentui/react'
import { AutoComplete } from '../../AutoComplete'
import { useServiceOptions } from './useServiceOptions'
import { CustomAutoCompleteProps } from '../Props'

export const ServiceAutoComplete: React.FunctionComponent<CustomAutoCompleteProps> = (props) => {
  const { onKeyChange } = props
  const options = useServiceOptions({})

  const onChange = (_event: FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (onKeyChange) {
      onKeyChange(String(option?.key), option?.text)
    }
  }

  return <AutoComplete {...options} {...props} onChange={onChange} />
}
