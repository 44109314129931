import { Label } from '@fluentui/react'
import { HelpfulLabel } from 'src/components/HelpfulLabel'

export const useOnRenderLabel = (hint?: string, label?: string) => {
  const onRenderLabel = () => {
    if (label) {
      return hint ? <HelpfulLabel label={label} hint={hint} /> : <Label>{label}</Label>
    }
    return null
  }

  return onRenderLabel
}
