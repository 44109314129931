import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/graphapi'
import { useQuery } from 'react-query'

const getRTIVulnerabilityDownloadReport = async (
  team?: string,
  environment?: string,
  isDownloadRequested?: boolean,
) => {
  if (isDownloadRequested) {
    const response = await apiService.rTI_GetRTIVulnerabilityDownloadReport(team, environment)
    return response
  }

  return null
}

export function useRTIVulnerabilityDownloadReport(team?: string, environment?: string, isDownloadRequested?: boolean) {
  return useQuery(
    [SgQueryKey.rtiVulnerabilityDownloadReport, team, environment, isDownloadRequested],
    () => getRTIVulnerabilityDownloadReport(team, environment, isDownloadRequested),
    {},
  )
}
