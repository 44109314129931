import { FunctionComponent } from 'react'
import { IStackStyles, Stack } from '@fluentui/react'
import { ErrorFallbackMessageContent } from './ErrorFallbackMessageContent'

export interface ErrorFallbackMessageProps {
  extendedContent?: JSX.Element
  error: Error
}

const styles: IStackStyles = {
  root: {
    textAlign: 'center',
  },
}

export const ErrorFallbackMessage: FunctionComponent<ErrorFallbackMessageProps> = ({ error, extendedContent }) => {
  return (
    <Stack styles={styles}>
      <ErrorFallbackMessageContent error={error} />
      <br />
      {extendedContent}
    </Stack>
  )
}
