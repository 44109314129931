import { useQuery } from 'react-query'
import { apiService, DataFlowState, DataFlowVM, SwaggerException } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getDataFlows = async (serviceId?: string, diagramName?: string, dataFlowState?: DataFlowState) => {
  const response = await apiService.dataFlows_Get(serviceId, diagramName, dataFlowState)
  return response
}
export function useDataFlows(serviceId?: string, diagramName?: string, dataFlowState?: DataFlowState) {
  return useQuery<DataFlowVM[], SwaggerException>(
    [SgQueryKey.dataFlows, serviceId, diagramName, dataFlowState],
    () => getDataFlows(serviceId, diagramName, dataFlowState),
    {
      enabled: !!serviceId,
    },
  )
}
