import { useQueryErrorResetBoundary } from 'react-query'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './ErrorFallback'

const errorHandler = (error: Error, info: { componentStack: string }) => {
  console.log(error)
  console.log(info.componentStack)
}

export const ContentErrorBoundary: React.FC = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary()

  return (
    <ErrorBoundary onReset={reset} FallbackComponent={ErrorFallback} onError={errorHandler}>
      <>{children}</>
    </ErrorBoundary>
  )
}
