import { IComboBoxOption } from '@fluentui/react'
import { useLocalAccountId, useSearchParamValue } from 'src/hooks'
import { useProducts, useEcsConfig } from 'src/queries'
import { ProductVM, ProductsSelectionType, FeatureFlag } from 'src/services/graphapi'
import { useOptions } from '../../hooks'
import { CustomFilterSizeProps } from '../Props'
import { Filters, QueryStringParam } from 'src/enums'

export interface ProductOptionsProps extends CustomFilterSizeProps {
  isOwnedProducts?: boolean
  productsSelectionType?: ProductsSelectionType
  isSkuMappingReport?: boolean
}

export const useProductOptions = ({
  isOwnedProducts,
  productsSelectionType,
  selectedIds,
  isSkuMappingReport,
  showTitle,
}: ProductOptionsProps) => {
  const [skuIds] = useSearchParamValue(QueryStringParam.SkuIds)
  const { data: configFlags } = useEcsConfig()
  const skuMappingV2Enabled = configFlags?.skuMappingV2Flag === FeatureFlag.Enabled
  const selectedSkuIds = selectedIds || skuIds
  const [servicePlanIds] = useSearchParamValue(QueryStringParam.ServicePlanIds)
  const localAccountId = useLocalAccountId()
  const queryResult = useProducts(
    selectedSkuIds,
    skuMappingV2Enabled && isSkuMappingReport ? '' : servicePlanIds,
    isOwnedProducts ? localAccountId : undefined,
    productsSelectionType,
    isSkuMappingReport,
    skuMappingV2Enabled,
  )

  const map = ({ id, name }: ProductVM) => ({ id, key: id || '', text: name || '' } as IComboBoxOption)
  const transform = (items: ProductVM[]) => items.map((item) => map(item))

  const options = useOptions<ProductVM>({ queryResult, transform, name: Filters.ServiceTreeProduct, showTitle })

  return options
}
