import { IStackItemStyles, IStackStyles, IStackTokens, Separator, Stack } from '@fluentui/react'
import { ActionPanelFooterButton, ActionPanelFooterButtonProps } from './ActionPanelFooterButton'

export interface ActionPanelFooterProps {
  note?: any
  primaryButtonProps: ActionPanelFooterButtonProps
  secondaryButtonProps?: ActionPanelFooterButtonProps
}

const footerTokens: IStackTokens = { childrenGap: 20 }
const footerStyles: IStackStyles = { root: { alignItems: 'center', textAlign: 'center' } }
const footerColLeftStyles: IStackItemStyles = { root: { flex: 3 } }
const footerColRightStyles: IStackItemStyles = { root: { flex: 1 } }

export const ActionPanelFooter = ({ note, primaryButtonProps, secondaryButtonProps }: ActionPanelFooterProps) => {
  return (
    <>
      <Separator />
      <Stack horizontal tokens={footerTokens} styles={footerStyles}>
        {note && <Stack.Item styles={footerColLeftStyles}>{note}</Stack.Item>}
        <Stack.Item styles={footerColRightStyles}>
          <ActionPanelFooterButton {...primaryButtonProps} />
        </Stack.Item>
        {secondaryButtonProps && <ActionPanelFooterButton {...secondaryButtonProps} />}
      </Stack>
    </>
  )
}
