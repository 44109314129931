export const tourTargetIds = {
  autoCompleteSelector: 'tour_autoCompleteSelector',
  documentTitle: 'tour_documentTitle',
  tmAttestButton: 'tour_tmAttestButton',
  tmUploadIcon: 'tour_tmUploadIcon',
  tmUploadPivotPrefixId: 'tour_tmUploadPivot',
  tmUploadPivot1: `tour_tmUploadPivot1`,
  tmUploadPivot2: 'tour_tmUploadPivot2',
}

export const tourTargetClasses = {
  additionalFilters: 'tour_additionalFilters',
  shimmeredDetailsList: 'tour_shimmeredDetailsList',
}
