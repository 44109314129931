import { Dialog, DialogType, IDialogContentProps, IModalProps } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorFallback } from './ErrorFallback'

export interface ErrorDialogProps {
  error: Error | AggregateError
  onDismiss?: () => void
}

const dialogContentProps: IDialogContentProps = {
  type: DialogType.normal,
}

const modalProps: IModalProps = {
  isBlocking: true,
  topOffsetFixed: true,
}

export const ErrorDialog: FunctionComponent<ErrorDialogProps> = ({ error, onDismiss }) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false)

  const navigate = useNavigate()

  const refreshPage = () => {
    navigate('?')
    window.location.reload()
  }

  const onDismissDialog = () => {
    toggleHideDialog()
    if (onDismiss) {
      onDismiss()
    }
  }

  return (
    <Dialog
      hidden={hideDialog}
      onDismiss={onDismissDialog}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <ErrorFallback error={error} resetErrorBoundary={refreshPage} />
    </Dialog>
  )
}
