import { getTheme, IStackTokens, mergeStyles, Stack, Text } from '@fluentui/react'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useWizard, WizardSummaryItem } from 'src/contexts'
import { CompletedIconBig, WarningIconBig } from 'src/components'
import { VALUE_NOT_AVAILABLE } from 'src/constants'

const theme = getTheme()

export interface SummaryProps {
  summaryItems?: WizardSummaryItem[]
}

const summaryBoxClassName = mergeStyles({
  height: '70vh',
  width: '90%',
  overflowY: 'auto',
  backgroundColor: theme.semanticColors.bodyBackgroundChecked,
  marginTop: theme.spacing.l1,
  padding: theme.spacing.l1,
  borderRadius: theme.effects.roundedCorner4,
})

const boldTextClassName = mergeStyles({
  fontWeight: 'bold',
})

const stackTokens: IStackTokens = { childrenGap: 10 }

export const Summary: FunctionComponent<SummaryProps> = () => {
  const [summaryItems, setSummaryItems] = useState<WizardSummaryItem[]>()
  const { getSummary, isDone, wizardSteps, getData } = useWizard()

  useEffect(() => {
    if (getSummary) {
      setSummaryItems(getSummary())
    }
  }, [getSummary])

  const allStepsSuccessful = useMemo(() => {
    return wizardSteps?.every((ws) => !ws.hasError)
  }, [wizardSteps])

  const message = useMemo(() => {
    if (getData) {
      const { summaryMessage } = getData()
      return summaryMessage(allStepsSuccessful || false)
    }

    return ''
  }, [allStepsSuccessful, getData])

  return (
    <>
      {isDone && (
        <Stack horizontal tokens={stackTokens}>
          {allStepsSuccessful ? <CompletedIconBig /> : <WarningIconBig />}
          <Text variant={'xxLarge'}>{message}</Text>
        </Stack>
      )}
      <Text variant={'medium'} nowrap block>
        Here are the details about your feature update.
      </Text>
      <Stack verticalFill className={summaryBoxClassName} tokens={stackTokens}>
        <Text variant={'mediumPlus'} className={boldTextClassName}>
          Feature details
        </Text>
        <br />
        {summaryItems
          ?.flatMap((items) => items)
          .map(({ label, value }) => (
            <Stack key={label} tokens={stackTokens}>
              <Text variant="smallPlus" className={boldTextClassName}>
                {label}
              </Text>
              <Text variant="medium">{value || VALUE_NOT_AVAILABLE}</Text>
            </Stack>
          ))}
      </Stack>
    </>
  )
}
