import { IComboBox } from '@fluentui/react'
import { FormEvent } from 'react'
import { QueryStringParam } from 'src/enums'
import { useSearchParamValue } from 'src/hooks'

interface UrlAutoCompleteMultiSelectProps {
  urlKey: QueryStringParam
  dependents?: QueryStringParam[]
}

interface UrlAutoCompleteMultiSelectResponse {
  urlValue?: string
  onChangeMultipleValues: (_event: FormEvent<IComboBox>, selectedOptions?: string[]) => void
}

export const useUrlAutoCompleteMultiSelect = ({
  urlKey,
  dependents,
}: UrlAutoCompleteMultiSelectProps): UrlAutoCompleteMultiSelectResponse => {
  const [urlValue, setUrlValue] = useSearchParamValue(urlKey, dependents)

  const onChangeMultipleValues = (_event: FormEvent<IComboBox>, selectedOptions?: string[]) => {
    setUrlValue(selectedOptions?.length ? selectedOptions.join('_') : undefined)
  }

  return {
    urlValue,
    onChangeMultipleValues,
  }
}
