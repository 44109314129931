import { QueryStringParam } from 'src/enums'
import { useSearchParamValue } from './useSearchParamValue'

export const useUrlSearchParamsWithDefaults = () => {
  const [globalOptIn] = useSearchParamValue(QueryStringParam.FeatureFlag)
  const urlSearchParams = new URLSearchParams()
  if (globalOptIn) {
    urlSearchParams.set(QueryStringParam.FeatureFlag, globalOptIn)
  }

  return urlSearchParams
}
