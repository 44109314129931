import { useCallback, useMemo } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { QueryStringParam } from 'src/enums'
import { getCurrentUrlSearchValue, toBoolean } from 'src/utils'

const getCurrentSearchParams = () => {
  return createSearchParams(getCurrentUrlSearchValue())
}

const getNextSearchParams = (value: any | undefined, key: string, dependents?: string[]): URLSearchParams => {
  const currentSearchParams = getCurrentSearchParams()
  dependents?.forEach((dependent) => currentSearchParams.delete(dependent))

  if (value) {
    if (currentSearchParams.get(key) !== value) {
      currentSearchParams.set(key, value)
    }
  } else {
    currentSearchParams.delete(key)
  }

  return currentSearchParams
}

export const useSearchParamValue = <T = string>(
  key: QueryStringParam,
  dependents?: QueryStringParam[],
): [T | undefined, (value: T | undefined) => void] => {
  const searchParams = getCurrentSearchParams()
  const navigate = useNavigate()

  const searchParamValue = useMemo<T | undefined>(() => {
    const val = searchParams.get(key)
    if (!val) return undefined
    const num = Number(val)
    if (!isNaN(num)) return num as T
    const bool = toBoolean(val)
    if (bool) return bool as T
    return val as T
  }, [searchParams, key])

  const setSearchParamValue = useCallback(
    (value: T | undefined) => {
      navigate('?' + getNextSearchParams(value, key, dependents))
    },
    [navigate, key, dependents],
  )

  return [searchParamValue, setSearchParamValue]
}
