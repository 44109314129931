import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/graphapi'
import { useQuery } from 'react-query'

const getRTIEnvironments = async () => {
  const response = await apiService.rTI_GetRTIEnvironments()
  return response
}

export function useRTIEnvironments() {
  return useQuery(SgQueryKey.rtiEnvironments, getRTIEnvironments)
}
