import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/Services'
import { useQuery } from 'react-query'

export const getSGIApplicationNames = async () => {
  const response = await apiService.sGI_GetSGIApplicationNames()
  return response
}

export function useSGIApplicationNames() {
  return useQuery(SgQueryKey.sgiApplicationNames, getSGIApplicationNames)
}
