import { useQuery } from 'react-query'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getTMDiagramName = async (serviceId?: string) => {
  const response = await apiService.threatModels_GetDiagramNames(serviceId)
  return response
}
export function useTMDiagramNames(serviceId?: string) {
  return useQuery([SgQueryKey.diagramNames, serviceId], () => getTMDiagramName(serviceId), {
    enabled: !!serviceId,
  })
}
