import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery } from 'react-query'

export const getFeatureOwners = async (featureId?: string) => {
  const response = await apiService.features_GetFeatureOwners(featureId || '')
  return response
}

export function useFeatureOwners(featureId?: string) {
  return useQuery([SgQueryKey.featureOwners, featureId], () => getFeatureOwners(featureId), { enabled: !!featureId })
}
