import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getEcsConfig = async () => {
  const response = await apiService.eCS_GetEcsConfig()
  return response
}

export function useEcsConfig() {
  return useQuery(SgQueryKey.configFlags, getEcsConfig, {
    staleTime: 5 * MINUTE,
  })
}

export const useCloudParityClouds = () => {
  const { data: configFlags } = useEcsConfig()
  return configFlags?.cloudParityCloudsFilter || []
}
