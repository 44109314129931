import { IEnumValueDescriptor } from '.'

export abstract class EnumDescriptorService<TEnum extends number> {
  protected abstract descriptors: IEnumValueDescriptor<TEnum>[]

  private descriptorsMap: Map<TEnum, IEnumValueDescriptor<TEnum>> = new Map<TEnum, IEnumValueDescriptor<TEnum>>()

  abstract getName: () => string

  abstract getDescription: () => string

  abstract getDocumentationUrl: () => string

  protected createDescriptorMap = () => {
    this.getAllDescriptors().forEach((desc) => this.descriptorsMap.set(desc.enum, desc))
  }

  getAllDescriptors = () => this.descriptors

  getDescriptors = (encryptionList: TEnum[]) => {
    return encryptionList.map((identifier) => this.descriptorsMap.get(identifier))
  }

  getDescriptor = (key: TEnum | undefined) => {
    return key !== undefined ? this.descriptorsMap.get(key) : undefined
  }

  getDescriptorByShortName = (shortName: string) => {
    return this.getAllDescriptors().find((item) => item.data.shortName === shortName)
  }

  getDescriptorsByShortName = (shortNames: string[]) => {
    return this.getAllDescriptors().filter((desc) => shortNames.some((it) => it == desc.data.shortName))
  }

  getDescriptorByValue = (value: number) => {
    return this.getAllDescriptors().find((item) => item.value === value)
  }
}
