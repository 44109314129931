import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/graphapi'
import { useQuery } from 'react-query'

const getRTIVulnerabilityMetadata = async (team?: string, environment?: string) => {
  const response = await apiService.rTI_GetRTIVulnerabilityMetadata(team, environment)
  return response
}

export function useRTIVulnerabilityMetadata(team?: string, environment?: string) {
  return useQuery(
    [SgQueryKey.rtiVulnerabilityMetadata, team, environment],
    () => getRTIVulnerabilityMetadata(team, environment),
    {},
  )
}
