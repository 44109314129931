import { FontIcon, IStackTokens, Stack, Text } from '@fluentui/react'
import { FunctionComponent } from 'react'
import _ from 'lodash'
import { sizeClasses } from './Icons'

export interface IconLabelProps {
  icon: JSX.Element | string
  label: string
}

const tokens: IStackTokens = { childrenGap: 7 }

export const IconWithLabel: FunctionComponent<IconLabelProps> = ({ icon, label }) => {
  return (
    <Stack horizontal verticalAlign="center" tokens={tokens}>
      {_.isString(icon) ? <FontIcon className={sizeClasses.default} iconName={icon} /> : icon}
      <Text>{label}</Text>
    </Stack>
  )
}
