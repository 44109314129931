import { FunctionComponent } from 'react'
import { Text } from '@fluentui/react'
import { SwaggerException } from 'src/services/graphapi'
import { StatusCodes } from 'http-status-codes'

export interface ErrorFallbackHeaderProps {
  showErrorHeader?: boolean
  header?: string
  error: Error
}

export const ErrorFallbackHeader: FunctionComponent<ErrorFallbackHeaderProps> = ({
  showErrorHeader = true,
  header = 'Something went wrong',
  error,
}) => {
  return (
    <>
      {showErrorHeader && (
        <Text>
          <b>
            {error instanceof SwaggerException && error.status === StatusCodes.FORBIDDEN
              ? 'You are not authorized to view this page'
              : header}
          </b>
        </Text>
      )}
      <br />
    </>
  )
}
