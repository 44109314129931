import { Label, mergeStyleSets, TooltipHost } from '@fluentui/react'
import { InfoIcon } from '../Icons'

const getClassNames = () => {
  return mergeStyleSets({
    displayInline: {
      display: 'inline-block',
    },
    iconContainer: {
      marginLeft: '0.2rem',
      verticalAlign: 'middle',
      cursor: 'default',
    },
  })
}

export interface HelpfulLabelProps {
  label: string
  hint: string
}

export const HelpfulLabel: React.FunctionComponent<HelpfulLabelProps> = (props) => {
  const classes = getClassNames()

  return (
    <>
      <Label className={classes.displayInline}>{props.label}</Label>
      <TooltipHost content={props.hint}>
        <div className={`${classes.displayInline} ${classes.iconContainer}`}>
          <InfoIcon />
        </div>
      </TooltipHost>
    </>
  )
}
