import { FunctionComponent } from 'react'
import { ViolatedDataset } from './types'
import { Stack } from '@fluentui/react'
import { content } from './Styles'

interface Props {
  datasets: ViolatedDataset[] | undefined
}

export const DatasetsList: FunctionComponent<Props> = ({ datasets }) => {
  if ((datasets ?? []).length == 0) {
    return (
      <Stack styles={content}>
        <Stack.Item>No connected models found</Stack.Item>
      </Stack>
    )
  }
  return (
    <Stack styles={content}>
      {datasets?.map((value) => {
        return (
          <Stack.Item>
            <Stack>
              <Stack.Item>- DataPath: {value.PathOnResource}</Stack.Item>
              <Stack.Item>
                &nbsp;&nbsp;DataBoundary: {(value.DataBoundary.length ?? '') == 0 ? 'No Boundary' : 'EUDB'}
              </Stack.Item>
              <Stack.Item>&nbsp;&nbsp;DataClassification: {value.DataClassification}</Stack.Item>
            </Stack>
          </Stack.Item>
        )
      })}
    </Stack>
  )
}
