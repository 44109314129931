import { DetailsRow, getTheme, IColumn, IDetailsFooterProps, IDetailsRowStyles, SelectionMode } from '@fluentui/react'

export interface SGTableFooterSharedProps {
  footerAction?: JSX.Element
  footerColumns?: IColumn[]
}

export interface SGTableFooterProps extends IDetailsFooterProps, SGTableFooterSharedProps {}

const theme = getTheme()

const styles: Partial<IDetailsRowStyles> = {
  root: {
    justifyContent: 'flex-end',
    borderBottom: 0,
    minHeight: 50,
    ':hover': { backgroundColor: theme.semanticColors.cardStandoutBackground },
  },
}

export const SGTableFooter: React.FunctionComponent<SGTableFooterProps> = ({
  columns,
  footerAction,
  footerColumns,
  selection,
  rowWidth,
}) => {
  const footerActionColumn = footerAction
    ? ([
        {
          key: 'Action',
          name: '',
          minWidth: 100,
          onRender: () => footerAction,
        },
      ] as IColumn[])
    : undefined

  return (
    <DetailsRow
      columns={footerActionColumn || footerColumns || columns}
      item={{}}
      itemIndex={-1}
      selection={selection}
      selectionMode={(selection && selection.mode) || SelectionMode.none}
      styles={styles}
      rowWidth={rowWidth}
    />
  )
}
