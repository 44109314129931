import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery } from 'react-query'

const getFeatureServices = async (featureId?: string) => {
  const response = await apiService.features_GetFeatureServices(featureId || '')
  return response
}

export function useFeatureServices(featureId?: string) {
  return useQuery([SgQueryKey.featureServices, featureId], () => getFeatureServices(featureId), {
    enabled: !!featureId,
  })
}
