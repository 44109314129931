import { QueryStringParam } from 'src/enums'
import { useEffectOnlyFirstRender } from './useEffectOnlyFirstRender'
import { useSearchParamValue } from './useSearchParamValue'
import { loadUserSettings, saveUserSettings } from 'src/services/userSettingsStore'

export const useDefaultService = () => {
  const [serviceUrlValue, setServiceUrlValue] = useSearchParamValue(QueryStringParam.ServiceId)
  const userSettings = loadUserSettings()
  if (serviceUrlValue && serviceUrlValue != userSettings?.selectedServiceId) {
    userSettings.selectedServiceId = serviceUrlValue
    saveUserSettings(userSettings)
  }

  useEffectOnlyFirstRender(() => {
    if (!serviceUrlValue && userSettings?.selectedServiceId) {
      setServiceUrlValue(userSettings?.selectedServiceId)
    }
  }, [userSettings?.selectedServiceId, serviceUrlValue, setServiceUrlValue])
}
