import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getServicesByProducts = async (productIds?: string) => {
  const response = await apiService.services_GetByProducts(productIds)
  return response
}

export function useServicesByProducts(productIds?: string) {
  return useQuery([SgQueryKey.servicesByProducts, productIds], () => getServicesByProducts(productIds), {
    staleTime: 5 * MINUTE,
  })
}
