import React, { useCallback, useState } from 'react'
import { FullScreenContext } from './context'

export const FullScreenProvider: React.FunctionComponent = ({ children }) => {
  const [isFullScreen, setFullScreenState] = useState(false)
  const switchFullScreenState = useCallback(() => {
    setFullScreenState(!isFullScreen)
  }, [isFullScreen])

  return (
    <FullScreenContext.Provider value={{ isFullScreen: isFullScreen, switchFullScreenMode: switchFullScreenState }}>
      {children}
    </FullScreenContext.Provider>
  )
}
