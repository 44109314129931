import { lazy } from 'react'
import { ILandingPage } from '../Models'
import { EUDB_ALERTS } from 'src/constants/StringConstants'

const EUDBAlertsPage = lazy(() => import('../../../pages/EUDBAlerts'))

export const EUDBAlerts = {
  navigationTitle: EUDB_ALERTS,
  icon: 'ShieldAlert',
  element: <EUDBAlertsPage />,
} as ILandingPage
