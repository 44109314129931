import { FunctionComponent } from 'react'
import { useHref } from 'react-router-dom'
import { CompliantExternalLink } from 'src/components'

interface Props {
  serviceId: string
  serviceName: string
}

export const ServiceActionCell: FunctionComponent<Props> = ({ serviceId, serviceName }) => {
  const managePanelUrl = useHref(`/resources?serviceId=${serviceId}`)
  return (
    <CompliantExternalLink href={managePanelUrl} target="_blank">
      {serviceName}
    </CompliantExternalLink>
  )
}
