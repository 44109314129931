import { Stack } from '@fluentui/react'
import { FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'
import { landingPages, CompliantExternalLink } from 'src/components'

export const ForbiddenErrorMessage: FunctionComponent<any> = () => {
  const location = useLocation()
  const { securityGroup } = landingPages.getPage(location.pathname)

  return (
    <>
      {securityGroup ? (
        <Stack>
          {'Join to the security group: '}
          <CompliantExternalLink
            href={`https://idwebelements.microsoft.com/GroupManagement.aspx?Group=${securityGroup}&Operation=join`}
            target="_blank"
          >
            {securityGroup}
          </CompliantExternalLink>
        </Stack>
      ) : (
        <Stack>
          {'You should have correct access rights or be a PM owner/Dev owner/Admin for a service in Service Tree'}
        </Stack>
      )}
    </>
  )
}
