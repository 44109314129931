import { useMutation, useQueryClient } from 'react-query'
import { CreateFeatureCommentCommand, apiService } from 'src/services/graphapi'
import { SgQueryKey, optimisticUpdateAndInvalidateQueries } from './Shared'

const createFeatureComment = async (command: CreateFeatureCommentCommand) => {
  const { featureId, comment } = command
  if (featureId && comment) {
    await apiService.features_CreateFeatureComment(featureId, command)
  }
}

export function useCreateFeatureComment() {
  const queryClient = useQueryClient()

  return useMutation((command: CreateFeatureCommentCommand) => createFeatureComment(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<any>(queryClient, SgQueryKey.featureComments, (queryKey, data) => {
        if (queryKey[1] === variables.featureId) {
          return data
        }
        return undefined
      })
    },
  })
}
