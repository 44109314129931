import { IBasePickerSuggestionsProps, ITag, TagPicker, Text } from '@fluentui/react'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { FetchingProgressIndicator } from '../ProgressIndicator'
import { Filters } from 'src/enums'

export interface PickerProps {
  name: Filters
  itemsList?: ITag[]
  selectedItems?: ITag[] | undefined
  getItems?: (filterText: string) => Promise<ITag[]>
  onChange?: (items?: ITag[]) => void
  isLoading?: boolean
  isFetching?: boolean
  disabled?: boolean
  error?: string | undefined
  isRequired?: boolean
}

const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false
  }
  return tagList.some((compareTag) => compareTag.key === tag.key)
}

const getTextFromItem = (item: ITag) => item.name
const errorTextStyles = { root: { color: 'rgb(255, 99, 71)' } }

const fieldRequiredStyles = {
  root: {
    '&::after': {
      content: '"*"',
      color: 'red',
      display: 'inline',
      position: 'absolute',
      right: -10,
      top: 0,
    },
  },
}

export const Picker: FunctionComponent<PickerProps> = ({
  name,
  getItems,
  onChange,
  itemsList,
  selectedItems,
  isLoading,
  isFetching,
  disabled,
  error,
  isRequired,
}) => {
  const pickerSuggestionsProps: IBasePickerSuggestionsProps = useMemo(() => {
    return {
      suggestionsHeaderText: `Suggested ${name}`,
      noResultsFoundText: `No ${name} found`,
    }
  }, [name])

  const filterSuggestedTags = useCallback(
    async (filterText: string, tagList?: ITag[]): Promise<ITag[]> => {
      let items: ITag[] = []
      if (filterText.length === 0) return items
      if (itemsList) {
        items = itemsList?.filter((tag) => tag?.name.toLowerCase().includes(filterText.toLocaleLowerCase()))
      } else if (getItems) {
        items = await getItems?.(filterText)
      }
      return items?.filter((tag) => !listContainsTagList(tag, tagList))
    },
    [itemsList, getItems],
  )

  const renderEmptyFieldSuggestions = useCallback(
    (_selectedItems: ITag[] | undefined) => {
      return itemsList ? itemsList.filter((tag) => !listContainsTagList(tag, _selectedItems)) : []
    },
    [itemsList],
  )

  return (
    <>
      <TagPicker
        selectedItems={selectedItems}
        removeButtonAriaLabel="Remove"
        selectionAriaLabel={`Selected ${name}`}
        onResolveSuggestions={filterSuggestedTags}
        onChange={onChange}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        pickerCalloutProps={{ doNotLayer: true }}
        onEmptyResolveSuggestions={itemsList ? renderEmptyFieldSuggestions : undefined}
        inputProps={{
          placeholder: disabled ? '' : `Search for ${name}`,
          'aria-required': isRequired,
          required: isRequired,
        }}
        resolveDelay={800}
        disabled={disabled || isLoading}
        styles={isRequired ? fieldRequiredStyles : undefined}
      />
      {error && <Text styles={errorTextStyles}>{error}</Text>}
      {isFetching && <FetchingProgressIndicator />}
    </>
  )
}
