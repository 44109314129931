import React, { useCallback, useState } from 'react'
import { WizardContext, IWizard, IWizardStep, IWizardStepFunctions, ContextData } from './context'
import { WizardMode } from './WizardModeEnum'

export const WizardProvider: React.FunctionComponent = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [isSavingArr, setIsSavingArr] = useState<boolean[]>([])
  const [isDone, setIsDone] = useState<boolean>(false)
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false)
  const [wizardSteps, setWizardSteps] = useState<IWizardStep[]>([])
  const [contextData, setContextData] = useState<ContextData>({
    id: '',
    mode: WizardMode.Edit,
    summaryMessage: () => '',
  })

  const register = useCallback((id: number, functions: IWizardStepFunctions) => {
    setWizardSteps((currentValue) => {
      const fns = functions
      currentValue[id] = { ...currentValue[id], ...fns }
      return currentValue
    })
  }, [])

  const onDone = useCallback(async () => {
    if (isViewOnly) {
      return
    }

    const stepErrors = new Array<boolean>(wizardSteps.length)

    for (const [index, step] of wizardSteps.entries()) {
      setIsSavingArr((currentValue) => {
        currentValue[index] = true
        return [...currentValue]
      })
      await step
        .save()
        .catch(() => {
          stepErrors[index] = true
        })
        .finally(() => {
          setIsSavingArr((currentValue) => {
            currentValue[index] = false
            return [...currentValue]
          })
        })
    }

    if (stepErrors.some((ws) => ws)) {
      setWizardSteps((currentValue) => {
        const nextValue = [...currentValue]
        for (const [index, stepError] of stepErrors.entries()) {
          nextValue[index].hasError = stepError
        }
        return nextValue
      })
    }

    setIsDone(true)
  }, [wizardSteps, isViewOnly])

  const getSummary = useCallback(() => {
    return currentStep === wizardSteps.length
      ? wizardSteps.map((wizardStep) => wizardStep.getSummary() || []).flatMap((summaryItems) => summaryItems)
      : []
  }, [wizardSteps, currentStep])

  const goNextStep = useCallback(async (step: IWizardStep) => {
    if (step.validate && (await step.validate())) {
      setCurrentStep((currentValue) => currentValue + 1)
      return true
    }
    return false
  }, [])

  const goToStep = useCallback(
    async (index: number) => {
      if (index === currentStep || index < -1 || index > wizardSteps.length) return
      if (index < currentStep) setCurrentStep(index)
      else {
        for (let i = currentStep; i < index; i++) {
          const step = wizardSteps[i]
          if (!(await goNextStep(step))) {
            break
          }
        }
      }
    },
    [wizardSteps, currentStep, goNextStep],
  )

  const goBack = useCallback(async () => {
    await goToStep(currentStep - 1)
  }, [currentStep, goToStep])

  const goNext = useCallback(async () => {
    await goToStep(currentStep + 1)
  }, [currentStep, goToStep])

  const backToFirstStep = useCallback(async () => {
    await goToStep(0)
  }, [goToStep])

  const skipToSummary = useCallback(async () => {
    await goToStep(wizardSteps.length)
  }, [goToStep, wizardSteps.length])

  const setData = useCallback((id, mode, summaryMessage) => {
    setContextData({ id, mode, summaryMessage })
  }, [])

  const makeViewOnly = useCallback(() => {
    setIsViewOnly(true)
  }, [])

  const getData = useCallback(() => contextData, [contextData])

  const wizard: IWizard = {
    currentStep,
    isSavingArr,
    isDone,
    isViewOnly,
    wizardSteps,
    onDone,
    backToFirstStep,
    skipToSummary,
    goToStep,
    getSummary,
    goBack,
    goNext,
    register,
    makeViewOnly,
    setData,
    getData,
  }

  return <WizardContext.Provider value={wizard}>{children}</WizardContext.Provider>
}
