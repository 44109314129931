import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery } from 'react-query'

export const getProductOwners = async (productId?: string) => {
  const response = await apiService.products_GetProductOwners(productId || '')
  return response
}

export function useProductOwners(productId?: string) {
  return useQuery([SgQueryKey.productOwners, productId], () => getProductOwners(productId), { enabled: !!productId })
}
