import { mergeStyleSets } from '@fluentui/react'
import * as luxon from 'luxon'
import React, { useContext } from 'react'
import { FOOTER_TEXT, FOOTER_HEIGHT } from 'src/constants'
import { AppSettingsContext } from 'src/contexts'
import { CompliantExternalLink } from 'src/components'

const getClassNames = () =>
  mergeStyleSets({
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: FOOTER_HEIGHT,
      width: '100%',
      fontSize: '0.625rem',
    },
    segmentDivider: {
      marginLeft: '0.1rem',
      marginRight: '0.1rem',
    },
    lrSpacedIcon: {
      marginLeft: '0.2rem',
      marginRight: '0.2rem',
    },
    spacedIcon: {
      marginRight: '0.2rem',
    },
  })

export const Footer = React.memo(() => {
  const appSettings = useContext(AppSettingsContext)
  const lastDeployed = luxon.DateTime.fromJSDate(
    appSettings.deploymentReleaseDeploymentStartTime || new Date(),
  ).toRelative()
  const classes = getClassNames()

  return (
    <footer className={classes.footer}>
      <CompliantExternalLink color="inherit" target="_blank" href="https://aka.ms/ServiceGraphVisionAndArchitectureV2">
        {FOOTER_TEXT.DOCUMENTATION_LINK}
      </CompliantExternalLink>
      <div className={classes.segmentDivider}>{'｜'}</div>
      <CompliantExternalLink target="_blank" href="http://aka.ms/dpn">
        {FOOTER_TEXT.DATA_PROTECTION_NOTICE_LINK}
      </CompliantExternalLink>
      <div className={classes.segmentDivider}>{'｜'}</div>
      {/* <Icon className={classes.spacedIcon} iconName="Build" /> */}
      <CompliantExternalLink
        color="inherit"
        target="_blank"
        href={`https://o365exchange.visualstudio.com/O365%20Core/_build/results?buildId=${appSettings.deploymentBuildId}`}
      >
        {appSettings.deploymentBuildNumber}
      </CompliantExternalLink>
      <div className={classes.segmentDivider}>{'｜'}</div>
      {/* <Icon iconName="BranchCommit" /> */}
      <CompliantExternalLink
        color="inherit"
        target="_blank"
        href={`https://dev.azure.com/O365Exchange/O365%20Core/_git/M365Graph/commit/${appSettings.deploymentBuildSourceVersion}`}
      >
        {appSettings.deploymentBuildSourceVersion?.substring(0, 8)}
      </CompliantExternalLink>
      {/* <Icon className={classes.lrSpacedIcon} iconName="Rocket" /> */}
      <div>&nbsp;deployed&nbsp;</div>
      <CompliantExternalLink color="inherit" target="_blank" href={appSettings.deploymentReleaseWebUrl}>
        {lastDeployed}
      </CompliantExternalLink>
    </footer>
  )
})
