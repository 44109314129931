import { CompactPeoplePicker, IBasePickerSuggestionsProps, IPersonaProps, Persona, Spinner } from '@fluentui/react'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'

export interface BasicPeoplePickerProps {
  name: string
  initialUsers: IPersonaProps[]
  disabled?: boolean
  isLoading?: boolean
  placeholder?: string
  getItems: (filterText: string) => Promise<IPersonaProps[]>
  onChangeItems?: (items?: IPersonaProps[]) => void
}

function listContainsItem(item: IPersonaProps, items: IPersonaProps[]) {
  if (!items || !items.length || items.length === 0) {
    return false
  }
  return items.filter((i) => i.key === item.key).length > 0
}

function removeDuplicates(items: IPersonaProps[], possibleDupes: IPersonaProps[]) {
  return items.filter((item) => !listContainsItem(item, possibleDupes))
}

function getTextFromItem(item: IPersonaProps): string {
  return item.text as string
}

export const PersonaLoading: IPersonaProps[] = [
  <Persona>
    <Spinner />
  </Persona>,
]

export const BasicPeoplePicker: FunctionComponent<BasicPeoplePickerProps> = ({
  name,
  initialUsers,
  disabled,
  isLoading,
  placeholder,
  getItems,
  onChangeItems,
}) => {
  const [mostRecentlyUsed, setMostRecentlyUsed] = useState<IPersonaProps[]>([])
  const [itemList, setItemList] = useState<IPersonaProps[]>(initialUsers)

  useEffect(() => {
    setItemList(initialUsers)
  }, [initialUsers])

  const suggestionProps: IBasePickerSuggestionsProps = useMemo(() => {
    return {
      suggestionsHeaderText: `Suggested ${name}`,
      mostRecentlyUsedHeaderText: `Suggested ${name}`,
      noResultsFoundText: 'No results found',
      loadingText: 'Loading',
      showRemoveButtons: true,
      suggestionsAvailableAlertText: `${name} Picker Suggestions available`,
      suggestionsContainerAriaLabel: `Suggested ${name}`,
    }
  }, [name])

  const onChange = useCallback(
    (items?: IPersonaProps[] | undefined) => {
      if (items) setItemList(items)
      if (onChangeItems) {
        onChangeItems(items)
      }
    },
    [onChangeItems, setItemList],
  )

  const onFilterChanged = async (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number,
  ): Promise<IPersonaProps[]> => {
    if (filterText && currentPersonas) {
      let items = await getItems(filterText)
      items = removeDuplicates(items, currentPersonas)
      items = limitResults ? items.slice(0, limitResults) : items
      return items
    } else {
      return []
    }
  }

  const filterPromise = (itemsToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return itemsToReturn
  }

  const returnMostRecentlyUsed = (
    currentItems: IPersonaProps[] | undefined,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (!currentItems) return []
    return filterPromise(removeDuplicates(mostRecentlyUsed, currentItems))
  }

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexItemList: number = itemList.indexOf(item)
    const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item)

    if (indexItemList >= 0) {
      const newItem = itemList.slice(0, indexItemList).concat(itemList.slice(indexItemList + 1))
      setItemList(newItem)
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedItem: IPersonaProps[] = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1))
      setMostRecentlyUsed(newSuggestedItem)
    }
  }

  return (
    <CompactPeoplePicker
      onResolveSuggestions={onFilterChanged}
      onEmptyInputFocus={returnMostRecentlyUsed}
      getTextFromItem={getTextFromItem}
      className={`ms-${name}Picker`}
      key={'list'}
      selectedItems={isLoading ? PersonaLoading : itemList}
      selectionAriaLabel={`Selected ${name}`}
      removeButtonAriaLabel={'Remove'}
      pickerSuggestionsProps={suggestionProps}
      onRemoveSuggestion={onRemoveSuggestion}
      onChange={onChange}
      inputProps={{
        'aria-label': `${name} picker`,
        placeholder: placeholder || '',
      }}
      resolveDelay={800}
      pickerCalloutProps={{ doNotLayer: true }}
      disabled={disabled || isLoading}
    />
  )
}
