import { Service } from './service-graph-api.generated'

export class ApiService extends Service {
  constructor(baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) {
    super(baseUrl, http)

    this.jsonParseReviver = (key: string, value: any) => {
      if (typeof value === 'string' && key.toLowerCase().includes('date') && isNaN(Number(value))) {
        const date = new Date(value)
        return isNaN(date.getTime()) ? value : date
      }
      return value
    }
  }
}
