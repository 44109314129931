import _ from 'lodash'
import { useMutation, useQueryClient } from 'react-query'
import { apiService, ResourceVM, CompleteResourceTransferCommand } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const completeTransfer = async (command: CompleteResourceTransferCommand) => {
  await apiService.resources_CompleteTransfer(command)
}

export function useCompleteTransfer() {
  const queryClient = useQueryClient()

  return useMutation((command: CompleteResourceTransferCommand) => completeTransfer(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<ResourceVM[]>(queryClient, SgQueryKey.resources, (queryKey, data) => {
        if (queryKey[1] === variables.targetServiceId || queryKey[1] === variables.serviceId) {
          data = data.map((resource) => {
            if (resource.id !== variables?.id) return resource
            return { ...resource, ...variables }
          })
          return data
        }
        return undefined
      })
    },
  })
}

export const getCompleteResourceTransferCommand = (resource: ResourceVM): CompleteResourceTransferCommand => {
  return {
    id: resource.id,
    m365Cloud: resource.m365Cloud,
    resourceId: resource.resourceId,
    serviceId: resource.serviceId,
    targetServiceId: _.isEmpty(resource.targetServiceId) ? undefined : resource.targetServiceId,
    environment: resource.environment,
    dataClassifications: resource.dataClassification || [],
    updateState: undefined,
  }
}
