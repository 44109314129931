import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/graphapi'
import { useQuery } from 'react-query'

const getRTIVulnerability = async (team?: string, environment?: string) => {
  const response = await apiService.rTI_GetRTIVulnerabilityReport(team, environment)
  return response
}

export function useRTIVulnerability(team?: string, environment?: string) {
  return useQuery([SgQueryKey.rtiVulnerability, team, environment], () => getRTIVulnerability(team, environment), {})
}
