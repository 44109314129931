import { Callout, IIconProps, IconButton, List, Stack, getTheme, mergeStyleSets, FontSizes } from '@fluentui/react'
import { useBoolean, useId } from '@fluentui/react-hooks'
import { SGTableLongTextCell } from 'src/components'

interface ReportGroupLitstItemProps {
  items: string[]
  groupKey: string
  columnKey: string
}

const theme = getTheme()

const styles = mergeStyleSets({
  callout: {
    width: 320,
    maxWidth: '90%',
    padding: '20px 24px',
  },
  title: {
    fontSize: FontSizes.size12,
    width: '100%',
  },
  itemName: {
    fontSize: FontSizes.size14,
    padding: 5,
    borderBottom: `1px solid ${theme.semanticColors.variantBorder}`,
  },
  button: {
    styles: {
      root: {
        fontSize: FontSizes.size12,
      },
    },
  },
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
})

const ArrowUp: IIconProps = { iconName: 'ChevronUpSmall' }
const ArrowDown: IIconProps = { iconName: 'ChevronDownSmall' }

export const ReportGroupLitstItem = ({ items }: ReportGroupLitstItemProps) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false)
  const buttonId = useId('callout-button')
  const labelId = useId('callout-label')
  const descriptionId = useId('callout-description')

  const onRenderCell = (item: any): JSX.Element => {
    return (
      <div data-is-focusable={true}>
        <div className={styles.itemName}>{item}</div>
      </div>
    )
  }

  return (
    <Stack horizontal className={styles.fullWidth}>
      <Stack.Item className={styles.halfWidth}>
        <SGTableLongTextCell text={items[0] || ''} />
      </Stack.Item>
      {items.length > 1 && (
        <Stack.Item className={styles.halfWidth}>
          <IconButton
            onClick={toggleIsCalloutVisible}
            id={buttonId}
            className={styles.button}
            iconProps={isCalloutVisible ? ArrowUp : ArrowDown}
          />
          {isCalloutVisible && (
            <Callout
              className={styles.callout}
              ariaLabelledBy={labelId}
              ariaDescribedBy={descriptionId}
              role="dialog"
              gapSpace={0}
              target={`#${buttonId}`}
              onDismiss={toggleIsCalloutVisible}
              setInitialFocus
            >
              <List items={items} onRenderCell={onRenderCell} />
            </Callout>
          )}
        </Stack.Item>
      )}
    </Stack>
  )
}
