import { useMutation, useQueryClient } from 'react-query'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'
import { DataFlowGroupVM } from './useDataFlowGroups'

export interface CreateDataFlowGroupCommand {
  groupName?: string
  groupOrder?: string
}

// TODO: become stub until dataflowGroup is completely removed
const saveDataFlowGroups = async (_command: CreateDataFlowGroupCommand) => {}

export function useSaveDataFlowGroups() {
  const queryClient = useQueryClient()

  return useMutation((command: CreateDataFlowGroupCommand) => saveDataFlowGroups(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<DataFlowGroupVM[]>(
        queryClient,
        SgQueryKey.dataFlowGroups,
        (_queryKey, data) => {
          if (data.find((d) => d.name !== variables?.groupName && d.order !== variables.groupOrder)) {
            data.push({ name: variables.groupName, order: Number(variables.groupOrder) })
            return data
          }
          return undefined
        },
      )
    },
  })
}
