import { useQuery } from 'react-query'
import { KpiType } from 'src/enums'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getKpi = async (
  kpiType: string,
  divisionName?: string,
  organizationName?: string,
  serviceId?: string,
  workstream1Only?: boolean,
) => {
  const response = await apiService.kpi_GetKpi(kpiType, divisionName, organizationName, serviceId, workstream1Only)
  return response
}

export function useKpi(
  kpiType: KpiType,
  divisionName?: string,
  organizationName?: string,
  serviceId?: string,
  workstream1Only?: boolean,
) {
  return useQuery(
    [SgQueryKey.kpi, kpiType, divisionName, organizationName, serviceId, workstream1Only],
    () => getKpi(kpiType, divisionName, organizationName, serviceId, workstream1Only),
    {
      enabled: kpiType.length > 0,
      useErrorBoundary: true,
      onError: undefined,
    },
  )
}
