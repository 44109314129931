import _ from 'lodash'

export const getNormalizedText = (string: string) => _.upperFirst(_.lowerCase(string))

export const PascalCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, ' $1').trim()
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase()
}

export const toBoolean = (value: string) => {
  const val = value.toLocaleLowerCase()
  return val === 'true' ? true : val === 'false' ? false : undefined
}

/**
 * Takes a raw CSV string and converts it to a JavaScript object.
 * @param {string} text The raw CSV string.
 * @param {string[]} headers An optional array of headers to use. If none are
 * given, they are pulled from the first line of `text`.
 * @param {string} quoteChar A character to use as the encapsulating character.
 * @param {string} delimiter A character to use between columns.
 * @returns {object[]} An array of JavaScript objects containing headers as keys
 * and row entries as values.
 */
export function csvToJson(text: string, headers?: string, quoteChar = '"', delimiter = ',') {
  const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs')

  const match = (line) =>
    [...line.matchAll(regex)]
      .map((m) => m[2]) // we only want the second capture group
      .slice(0, -1) // cut off blank match at the end

  const lines = text.split('\n')
  const heads = headers ?? match(lines.shift())

  return lines.map((line) => {
    return match(line).reduce((acc, cur, i) => {
      // parse the value into the correct format
      const val =
        cur.length <= 0 ? null : Number(cur) || toBoolean(cur) || (Date.parse(cur) ? new Date(cur) : undefined) || cur
      const key = heads[i] ?? `extra_${i}`
      return { ...acc, [key]: val }
    }, {})
  })
}
