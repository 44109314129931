import { Dropdown, IDropdownOption, IDropdownProps } from '@fluentui/react'
import { ControlledDropdown, HookFormOptionalProps } from '../ControlledInput'
import { multiSelectControlPlaceholder } from '../../constants/StringConstants'
import { getDropdownStyles } from '../Filters'
import { GraphApiListComponentProps } from './props'
import { useOptions, UseOptionsProps } from './useOptions'
import { Filters } from 'src/enums'

export interface GraphApiListMultiSelectProps<TEnum extends number>
  extends UseOptionsProps<TEnum>,
    HookFormOptionalProps,
    GraphApiListComponentProps {
  selectedKeys?: TEnum[]
  setValue: (value: TEnum[]) => void
  label?: Filters
}

export const GraphApiListMultiSelect = <TEnum extends number>({
  selectedKeys = [],
  setValue,
  enumDescriptorService,
  filteredOptions,
  control,
  name,
  disabled,
  required,
  errorMessage,
  label,
  filterSize,
}: GraphApiListMultiSelectProps<TEnum>) => {
  const options = useOptions({ enumDescriptorService, filteredOptions })

  const styles = getDropdownStyles(filterSize)

  const onChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option?.key !== undefined) {
      const value = option.key as TEnum
      setValue(option.selected ? [...selectedKeys, value] : selectedKeys.filter((key) => key !== value))
    }
  }

  const dropdownProps: IDropdownProps = {
    multiSelect: true,
    onChange,
    options,
    label,
    placeholder: multiSelectControlPlaceholder(label),
    selectedKeys,
    disabled,
    required,
    errorMessage,
    styles,
  }

  return (
    <>
      {control && name ? (
        <ControlledDropdown {...dropdownProps} name={name} control={control} />
      ) : (
        <Dropdown {...dropdownProps} />
      )}
    </>
  )
}
