import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/graphapi'
import { useQuery } from 'react-query'

const getRTITeams = async () => {
  const response = await apiService.rTI_GetRTITeams()
  return response
}

export function useRTITeams() {
  return useQuery(SgQueryKey.rtiTeams, getRTITeams)
}
