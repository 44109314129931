import { DefaultEffects, getTheme, IStackTokens, memoizeFunction, mergeStyles, Stack } from '@fluentui/react'
import { useFullScreen } from '../../contexts/fullScreen/use'

const theme = getTheme()

interface CardProps {
  className?: string
  children?: any
  grow?: number | boolean
  horizontal?: boolean
  tokens?: IStackTokens
  verticalFill?: boolean
  verticalFillMargin?: number
}

const cardClassName = mergeStyles({
  background: theme.palette.white,
  boxShadow: DefaultEffects.elevation4,
  borderRadius: 2,
  padding: 10,
})

const getVerticalFillCardClassName = memoizeFunction((margin?: number, isFullScreenModeOn?: boolean) => {
  const additionalMargin = isFullScreenModeOn ? 130 : 0
  return mergeStyles({
    height: `calc(100vh - ${(margin ? margin : 240) - additionalMargin}px)`,
  })
})

export const Card = ({
  horizontal,
  grow,
  tokens,
  children,
  className = '',
  verticalFill,
  verticalFillMargin,
}: CardProps) => {
  const { isFullScreen } = useFullScreen()
  const verticalFillClassName = verticalFill ? getVerticalFillCardClassName(verticalFillMargin, isFullScreen) : ''

  return (
    <Stack
      tokens={tokens}
      horizontal={horizontal}
      grow={grow}
      className={`${cardClassName} ${className} ${(verticalFill && verticalFillClassName) || ''}`}
    >
      {children}
    </Stack>
  )
}
