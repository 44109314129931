import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/Services'
import { useQuery } from 'react-query'

export const getSGIJobsForApp = async (myAppName: string, jobEnvironment: string) => {
  const response = await apiService.sGI_GetSGIJobsForApp(myAppName, jobEnvironment)
  return response
}

export function useSGIJobsForApp(myAppName: string, jobEnvironment: string) {
  return useQuery(
    [SgQueryKey.sgiJobsForApp, myAppName, jobEnvironment],
    () => getSGIJobsForApp(myAppName, jobEnvironment),
    { enabled: myAppName !== '' && jobEnvironment !== '' },
  )
}
