import { ServiceTier } from 'src/services/graphapi'

export const SERVICE_TIERS_LIST = [
  ServiceTier.NotSet,
  ServiceTier.Tier0,
  ServiceTier.Tier1,
  ServiceTier.Tier2,
  ServiceTier.Unclassified,
  ServiceTier.OutOfScope,
]
