import { useMutation, useQueryClient } from 'react-query'
import { apiService, DataFlowVM, TMErrorVM, UpdateDataFlowCommand } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

interface ICommandParams {
  flow: DataFlowVM
}
const updateTMFlow = async ({ flow }: ICommandParams) => {
  const command: UpdateDataFlowCommand = {
    serviceId: flow.serviceId,
    entityId: flow.dataFlowGUID,
    dataFlow: flow,
  }
  await apiService.threatModels_UpdateDataFlow(command)
}

export function useUpdateTMFlow() {
  const queryClient = useQueryClient()

  return useMutation(updateTMFlow, {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<DataFlowVM[]>(queryClient, SgQueryKey.dataFlows, (_queryKey, data) => {
        const foundedItem = data.find((item) => item.dataFlowGUID == variables.flow.dataFlowGUID)
        if (foundedItem == undefined) {
          return undefined
        }
        return data.map((item) => {
          if (item !== foundedItem) return item
          return { ...item, ...variables.flow }
        })
      })
      optimisticUpdateAndInvalidateQueries<TMErrorVM[]>(queryClient, SgQueryKey.tmErrors, (_queryKey, data) => {
        const foundedItem = data.find((item) => item.entityId == variables.flow.dataFlowGUID)
        if (foundedItem == undefined) {
          return undefined
        }
        return data.map((item) => {
          if (item !== foundedItem) return item
          return { ...item, ...variables }
        })
      })
      optimisticUpdateAndInvalidateQueries<DataFlowVM[]>(
        queryClient,
        SgQueryKey.linkableDataFlows,
        (_queryKey, data) => {
          return data
        },
      )
      queryClient.invalidateQueries([SgQueryKey.tmNodes])
    },
  })
}
