import { useQuery } from 'react-query'
import { apiService, TMNodeVM, SwaggerException } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getThreatModelNodes = async (serviceId?: string) => {
  const response = await apiService.serviceModels_GetThreatModelNodes(serviceId)
  return response
}
export function useThreatModelNodes(serviceId?: string) {
  return useQuery<TMNodeVM[], SwaggerException>(
    [SgQueryKey.threatModelNodes, serviceId],
    () => getThreatModelNodes(serviceId),
    {
      enabled: !!serviceId,
    },
  )
}
