import { IComboBoxOption } from '@fluentui/react'
import { useSkus } from 'src/queries'
import { SkuVM } from 'src/services/graphapi'
import { useOptions } from '../../hooks'
import { Filters } from 'src/enums'

export const useSkuOptions = (skuType?: string | undefined) => {
  const queryResult = useSkus(skuType)
  const map = (item: SkuVM) => ({ key: item.id || '', text: item.name || '' } as IComboBoxOption)
  const transform = (items: SkuVM[]) => items.map((item) => map(item))
  const options = useOptions<SkuVM>({ queryResult, transform, name: Filters.SKU, showTitle: true })

  return options
}
