import { FunctionComponent } from 'react'
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'

export interface SGTableLongCellTooltipProps {
  children: JSX.Element
  content: string
}

const tooltipStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', width: '100%' } }

export const SGTableLongCellTooltip: FunctionComponent<SGTableLongCellTooltipProps> = ({ children, content }) => {
  const tooltipId = useId('tooltip')

  return (
    <TooltipHost content={content} id={tooltipId} styles={tooltipStyles}>
      {children}
    </TooltipHost>
  )
}
