import React from 'react'
import { useServiceOptionsWithFilters } from './useServiceOptions'
import { useUrlAutoComplete } from '../../hooks'
import { useDefaultService } from 'src/hooks'
import { AutoComplete } from '../../AutoComplete'
import { FilterSize } from 'src/components/Filters'
import { isUserServiceOwner } from 'src/services'
import { QueryStringParam } from 'src/enums'

export interface ServiceUrlAutocompleteWithFiltersProps {
  customizationByUserType?: boolean
  displayOnlyMyServices?: boolean
}

export const ServiceUrlAutoCompleteWithFilters: React.FunctionComponent<ServiceUrlAutocompleteWithFiltersProps> = ({
  customizationByUserType = false,
  displayOnlyMyServices = false,
}) => {
  useDefaultService()
  const params = {
    showTitle: true,
    areServiceOptionsGrouped: customizationByUserType ? isUserServiceOwner() : false,
    displayOnlyMyServices,
  }
  const options = useServiceOptionsWithFilters(params)
  const { urlValue, onChange } = useUrlAutoComplete({ urlKey: QueryStringParam.ServiceId })

  return <AutoComplete {...options} selectedKey={urlValue} onChange={onChange} filterSize={FilterSize.M} />
}
