import { Dropdown, IDropdownProps, ISelectableOption, ProgressIndicator } from '@fluentui/react'
import { FunctionComponent } from 'react'
import { ALL } from 'src/constants'
import { useSearchParamValue } from 'src/hooks'
import { UrlFilterProps, getEveryDependent } from '../UrlFilterProps'

interface UrlDropdownProps extends IDropdownProps, UrlFilterProps {
  allOptionEnabled?: boolean
  isFetching?: boolean
  onValueChange?: (_event: React.FormEvent<HTMLDivElement>, option?: ISelectableOption) => void
}

const getAllOptions = (options: ISelectableOption[], allOptionEnabled: boolean): ISelectableOption[] => {
  if (allOptionEnabled && options.length) {
    return [{ key: ALL, id: undefined, text: ALL }, ...options]
  }
  return options
}

const getSelectedOptionKey = (options: ISelectableOption[], selectedOptionId?: string) => {
  return options.find((item) => item.id == selectedOptionId)?.key
}

export const UrlDropdown: FunctionComponent<UrlDropdownProps> = ({
  urlKey,
  dependents,
  allOptionEnabled = false,
  isFetching = false,
  onValueChange = undefined,
  options,
  ...restDropdownProps
}) => {
  const [urlKeyValue, setUrlKeyValue] = useSearchParamValue(urlKey, getEveryDependent(dependents))

  const onChange = (_event: React.FormEvent<HTMLDivElement>, option?: ISelectableOption): void => {
    setUrlKeyValue(option?.id)
    if (onValueChange) {
      onValueChange(_event, option)
    }
  }

  const allOptions = getAllOptions(options, allOptionEnabled)

  return (
    <>
      <Dropdown
        selectedKey={getSelectedOptionKey(allOptions, urlKeyValue)}
        onChange={onChange}
        options={allOptions}
        {...restDropdownProps}
      />
      {isFetching && <ProgressIndicator />}
    </>
  )
}
