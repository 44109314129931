import { ILinkProps, Link } from '@fluentui/react'
import { FunctionComponent } from 'react'

export const CompliantExternalLink: FunctionComponent<ILinkProps> = ({ children, ...props }) => {
  return (
    <Link rel="noreferrer noopener" {...props}>
      {children}
    </Link>
  )
}
