import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/graphapi'
import { useQuery } from 'react-query'

const getSGIDatasetIngestionActivities = async (jobEnvironment: string) => {
  const response = await apiService.sGI_GetSGIDatasetIngestionActivities(jobEnvironment)
  return response
}

export function useSGIDatasetIngestionActivities(jobEnvironment: string) {
  return useQuery(
    [SgQueryKey.sgiDatasetIngestionActivities, jobEnvironment],
    () => getSGIDatasetIngestionActivities(jobEnvironment),
    {},
  )
}
