import { useQuery } from 'react-query'
import { apiService, SwaggerException, DataFlowVM } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getThreatModelEdges = async (serviceId?: string) => {
  const response = await apiService.serviceModels_GetThreatModelEdges(serviceId)
  return response
}
export function useThreatModelEdges(serviceId?: string) {
  return useQuery<DataFlowVM[], SwaggerException>(
    [SgQueryKey.threatModelEdges, serviceId],
    () => getThreatModelEdges(serviceId),
    {
      enabled: !!serviceId,
    },
  )
}
