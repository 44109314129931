import { useMutation, useQueryClient } from 'react-query'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const deleteFeature = async (featureId?: string) => {
  if (featureId) {
    await apiService.features_DeleteFeature(featureId)
  }
}

export function useDeleteFeature() {
  const queryClient = useQueryClient()

  return useMutation((featureId?: string) => deleteFeature(featureId), {
    onSuccess: (_data, _variables) => {
      queryClient.invalidateQueries([SgQueryKey.cloudParityReport])
    },
  })
}
