import { IDropdownStyles, IComboBoxStyles } from '@fluentui/react'

export enum FilterSize {
  XXS = 80,
  XS = 110,
  S = 140,
  M = 170,
  L = 200,
  XL = 230,
  XXL = 260,
  FULL = '100%',
}

export const getDropdownStyles = (filterSize?: FilterSize): Partial<IDropdownStyles> => {
  return {
    dropdown: {
      width: filterSize,
    },
    label: {
      fontSize: '10pt',
      marginBottom: 3,
    },
  }
}

export const getComboBoxStyles = (filterSize?: FilterSize): Partial<IComboBoxStyles> => {
  return {
    container: {
      width: filterSize,
    },
    optionsContainer: {
      zIndex: 1000001,
    },
    label: {
      fontSize: '10pt',
      marginBottom: 3,
    },
  }
}
