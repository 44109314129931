import { IStackItemStyles } from '@fluentui/react'
import { COLOR_LABELS } from 'src/constants'

export const headerStackItemStyle: IStackItemStyles = {
  root: {
    color: COLOR_LABELS.MEDIUM_BLUE,
    fontSize: '14',
    fontWeight: 'bold',
  },
}

export const header: IStackItemStyles = {
  root: {
    padding: '5px 0',
  },
}

export const content: IStackItemStyles = {
  root: {
    padding: '0 10px',
  },
}
