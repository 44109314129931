import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/graphapi'
import { useQuery } from 'react-query'

const getRTIVulnerabilityTeams = async () => {
  const response = await apiService.rTI_GetRTIVulnerabilityReportTeams()
  return response
}

export function useRTIVulnerabilityTeams() {
  return useQuery(SgQueryKey.rtiVulnerabilityTeams, getRTIVulnerabilityTeams)
}
