import { FunctionComponent } from 'react'
import { PrimaryButton, Stack, memoizeFunction, mergeStyles } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'

interface Props {
  text: string
}

const toggledTextClassName = memoizeFunction((visible?: boolean) => {
  return mergeStyles({ visibility: visible ? 'visible' : 'hidden', height: visible ? 'auto' : '0px' })
})

export const TogglePanel: FunctionComponent<Props> = ({ text }) => {
  const [visible, { toggle: toggleVisibility }] = useBoolean(false)
  return (
    <Stack>
      <Stack.Item>
        <PrimaryButton onClick={toggleVisibility}>{visible ? 'Hide' : 'Show'}</PrimaryButton>
      </Stack.Item>
      {<Stack.Item className={toggledTextClassName(visible)}> {text} </Stack.Item>}
    </Stack>
  )
}
