import { QueryClient, QueryKey } from 'react-query'
import { SgQueryKey } from './SgQueryKey'

export const optimisticUpdateAndInvalidateQueries = <T>(
  queryClient: QueryClient,
  sgQueryKey: SgQueryKey,
  optimisticUpdate: (queryKey: QueryKey, data: T) => T | undefined,
) => {
  const queryKeys: QueryKey[] = []
  queryClient
    .getQueriesData<T>(sgQueryKey)
    .filter(([_, data]) => !!data)
    .forEach(([queryKey, data]) => {
      const updatedData = optimisticUpdate(queryKey, data)
      if (updatedData) {
        queryKeys.push(queryKey)
        queryClient.setQueriesData(queryKey, updatedData)
      }
    })
  queryKeys.forEach((qk) => queryClient.invalidateQueries(qk))
}
