import { FunctionComponent } from 'react'
import { FormTitle } from './FormTitle'

export interface FormProps {
  title?: string
}

export const Form: FunctionComponent<FormProps> = ({ title, children }) => {
  return (
    <>
      <FormTitle value={title} />
      {children}
    </>
  )
}
