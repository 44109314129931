import { AutoComplete } from '../../AutoComplete'
import { useUrlAutoComplete } from '../../hooks'
import { CustomFilterSizeProps } from '../Props'
import { useSkuTypeOptions } from './useSkuTypeOptions'
import { QueryStringParam } from 'src/enums'

export const SkuTypeUrlAutoCompleteMultiselect = ({ filterSize }: CustomFilterSizeProps) => {
  const options = useSkuTypeOptions()
  const { urlValue, onChange } = useUrlAutoComplete({
    urlKey: QueryStringParam.SkuType,
    dependents: [QueryStringParam.SkuIds],
  })

  return <AutoComplete {...options} selectedKey={urlValue} onChange={onChange} filterSize={filterSize} />
}
