import { useQuery } from 'react-query'
import { apiService, DataFlowNodeType, TMNodeVM, SwaggerException } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

const getNodes = async (serviceId?: string, nodeType?: DataFlowNodeType, eudbRelevantNodes?: boolean) => {
  const response = await apiService.threatModels_GetNodes(serviceId, nodeType, eudbRelevantNodes)
  return response
}
export function useTMNodes(serviceId?: string, nodeType?: DataFlowNodeType, eudbRelevantNodes?: boolean) {
  return useQuery<TMNodeVM[], SwaggerException>(
    [SgQueryKey.tmNodes, serviceId, nodeType, eudbRelevantNodes],
    () => getNodes(serviceId, nodeType, eudbRelevantNodes),
    {
      enabled: !!serviceId,
    },
  )
}
