import { Stack } from '@fluentui/react'
import { FunctionComponent } from 'react'

export interface PageActionsRightGroupProps {
  children?: any
}

const tokens = { childrenGap: 5 }

export const PageActionsRightGroup: FunctionComponent<PageActionsRightGroupProps> = ({ children }) => {
  return (
    <Stack horizontal horizontalAlign="end" grow tokens={tokens}>
      {children}
    </Stack>
  )
}
