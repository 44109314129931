import { HashRouter as Router } from 'react-router-dom'
import * as FUI from '@fluentui/react'
import { initializeIcons } from '@fluentui/font-icons-mdl2'
import { ReactQueryDevtools } from 'react-query/devtools'
import { CustomRouter, Layout, ProtectedContent } from 'src/components'
import './App.css'
import {
  AppInsightsProvider,
  AppSettingsProvider,
  ReactQueryProvider,
  SGAlertsPayloadComponentsProvider,
} from 'src/contexts'
import { __exposeGlobalTsLib } from '@microsoft/applicationinsights-shims'
import { FullScreenProvider } from '../contexts/fullScreen'

__exposeGlobalTsLib()
initializeIcons()

function App() {
  return (
    <Router>
      <ReactQueryProvider>
        <FUI.ThemeProvider>
          <AppSettingsProvider>
            <ProtectedContent>
              <FullScreenProvider>
                <Layout>
                  <AppInsightsProvider>
                    <SGAlertsPayloadComponentsProvider>
                      <CustomRouter />
                    </SGAlertsPayloadComponentsProvider>
                  </AppInsightsProvider>
                </Layout>
              </FullScreenProvider>
            </ProtectedContent>
          </AppSettingsProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </FUI.ThemeProvider>
      </ReactQueryProvider>
    </Router>
  )
}

export default App
