import React from 'react'
import { mergeStyles, Stack } from '@fluentui/react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'

const spinnerContainerClassName = mergeStyles({
  height: '98vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const Loader = React.memo(() => (
  <Stack className={spinnerContainerClassName}>
    <Spinner size={SpinnerSize.large} />
  </Stack>
))
