import { useCallback, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useUrlAutoComplete } from 'src/components/AutoComplete/hooks'
import { SubTabs } from 'src/components/Tour/enum'
import { getPageShortName, getTabShortName } from 'src/components/Tour/stepsService'
import { LandingPages, QueryStringParam } from 'src/enums'
import { loadOnboardingSettings, saveOnboardingSettings } from 'src/services'

export const useOnboardingStore = (): [boolean, () => void] => {
  const userSettings = loadOnboardingSettings()
  const location = useLocation()
  const { urlValue } = useUrlAutoComplete({ urlKey: QueryStringParam.Tab })
  const locationPath = location.pathname.substring(1) as LandingPages
  const page = getPageShortName(locationPath)
  const subTab = getTabShortName(locationPath, urlValue as SubTabs)
  const [isCurrentOnboardingCompleted, setCurrentOnboardingCompleted] = useState(false)

  useEffect(() => {
    if (
      Object.prototype.hasOwnProperty.call(userSettings, 'userOnboarded') &&
      page &&
      subTab &&
      userSettings.userOnboarded[page] &&
      userSettings.userOnboarded[page][subTab]
    ) {
      setCurrentOnboardingCompleted(true)
    } else {
      setCurrentOnboardingCompleted(false)
    }
  }, [page, userSettings, subTab])

  const setCurrentOnboardingCompletion = useCallback(() => {
    if (!userSettings.userOnboarded) {
      userSettings.userOnboarded = {}
    }

    if (page && subTab) {
      if (!userSettings.userOnboarded[page]) {
        userSettings.userOnboarded[page] = {}
      }
      if (!userSettings.userOnboarded[page][subTab]) {
        userSettings.userOnboarded[page][subTab] = true
      }
      saveOnboardingSettings(userSettings)
    }
  }, [page, userSettings, subTab])
  return [isCurrentOnboardingCompleted, setCurrentOnboardingCompletion]
}
