import { Icon, memoizeFunction, mergeStyleSets, SharedColors, Stack } from '@fluentui/react'
import { QueryStringParam, TrendKpiType } from 'src/enums'
import { useSearchParamValue } from 'src/hooks'
import { useTrendKpi } from 'src/queries'
import { getHourWithAmPm, getMonthAndDay } from 'src/utils'
import { Loader } from '../Loader'
import { TrendChart, TrendChartPoint } from './TrendChart'

export interface TrendChartWithKpiProps {
  trendKpiType: TrendKpiType
  divisionUrlKey: QueryStringParam
  organizationUrlKey: QueryStringParam
  serviceUrlKey: QueryStringParam
  workstream1OnlyUrlKey: QueryStringParam
}

const getPercentage = memoizeFunction((trendChartData: TrendChartPoint[]) => {
  if (trendChartData.length) {
    return Math.round(
      ((trendChartData[trendChartData.length - 1].value - trendChartData[0].value) / trendChartData[0].value) * 100,
    )
  }
  return 0
})

const getChangedPercentageString = memoizeFunction((percentage) => {
  if (isNaN(percentage)) {
    percentage = 0 + '%'
  } else if (!isFinite(percentage)) {
    percentage = 'NA'
  } else if (percentage > 0) {
    percentage = '+' + percentage + '%'
  } else {
    percentage = percentage + '%'
  }
  return percentage
})

const getPercentageIcon = memoizeFunction((percentage): string => {
  switch (true) {
    case percentage > 0:
      return 'TriangleSolidUp12'
    case percentage < 0:
      return 'TriangleSolidDown12'
    default:
      return 'CircleFill'
  }
})

const getIconColor = memoizeFunction((percentage): string => {
  switch (true) {
    case percentage > 0:
      return SharedColors.green20
    case percentage < 0:
      return SharedColors.red20
    default:
      return SharedColors.cyanBlue20
  }
})

const getClassNames = memoizeFunction((percentage: number) => {
  return mergeStyleSets({
    percentageIcon: {
      color: getIconColor(percentage),
      marginRight: 7,
      fontSize: 10,
    },
    refreshIcon: {
      marginRight: 7,
      fontSize: 10,
    },
    trendInfo: {
      justifyContent: 'space-between',
      width: '100%',
    },
    percentageTrend: {
      alignSelf: 'end',
      alignItems: 'center',
    },
    percentageString: {
      fontSize: 10,
    },
    updatedTime: {
      fontSize: 10,
    },
  })
})

export const TrendChartWithKpi = ({
  trendKpiType,
  divisionUrlKey,
  organizationUrlKey,
  serviceUrlKey,
  workstream1OnlyUrlKey,
}: TrendChartWithKpiProps) => {
  const [divisionUrlValue] = useSearchParamValue(divisionUrlKey)
  const [organizationUrlValue] = useSearchParamValue(organizationUrlKey)
  const [serviceUrlValue] = useSearchParamValue(serviceUrlKey)
  const [workstream1OnlyUrlValue] = useSearchParamValue<boolean>(workstream1OnlyUrlKey)

  const { data, isLoading, isSuccess, dataUpdatedAt } = useTrendKpi(
    trendKpiType,
    divisionUrlValue,
    organizationUrlValue,
    serviceUrlValue,
    workstream1OnlyUrlValue,
  )
  const name = trendKpiType.replace(/([a-z])([A-Z])/g, '$1 $2')
  const lastUpdatedTime = isSuccess ? getHourWithAmPm(new Date(dataUpdatedAt)) : undefined
  const trendChartData: TrendChartPoint[] =
    data?.map((d) => {
      return { name: d.date ? getMonthAndDay(d.date) : '', value: d.count ?? 0 } as TrendChartPoint
    }) ?? []

  const percentage = getPercentage(trendChartData)
  const { refreshIcon, percentageIcon, trendInfo, percentageTrend, percentageString, updatedTime } =
    getClassNames(percentage)

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Stack horizontal className={trendInfo}>
        <Stack.Item>
          <Stack horizontal className={percentageTrend}>
            <Icon className={refreshIcon} iconName={'Refresh'} />
            <Stack.Item className={updatedTime}>Updated at {lastUpdatedTime}</Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal className={percentageTrend}>
            <Icon className={percentageIcon} iconName={getPercentageIcon(percentage)} />
            <Stack.Item className={percentageString}>{getChangedPercentageString(percentage)}</Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
      <TrendChart name={name} data={trendChartData} />
    </>
  )
}
