import { FontSizes, getTheme, Icon, mergeStyleSets } from '@fluentui/react'
import React from 'react'

const theme = getTheme()
export const sizeClasses = mergeStyleSets({
  default: { fontSize: FontSizes.size18 },
  small: { fontSize: FontSizes.size12 },
  big: { fontSize: FontSizes.size28 },
  xl: { fontSize: FontSizes.size68 },
})
const classes = mergeStyleSets({
  ...sizeClasses,
  yellow: [{ color: theme.palette.yellow }, sizeClasses.default],
  yellowBig: [{ color: theme.palette.yellow }, sizeClasses.big],
  green: [{ color: theme.palette.green }, sizeClasses.default],
  greenBig: [{ color: theme.palette.green }, sizeClasses.big],
  red: [{ color: theme.palette.red }, sizeClasses.default],
  redSmall: [{ color: theme.palette.red }, sizeClasses.small],
  blue: [{ color: theme.palette.blue }, sizeClasses.default],
  greenSmall: [{ color: theme.palette.green }, sizeClasses.small],
  blueXl: [{ color: theme.palette.blue }, sizeClasses.xl],
})

export const WarningIcon = React.memo(() => <Icon className={classes.yellow} iconName="WarningSolid" />)
export const CompletedIcon = React.memo(() => <Icon className={classes.green} iconName="CompletedSolid" />)
export const CompletedIconBig = React.memo(() => <Icon className={classes.greenBig} iconName="CompletedSolid" />)
export const WarningIconBig = React.memo(() => <Icon className={classes.yellowBig} iconName="WarningSolid" />)
export const BlockedIcon = React.memo(() => <Icon className={classes.red} iconName="Blocked" />)
export const BlockedIconSmall = React.memo(() => <Icon className={classes.redSmall} iconName="Blocked" />)
export const UnknownIcon = React.memo(() => <Icon className={classes.default} iconName="UnknownSolid" />)
export const InfoIcon = React.memo(() => <Icon className={classes.small} iconName="Info" />)
export const XLInfoIcon = React.memo(() => <Icon className={classes.blueXl} iconName="Info" />)
export const StandardInfoIcon = React.memo(() => <Icon className={classes.blue} iconName="Info" />)
export const ExtLinkIcon = React.memo(() => <Icon className={classes.small} iconName="NavigateExternalInline" />)
export const ExtLinkIconBlue = React.memo(() => <Icon className={classes.blue} iconName="NavigateExternalInline" />)
export const ErrorIcon = React.memo(() => <Icon className={classes.red} iconName="StatusErrorFull" />)
export const CloudExemptionIcon = React.memo(() => <Icon className={classes.default} iconName="DRM" color="gray110" />)
export const ProgressRingIcon = React.memo(() => <Icon className={classes.default} iconName="ProgressLoopInner" />)
export const ScheduledIcon = React.memo(() => <Icon className={classes.default} iconName="MailSchedule" />)
export const TransparentCompleteIcon = React.memo(() => <Icon className={classes.default} iconName="Completed" />)
export const GlobeIcon = React.memo(() => <Icon className={classes.default} iconName="Globe" />)
export const BuildingIcon = React.memo(() => <Icon className={classes.default} iconName="EMI" />)
export const ClockIcon = React.memo(() => <Icon className={classes.default} iconName="AwayStatus" />)
export const LinkIcon = React.memo(() => <Icon className={classes.default} iconName="Link" />)
export const ErrorIconSmall = React.memo(() => <Icon className={classes.redSmall} iconName="StatusErrorFull" />)
export const CompletedIConSmall = React.memo(() => <Icon className={classes.greenSmall} iconName="CompletedSolid" />)
export const ToDoLogoInverseIconBig = React.memo(() => <Icon className={classes.greenBig} iconName="ToDoLogoInverse" />)
