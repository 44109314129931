import { useMemo } from 'react'
import { QueryStringParam } from 'src/enums'
import { useSearchParamValue } from 'src/hooks'

interface DateRange {
  startDate?: Date
  endDate?: Date
}

const getDate = (dateValue?: string, isEndOfTheDay?: boolean) => {
  if (!dateValue) return undefined
  const date = new Date(dateValue)
  if (isEndOfTheDay) {
    date.setHours(23, 59, 59)
  }
  return date
}

export const useDateRange = (): DateRange => {
  const [startDateValue] = useSearchParamValue<string>(QueryStringParam.StartDate)
  const [endDateValue] = useSearchParamValue<string>(QueryStringParam.EndDate)

  const startDate = useMemo(() => getDate(startDateValue), [startDateValue])
  const endDate = useMemo(() => getDate(endDateValue, true), [endDateValue])

  return {
    startDate,
    endDate,
  }
}
