import { IToggleProps, mergeStyles, Toggle } from '@fluentui/react'
import { FunctionComponent, useCallback } from 'react'
import { useEffectOnlyFirstRender, useSearchParamValue } from 'src/hooks'
import { UrlFilterProps } from '../UrlFilterProps'

interface UrlToggleProps extends IToggleProps, UrlFilterProps {}

const toggleClass = mergeStyles({
  width: 'max-content',
  marginBottom: 0,
})

export const UrlToggle: FunctionComponent<UrlToggleProps> = ({ defaultUrlValue, urlKey, ...toggleProps }) => {
  const [urlKeyValue, setUrlKeyValue] = useSearchParamValue<number>(urlKey)

  const checkedValue = urlKeyValue === 1

  useEffectOnlyFirstRender(() => {
    setUrlKeyValue(defaultUrlValue ? 1 : undefined)
  })

  const onChange = useCallback(
    (_ev: any, checked?: boolean) => {
      setUrlKeyValue(checked ? 1 : undefined)
    },
    [setUrlKeyValue],
  )

  return <Toggle checked={checkedValue} onChange={onChange} className={toggleClass} {...toggleProps} />
}
