import { FunctionComponent } from 'react'
import { ActionButton, getTheme, IButtonProps, IButtonStyles, IIconProps } from '@fluentui/react'
import { ICON_NAMES } from 'src/constants'

const theme = getTheme()

const forwardIcon: IIconProps = { iconName: ICON_NAMES.FORWARD }
const resolveButtonStyles: IButtonStyles = {
  root: { height: 17 },
  flexContainer: { flexDirection: 'row-reverse', color: theme.palette.blue },
  labelDisabled: { color: theme.palette.neutralQuaternary },
  iconDisabled: { color: theme.palette.neutralQuaternary },
}

export const SGTableActionButton: FunctionComponent<IButtonProps> = (props) => {
  return <ActionButton iconProps={forwardIcon} styles={resolveButtonStyles} {...props} />
}
