import { useMutation, useQueryClient } from 'react-query'
import { apiService, FeatureOwnerVM, UpdateFeatureOwnersCommand } from 'src/services/graphapi'
import { optimisticUpdateAndInvalidateQueries, SgQueryKey } from './Shared'

const saveFeatureOwners = async (command: UpdateFeatureOwnersCommand) => {
  if (command.featureId) {
    await apiService.features_UpdateFeatureOwners(command.featureId, command)
  }
}

export function useSaveFeatureOwners() {
  const queryClient = useQueryClient()

  return useMutation((command: UpdateFeatureOwnersCommand) => saveFeatureOwners(command), {
    onSuccess: (_data, variables) => {
      optimisticUpdateAndInvalidateQueries<FeatureOwnerVM[]>(
        queryClient,
        SgQueryKey.featureOwners,
        (_queryKey, data) => {
          return _queryKey[1] === variables.featureId ? data : undefined
        },
      )
      queryClient.invalidateQueries([SgQueryKey.features])
    },
  })
}
