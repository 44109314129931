import { IColumn } from '@fluentui/react'
import { ViolatedResources } from '../types'
import { ResourceActionCell } from '../../utils/ResourceActionCell'

export const getColumns = (serviceId: string): IColumn[] => [
  {
    key: 'Name',
    name: 'Resource',
    fieldName: 'Name',
    minWidth: 80,
    flexGrow: 1,
    isMultiline: true,
    onRender: (item: ViolatedResources) => (
      <ResourceActionCell serviceId={serviceId} resourceId={item.Id} resourceName={item.Name} />
    ),
  },
  {
    key: 'ResourceGroup',
    name: 'Resource group',
    fieldName: 'ResourceGroup',
    minWidth: 80,
    flexGrow: 1,
    isMultiline: true,
  },
  {
    key: 'Location',
    name: 'Location',
    fieldName: 'Location',
    minWidth: 40,
    flexGrow: 1,
  },
  {
    key: 'DataClassification',
    name: 'Data classification',
    fieldName: 'DataClassification',
    minWidth: 40,
    flexGrow: 1,
    isMultiline: true,
  },
]
