import { apiService, FeatureIntentVM } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery, useQueryClient } from 'react-query'

const getIntent = async (featureId: string) => {
  const response = await apiService.features_GetFeatureIntents(featureId)
  return response
}

export function useFeatureIntents(featureId?: string) {
  const queryClient = useQueryClient()
  return useQuery([SgQueryKey.featureIntent, featureId], () => getIntent(featureId ? featureId : ''), {
    enabled: !!featureId,
    initialData: () => {
      return queryClient.getQueryData<FeatureIntentVM[]>(SgQueryKey.featureIntent)
    },
  })
}
