import { FontSizes, getTheme, Icon, mergeStyleSets } from '@fluentui/react'
import { logoutRedirect } from 'src/services/msalInstance'
import { LocalStorageExcludeList } from './LocalStorageExcludeList'

const theme = getTheme()
const getClassNames = () => {
  return mergeStyleSets({
    icon: {
      fontSize: FontSizes.size20,
      color: theme.palette.white,
      padding: 14,
    },
    signOutIcon: {
      padding: 12,
      gridColumn: '4 / 5',
      cursor: 'pointer',
    },
  })
}

const clearLocalStorage = () => {
  for (const key in window.localStorage) {
    if (Object.prototype.hasOwnProperty.call(window.localStorage, key) && LocalStorageExcludeList.indexOf(key) === -1) {
      localStorage.removeItem(key)
    }
  }
}

export const SignOut = () => {
  const classes = getClassNames()
  const onSignOutOnClick = async () => {
    clearLocalStorage() // Logout redirect clears only auth data from local storage.
    await logoutRedirect()
  }
  return <Icon className={`${classes.icon}, ${classes.signOutIcon}`} iconName="SignOut" onClick={onSignOutOnClick} />
}
