import { useContext, useMemo } from 'react'
import { UserProfileContext } from 'src/contexts/userProfile'
import { QueryStringParam, UserRole } from 'src/enums'
import { useSearchParamValue } from './useSearchParamValue'

export const useCurrentUserRoles = () => {
  const userProfile = useContext(UserProfileContext)
  const [serviceUrlValue] = useSearchParamValue(QueryStringParam.ServiceId)

  const userRoles = useMemo(() => {
    return [
      ...(userProfile.userRoles ?? []),
      ...(userProfile.owningServiceIds && userProfile.owningServiceIds.length > 0 ? [UserRole.SERVICE_OWNER] : []),
      ...(userProfile.owningServiceIds && serviceUrlValue && userProfile.owningServiceIds.includes(serviceUrlValue)
        ? [UserRole.CURRENT_SERVICE_OWNER]
        : []),
    ] as UserRole[]
  }, [userProfile.userRoles, userProfile.owningServiceIds, serviceUrlValue])

  return userRoles
}
