import { lazy } from 'react'
import { ILandingPage } from '../Models'
import { CONFIDENTIAL_FLAG, SKU_MAPPING_REPORT } from 'src/constants'

const SkuMappingReportPage = lazy(() => import('../../../pages/SkuMappingReport/SkuMappingReport'))

export const SkuMappingReportRoute = {
  navigationTitle: SKU_MAPPING_REPORT,
  icon: 'ReportDocument',
  description: [`(${CONFIDENTIAL_FLAG})`],
  externalLink: 'https://eng.ms/docs/experiences-devices/ed-inventory-management/commerceskumap/overview',
  externalLinkText: 'SKU Mapping Documentation',
  element: <SkuMappingReportPage />,
} as ILandingPage
