import { FunctionComponent } from 'react'
import { IconButton } from '@fluentui/react/lib/Button'
import { mergeStyleSets } from '@fluentui/react'
import { TO_FULL_SCREEN_ICON, TO_WINDOW_SCREEN_ICON } from '../../constants'
import { useFullScreen } from 'src/contexts'

const getClassNames = () => {
  return mergeStyleSets({
    buttonStyle: {
      position: 'fixed',
      color: 'white',
      fontSize: 24,
      bottom: 16,
      right: 16,
      width: 40,
      height: 40,
      borderRadius: 8,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      transition: 'background-color 0.3s ease',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ':hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      },
      ':active': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
  })
}

export const FullScreenToggle: FunctionComponent = () => {
  const styles = getClassNames()
  const { isFullScreen, switchFullScreenMode } = useFullScreen()

  return (
    <IconButton
      iconProps={{ iconName: isFullScreen ? TO_WINDOW_SCREEN_ICON : TO_FULL_SCREEN_ICON }}
      className={styles.buttonStyle}
      onClick={switchFullScreenMode}
    />
  )
}
